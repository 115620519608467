import React, { ChangeEvent, FormEventHandler, useEffect, useRef, useState } from 'react';
import Modal from '../../../components/Modal';
import BaseModal from '../../../components/Modal/BaseModal';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';

import AddIcon from '../../../assets/images/add_icon.svg';
import { classNames } from '../../../helpers/styles';
import { isValidImageAspectRatio } from '../../../helpers/image';
import { useAdminSignUpMutation, useAdminUserUpdateMutation } from '../../../services/sign_up';
import { DEFAULT_PASSWORD } from '../../../constants/roles';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { util } from '../../../services/user';

const AddUser = (props: any) => {
    const dispatch = useDispatch();
    const [createAccount, { isSuccess, isLoading }] = useAdminSignUpMutation();
    const [updateAccount, { isLoading: updateIsLoading }] = useAdminUserUpdateMutation();
    const data = props?.data || {};
    const hiddenFileInput = useRef<any>(undefined);
    const [image_error, setImageError] = useState(false);
    const [form, setForm] = useState({
        fullname: '',
        username: '',
        email: '',
        phone_number: '',
    });
    const [image, setImage] = useState<any>({
        file: undefined,
        url: ''
    });

    useEffect(() => {
        setForm({
            fullname: '',
            username: '',
            email: '',
            phone_number: '',
        });
        setImage({
            file: undefined,
            url: ''
        });
    }, []);

    useEffect(() => {
        if (!!data && data?.id) {
            setForm({
                fullname: data.name,
                username: data.username,
                email: data.email,
                phone_number: data.phone,
            });
        }
    }, [data]);

    const handleOnUploadClick = () => {
        hiddenFileInput.current?.click();
    }

    const handleOnImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const img_file = e.target.files[0];
            const img_url = URL.createObjectURL(img_file);
            const is_valid = await isValidImageAspectRatio(img_url, { min_height: 512, min_width: 512, ratio: 1 / 1 });

            if (!is_valid) {
                setImageError(true);
                return;
            }

            setImage({
                file: img_file,
                url: img_url,
            });
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const params = { ...form };
        const image_data = image.url;
        console.log('submit', params, image_data);
        try {
            if (!data) {
                const xhr = await createAccount({
                    name: params.fullname,
                    username: params.username,
                    email: params.email,
                    phone: params.phone_number,
                    password: DEFAULT_PASSWORD,
                    c_password: DEFAULT_PASSWORD
                });

                if ((xhr as any)?.error?.status >= 400) {
                    throw (xhr as any);
                }
            } else {
                const xhr = await updateAccount({
                    name: params.fullname,
                    username: params.username,
                    email: params.email,
                    phone: params.phone_number,
                    password: DEFAULT_PASSWORD,
                    c_password: DEFAULT_PASSWORD,
                    id: data.id,
                });

                if ((xhr as any)?.error?.status >= 400) {
                    throw (xhr as any);
                }
            }

            dispatch(util.invalidateTags(['LIST']));

            Swal.fire({
                title: 'Success!',
                text: `User ${!!data ? 'Updated' : 'Added'} Successfully!`,
                icon: 'success',
                confirmButtonText: 'Ok',
                didClose() {
                    props?.onRequestClose();
                },
            });
        } catch (e: any) {
            console.log('error ', e);

            Swal.fire({
                title: 'Error!',
                text: e?.error?.data?.message || 'An error occurred. Please try again.',
                icon: 'error',
                confirmButtonText: 'Ok',
            });
        }
    }

    return (
        <BaseModal
            title={`${data.id ? 'Update' : 'Add'} User`}
            className="modal-container"
            is_open={props?.is_open}
            onRequestClose={props?.onRequestClose}
        >
            <form onSubmit={handleSubmit}>
                <div className="w-full pt-6">
                    <div className='grid grid-flow-row grid-cols-2 gap-2'>
                        <TextInput
                            type="text"
                            width="w-full"
                            margin_bottom=''
                            placeholder="Full name"
                            value={form.fullname}
                            onChange={e => setForm({ ...form, fullname: e.target.value })}
                            required
                        />
                        <TextInput
                            type="text"
                            width="w-full"
                            margin_bottom=''
                            placeholder="Username"
                            value={form.username}
                            onChange={e => setForm({ ...form, username: e.target.value })}
                            required
                        />
                        <TextInput
                            type="text"
                            width="w-full"
                            margin_bottom=''
                            placeholder="Email Address"
                            value={form.email}
                            onChange={e => setForm({ ...form, email: e.target.value })}
                            required
                        />
                        <TextInput
                            type="text"
                            width="w-full"
                            margin_bottom=''
                            placeholder="Phone number"
                            value={form.phone_number}
                            onChange={e => setForm({ ...form, phone_number: e.target.value })}
                        />
                    </div>
                </div>
                <div className="flex space-x-6 pt-5">
                    <div
                        className="flex sm:h-20 sm:w-20 md:h-20 md:w-20 max-sm:h-20 max-sm:w-20 lg:h-28 lg:w-28 border-2 border-dashed border-gray-400 rounded-full bg-grey1 cursor-pointer"
                        onClick={handleOnUploadClick}
                    >
                        <img src={image?.url !== '' ? image.url : AddIcon} className={classNames(image.url !== '' ? 'object-cover w-full h-full rounded-full' : 'h-4 w-4 m-auto align-middle')} />
                    </div>
                    <div>
                        <div className="pt-2">
                            <span className="text-base font-montserrat font-bold">Profile Photo (Optional)</span><br />
                            <span className="font-sans text-sm italic">*Accepted files jpg, png and must be 512x512</span>
                            <div className="mt-2">
                                <Button type='button' height="h-8" text="Upload" bg_color="bg-sky-blue" text_size='text-xs' width="w-100" onClick={handleOnUploadClick} />
                                <input type='file' accept="image/*" ref={hiddenFileInput} style={{ visibility: 'hidden' }} onChange={handleOnImageChange} />
                                {
                                    image_error && <span className='flex text-sm text-red font-sans mt-2'>Image must be atleast 512x512 and with 1:1 ratio</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-8">
                    <Button type='submit' text={`${!!data ? 'UPDATE' : 'ADD'} USER`} width="lg:w-full sm:w-full md:w-full max-sm:w-full" onSubmit={handleSubmit} disabled={isLoading || updateIsLoading} />
                </div>
            </form>
        </BaseModal>
    );
}

export default AddUser;