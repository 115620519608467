import React, { Fragment, InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { Popover, Transition, Disclosure } from "@headlessui/react";
import Logo from '../../../src/assets/images/valu_menu.svg';
import search_icon from '../../assets/images/search_gray.svg';
import close_icon from '../../assets/images/icons/close.svg';
import Spa from '../../../src/assets/images/streamline-icon-spa-stone-1@48x48.svg';
import Hotels from '../../../src/assets/images/streamline-icon-hotel-double-bed-1@48x48.svg';
import ShoppingBag from '../../../src/assets/images/icons/shopping_bag.svg';
import Search from '../../../src/assets/images/icons/search.svg';
import BurgerIcon from '../../../src/assets/images/icons/burger_icon.svg';
import ProfilePic from '../../assets/images/profile_pic.png';
import Path from '../../assets/images/icons/path.svg';
import ShoppingBagGray from '../../assets/images/shopping_bag_gray.svg';
import SearchGrayIcon from '../../assets/images/search_gray.svg';
import NotificationIcon from '../../assets/images/icons/notification_icon.svg';
import './Header.scss';
import Button from '../Button';
import { Link } from "react-router-dom";
import { AuthUser } from "../../store/auth/types";
import Accessories from '../../assets/images/icons/categories/accessories.svg';
import ArtsAndDesign from '../../assets/images/icons/categories/arts_&_design.svg';
import Automotive from '../../assets/images/icons/categories/automotive.svg';
import CafesAndTreats from '../../assets/images/icons/categories/cafes_&_treats.svg';
import Cosmetics from '../../assets/images/icons/categories/cosmetics.svg';
import Dental from '../../assets/images/icons/categories/dental.svg';
import Electronics from '../../assets/images/icons/categories/electronics.svg';
import Entertainment from '../../assets/images/icons/categories/entertainment.svg';
import FitnessAndGym from '../../assets/images/icons/categories/fitness_&_gym.svg';
import FunAndLeisure from '../../assets/images/icons/categories/fun_&_leisure.svg';
import GiftsAndSurprises from '../../assets/images/icons/categories/gifts_&_surprises.svg';
import GroceriesAndMarkets from '../../assets/images/icons/categories/groceries_&_markets.svg';
import HomeService from '../../assets/images/icons/categories/home_service.svg';
import KidsAndToys from '../../assets/images/icons/categories/kids_&_Toys.svg';
import Medical from '../../assets/images/icons/categories/medical.svg';
import NightLife from '../../assets/images/icons/categories/night_life.svg';
import PersonalServices from '../../assets/images/icons/categories/personal_services.svg';
import Pets from '../../assets/images/icons/categories/pets.svg';
import Restaurants from '../../assets/images/icons/categories/restaurants.svg';
import Retail from '../../assets/images/icons/categories/retail.svg';
import Others from '../../assets/images/icons/categories/others.svg';
import BeautyAndWellness from '../../assets/images/icons/categories/beauty_&_wellness.svg';
import HotelsAndTravels from '../../assets/images/icons/categories/hotels_&_travels.svg';
import CartPopover from "../CartPopover";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logout } from "../../store/auth/slice";
import { authApi } from "../../services/auth";
import { AUTH_TAGS } from "../../services/auth/types";
import Searchbar from "./Searchbar";
import { formatNameForImg } from "../../helpers/utils";
import TextInput from "../TextInput";

type cat_icons = {
    [key: string]: string;
};

type featured = []

const category_icons: cat_icons = {
    Accessories,
    ArtsAndDesign,
    Automotive,
    CafesAndTreats,
    Cosmetics,
    Dental,
    Electronics,
    Entertainment,
    FitnessAndGym,
    FunAndLeisure,
    GiftsAndSurprises,
    GroceriesAndMarkets,
    HomeService,
    KidsAndToys,
    Medical,
    NightLife,
    PersonalServices,
    Pets,
    Restaurants,
    Retail,
    Others,
    BeautyAndWellness,
    HotelsAndTravels
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const HeaderAdmin = ({
    list_categories,
    user_data,
    ...props
}: HeaderProps) => {
    const token = useAppSelector(store => store.auth.token);
    const cart = useAppSelector(store => store.cart);
    const dispatch = useAppDispatch();
    const [show_searchbar, setShowSearchbar] = useState(false);
    const user_name = formatNameForImg(user_data?.name || '');

    const el = document.getElementById("PopoverButton");
    let mobileView = false;
    let login = !!token;
    let featured_categories = [{
        color: '',
        id: 0,
        is_featured: false,
        logo: '',
        name: ''
    }];
    let temp_arr = [];
    let has_business = user_data?.roles[0] === 'business owner' || user_data?.roles[1] === 'business owner' ? true : false;

    for (let i = 0; i < list_categories.length; i++) {
        if (list_categories[i].is_featured) {
            temp_arr.push(list_categories[i]);
        }
    }

    useEffect(() => {
        if (!!cart) {
            el?.click();
            setTimeout(() => {
                const attr = el?.getAttribute('data-headlessui-state') || '';
                if (attr === 'open') {
                    el?.click();
                }
            }, 3000);
        }
    }, [cart]);

    featured_categories = temp_arr;

    const handleLogout = () => {
        // window.location.reload();
        dispatch(authApi.util.invalidateTags([AUTH_TAGS.ME, AUTH_TAGS.LOGIN]));
        dispatch(authApi.util.resetApiState());
        dispatch(logout());
    }

    return (
        <header>
            <nav>
                <Popover id="HeaderComponent" className=" bg-white h-24 border-b border-solid border-slate-300 fixed w-full z-50">
                    <div className="my-5 mx-6">
                        <div className="flex items-center">
                            <div className="lg:inline-block lg:w-1/3 !w-auto">
                                <a href="/app">
                                    <img
                                        className="h-8 w-auto sm:h-10 lg:m-0 m-auto"
                                        src={Logo}
                                        alt=""
                                    />
                                </a>
                            </div>
                            <div className="h-8 sm:h-10 w-[2px] bg-hairline1 mx-3" />
                            <div className="flex flex-1 text-blue">Admin Portal</div>
                            <div className="flex justify-between lg:hidden w-full max-h-screen z-10">
                                <div className="flex lg:hidden space-x-4">
                                    <div>
                                        <img
                                            src={Search}
                                            className="cursor-pointer"
                                            alt=""
                                            onClick={() => {
                                                setShowSearchbar(true);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <img src={ShoppingBag} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className="md:hidden sm:hidden max-sm:hidden lg:inline-block lg:space-x-10 text-center lg:w-1/3">
                                <div className="flex justify-between">
                                    <TextInput
                                        // innerRef={search_ref}
                                        placeholder="Search Users, Businesses and More"
                                        width="w-full"
                                        containerClassName="border-1 mb-0"
                                        margin_bottom="mb-0"
                                        left_icon={<img src={search_icon} className="w-5 h-5 m-2" />}
                                        // right_icon={<img src={close_icon} className="w-5 h-5 m-2 cursor-pointer" onClick={() => { }} />}
                                        left_icon_border={false}
                                        right_icon_border={false}
                                    // value={search}
                                    // onChange={e => setSearch(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="md:hidden sm:hidden max-sm:hidden lg:inline-block lg:space-x-10 lg:w-1/3">
                                <div className="flex float-right justify-between space-x-6">
                                    <div className="lg:hidden">
                                        <img src={SearchGrayIcon} alt="" className="py-3 cursor-pointer" onClick={() => setShowSearchbar(true)} />
                                    </div>
                                    {
                                        login && (
                                            <div className="my-3">
                                                <Link to="profile" className="text-base font-medium text-black font-san pr-5">{(user_data?.name || '').split(' ')[0]}</Link>
                                                <Link onClick={handleLogout} to="#" className="text-base font-medium text-black font-san pl-5">Sign out</Link>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Popover>
            </nav>
        </header>
    );
}

export interface HeaderProps extends InputHTMLAttributes<HTMLInputElement> {
    list_categories: [{
        color: string,
        id: number,
        is_featured: boolean,
        logo: string,
        name: string
    }],
    user_data: AuthUser | undefined
}

export default HeaderAdmin;