import { Menu, Transition } from '@headlessui/react';
import { StarIcon } from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';
import business_img1 from '../../assets/images/samples/business_img1.png';
import price_tag from '../../assets/images/icons/price_tag.svg';
import percent from '../../assets/images/icons/percent.svg';
import Button from '../../components/Button';
import MessageNotification from './components/MessageNotification';
import Tabs from './components/Tabs';
import { Link, useParams } from 'react-router-dom';
import { PRODUCT_SERVICE, PROMO_CODE } from '../../constants/business';
import { Business } from '../../store/business/types';

const Desktop = ({ data, ...props }: BusinessDetailsProps) => {
  const { business_id } = useParams();

  return (
    <div className='w-full'>
      <div className='container m-auto'>
        <div className='grid grid-flow-row grid-cols-5 gap-2 w-full'>
          <div className='flex col-span-5 md:col-span-3'>
            <div className='border border-grey rounded-md w-28 h-28 overflow-hidden p-2'>
              <img src={data?.logo} className="w-24 h-24 object-contain" alt="JC Cafe" />
            </div>
            <div className='pl-8'>
              <h4>{data?.name}</h4>
              <div className='pt-1'>{data?.address_line_1}</div>

              <div className='pt-3 flex items-start md:items-center flex-col md:flex-row'>
                <div className='flex justify-start items-center'>
                  <StarIcon width={14} height={14} />
                  <StarIcon width={14} height={14} />
                  <StarIcon width={14} height={14} />
                  <StarIcon width={14} height={14} />
                  <StarIcon width={14} height={14} />
                  <span className='text-sm self-end pl-3'>4.5 of 35 Reviews</span>
                </div>
                <div className='pl-0 md:pl-5 pt-2 md:pt-0'>
                  <MessageNotification />
                </div>
              </div>
            </div>
          </div>
          <div className='col-span-5 md:col-span-2 grid grid-flow-row grid-cols-1 gap-2 place-content-start place-items-center md:place-items-end'>
            <div className='flex justify-evenly'>
              <Menu as="div" className="relative inline-block text-left" onChange={(e: any) => console.log(e)}>
                <div className='mx-2'>
                  <Button as={Menu.Button} label="+ ADD ITEM" width='w-150' />
                </div>
                <Transition
                  as={Fragment}
                  enterFrom="transform opacity-0 scale-95"
                  enter="transition ease-out duration-100"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className="absolute right-0 mt-2 w-60 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                  >
                    <div className="px-2 py-2">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/app/business/${business_id}/new?t=${PROMO_CODE}`}
                            className={`${
                              active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                            } group flex w-full items-center rounded-md px-2 py-5 text-sm`}
                          >
                            <div
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            >
                              <img src={percent} alt="Add New Promo Code" />
                            </div>
                            Add New Promo Code
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/app/business/${business_id}/new?t=${PRODUCT_SERVICE}`}
                            className={`${
                              active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                            } group flex w-full items-center rounded-md px-2 py-5 text-sm`}
                          >
                            <div
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            >
                              <img src={price_tag} alt="Add New Product or Service" />
                            </div>
                            Add New Product or Service
                          </Link>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <Button label="EDIT" width='w-150 mx-2' outlined />
            </div>
          </div>
        </div>
      </div>

      <div className='w-full pt-5'>
        <Tabs />
      </div>
    </div>
  );
}

export interface BusinessDetailsProps {
  data?: Business
}

export default Desktop;