import React from 'react';
import { Link } from 'react-router-dom';

const FooterLinks = (props: FooterLinksProps) => {
  return (
    <div className='mx-5 text-white'>
      <h6 className='text-sm font-semibold'>{props.title}</h6>
      <ul className='text-sm'>
        {props.links.map((l, i) => <li key={i} className='my-3'><Link to={l.to}>{l.label}</Link></li>)}
      </ul>
    </div>
  );
}

type LinkItem = {
  to: string
  label: string
}

export type FooterLinksProps = {
  title: string
  links: LinkItem[]
}

export default FooterLinks;