import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithCSRF } from '../fetchBaseQuery';
import { store } from '../../store/store';
import { OptionsResponse } from './types';
import { saveOption } from '../../store/categories/slice';
import { Category } from '../../models/Options';

const TAGS = {
    LIST: "CATEGORY/LIST",
};

export const categoriesApi = createApi({
    reducerPath: 'categoriesApi',
    baseQuery: baseQueryWithCSRF,
    tagTypes: [TAGS.LIST],
    endpoints: (builder) => ({
        options: builder.query<OptionsResponse, void>({
            query() {
                return {
                    url: '/api/int/options',
                    method: 'GET',
                };
            },
            transformResponse: (response: OptionsResponse) => {
                store.dispatch(saveOption(response.data));
                return response;
            }
        }),
        categoryList: builder.query<{ data: Array<Category> }, void>({
            providesTags: [TAGS.LIST],
            query() {
                return {
                    url: 'api/int/categories',
                    method: 'GET',
                };
            },
        }),
        create: builder.mutation<any, any>({
            query(params) {
                return {
                    url: 'api/int/categories',
                    method: 'POST',
                    body: params
                };
            },
            invalidatesTags: [TAGS.LIST],
        }),
        update: builder.mutation<any, any>({
            query(params) {
                return {
                    url: `api/int/categories/${params.id}`,
                    method: 'PUT',
                    body: params
                };
            },
            invalidatesTags: [TAGS.LIST],
        }),
        delete: builder.mutation<any, number>({
            query(id) {
                return {
                    url: `api/int/categories/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [TAGS.LIST],
        }),
    })
})

export const { useOptionsQuery, useCategoryListQuery, useCreateMutation, useUpdateMutation, useDeleteMutation } = categoriesApi