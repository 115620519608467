import React from 'react';
import massage from '../../assets/images/samples/massage.png';
import product_img from '../../assets/images/samples/product_img.png';
import Button from '../../components/Button';
import { useAppSelector } from '../../store/hooks';
import Counter from '../../components/TextInput/Counter';
import { useNavigate } from 'react-router-dom';
import { router_paths } from '../../routes';
import { Product } from '../../store/business/types';
import { computeDiscount } from '../../helpers/product';
import { useDispatch } from 'react-redux';
import { add, remove, update } from '../../store/cart/slice';
import { useCheckoutMutation } from '../../services/cart';

const Cart = () => {
  const navigate = useNavigate();
  const [checkout, { isLoading }] = useCheckoutMutation();
  const cart = useAppSelector(store => store.cart);
  const token = useAppSelector(store => store.auth.token);
  const cart_total_price = useAppSelector(store => {
    const cart = store.cart;
    let total = 0;
    cart.list.map(cart_item => {
      total += cart_item.item.price * cart_item.quantity;
    });

    return total;
  });

  const handleCheckout = async () => {
    if (!cart.list.length) return;
    try {
      const products = cart.list.map(item => ({ id: item.item.id, quantity: item.quantity }));
      const xhr: any = await checkout({ products });
      console.log(xhr);
      if (xhr?.error?.status >= 400) {
        throw xhr;
       }

      window.location.href = xhr?.data?.url || '#';    
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className='w-full'>
      <div className='container m-auto flex flex-col py-10'>
        <h4>Your Cart</h4>
        <div className='flex border-1 w-full gap-8 pt-5'>
          <div className='flex-1 border-t border-grey3'>
            {
              cart.list.map((item, i) => <CartItem index={i} key={item.item.id} data={item.item} quantity={item.quantity} />)
            }
            {
              !cart.list.length && (
                <div className='text-center py-5'><h5>Cart is empty</h5></div>
              )
            }
          </div>
          <div>
            <div className='flex flex-col text-center px-5 py-6 border border-grey3'>
              <span>Your Cart Total</span>
              <h3 className='pb-2'>${cart_total_price} USD</h3>
              {!token && <Button text='SIGN UP TO CHECKOUT' width='w-full px-16' onClick={() => navigate(router_paths.SIGN_IN)} />}
              {!!token && <Button text='CHECKOUT' disabled={!cart.list.length || isLoading} width='w-full px-24' onClick={handleCheckout} />}
            </div>
          </div>  
        </div>
      </div>
    </div>
  );
}

const CartItem = ({ data, quantity, index }: { data: Product, quantity: number, index: number }) => {
  const dispatch = useDispatch();
  const computeTotalItemPrice = (price: number, quantity: number) => {
    return (price * quantity);
  }

  const handleUpdateQuantity = (value: number) => {
    dispatch(update({
      index,
      quantity: value,
    }));
  }

  return (
    <div className='flex pr-5 relative py-5 border-b border-grey3'>
      <img src={data.image || product_img} className='w-20 h-20' alt="cart item" />
      <div className='flex flex-1 flex-col pl-4'>
        <span className="text-sm product-title text-span text-start font-bold pt-1 overflow-hidden text-ellipsis">Full Body Massage</span>
        <div className='text-sm text-left'>
          <p>{data.name}</p>
        </div>
        <div className='flex flex-row items-center'>
          <div className='text-sm font-bold'>${data.price}</div>
          <div className='border-gray border-l h-4 mx-3'/>
          <div className='flex-1 text-sm text-grey2'>{computeDiscount(data.original_price, data.price)}% OFF</div>
        </div>
      </div>
      <div className='flex justify-center'>
        <Counter min={1} width="w-1/2" value={quantity} onChangeValue={handleUpdateQuantity} />
      </div>
      <div className='flex flex-1 flex-col justify-between items-end'>
        <div className='text-sm font-bold'>${computeTotalItemPrice(data.price, quantity)}</div>
        <button className="mt-5 text-sm text-red" onClick={() => dispatch(remove({ index }))}>
          Remove
        </button>
      </div>
    </div>
  );
}

export default Cart;