import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ProductsTab from './ProductsTab';
import './index.scss';
import Settings from './Settings';
import Orders from './Orders';
import Overview from './Overview';
import { useEffect, useState } from 'react';
import { router_paths } from '../../../../routes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Reviews from './Reviews';
import PromoCodesTab from './PromoCodesTab';

const BusinessDetailsTabs = () => {

  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const url_params = useParams();
  const {
    business_id,

  } = url_params;
  const tab_list = [
    {
      label: "Overview",
      path: `${router_paths.BUSINESS}/${business_id}`,
    },
    {
      label: "Orders",
      path: `${router_paths.BUSINESS}/${business_id}/orders`,
    },
    {
      label: "Products",
      path: `${router_paths.BUSINESS}/${business_id}/products`,
    },
    {
      label: "Promo Codes",
      path: `${router_paths.BUSINESS}/${business_id}/promo-codes`,
    },
    {
      label: "Reviews",
      path: `${router_paths.BUSINESS}/${business_id}/reviews`,
    },
    {
      label: "Settings",
      path: `${router_paths.BUSINESS}/${business_id}/settings`,
    },
  ];

  useEffect(() => {
    const tab_index = tab_list.findIndex(tl => tl.path === location.pathname) || 0;

    setTabIndex(tab_index);
  }, [url_params]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleTabSelect = (index: number) => {
    setTabIndex(index);
    navigate(tab_list[index].path);
  }

  return (
    <Tabs selectedIndex={tabIndex} onSelect={handleTabSelect}>
      <div className='container m-auto'>
        <TabList>
          <Tab>Overview</Tab>
          <Tab>Orders</Tab>
          <Tab>Products</Tab>
          <Tab>Promo Codes</Tab>
          <Tab>Reviews</Tab>
          <Tab>Settings</Tab>
        </TabList>
      </div>
      <TabPanel>
        <Overview />
      </TabPanel>
      <TabPanel>
        <Orders />
      </TabPanel>
      <TabPanel>
        <ProductsTab />
      </TabPanel>
      <TabPanel>
        <PromoCodesTab />
      </TabPanel>
      <TabPanel>
        <Reviews />
      </TabPanel>
      <TabPanel>
        <Settings />
      </TabPanel>
    </Tabs>
  );
}

export default BusinessDetailsTabs;