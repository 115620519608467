export function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() || '' : "";
}

export const formatNameForImg = (name: string) => {
  const s = name.split(' ');

  if (s.length > 1) {
    if (s[1].charAt(0).match(/[0-9]/)) {
      return s[0];
    }
  }

  return name;
}

export const generateImageByName = (name: string) => {
  return `https://getstream.io/random_svg/?name=${formatNameForImg(name || '')}`;
}