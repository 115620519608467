import { BUSINESS_STATUS } from "../../constants/business";
import { ORDER_STATUS } from "../../constants/purchase_history";
import Button from "../Button";

const BusinessStatusChip = (props: any) => {
  let text = "";
  let bg = "";
  switch (props.status) {
    case BUSINESS_STATUS.ACTIVE:
      bg = "bg-[#86AD16]";
      break;
    case BUSINESS_STATUS.ON_TRIAL:
      bg = "bg-[#01539B]";
      break;
    case BUSINESS_STATUS.INACTIVE:
      bg = "bg-[#787878]";
      break;
  }

  return (
    <Button
      className={`${bg} h-7 uppercase`}
      bg_color="hover:opacity-100"
      border_radius="rounded-md"
      width="min-w-auto"
      text_color="text-white font-bold text-xs"
      text={props.text}
    />
  );
}

export default BusinessStatusChip;