const ReportItem = (props: { label: string, value: string }) => {
  return (
    <div>
      <div>
        <div className="flex flex-col py-12 px-10 rounded-md border border-grey3 items-center justify-center max-w-250">
          <h4 className="text-dark-blue-1">{props.value}</h4>
          <span className="text-sm text-center">{props.label}</span>
        </div>
      </div>
    </div>
  );
}

export default ReportItem;