import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';
import TextInput, { TextInputProps } from '.';

const Counter = ({
  interval = 1,
  value = 0,
  onAddClick,
  onSubtractClick,
  onChangeValue,
  min,
  max,
  width,
  ...props
}: CounterProps) => {
  const [count_value, setCountValue] = useState(value);

  useEffect(() => {
    setCountValue(value);
  }, [value]);

  const handleAdd = () => {
    const result = count_value + interval;

    if (!!max && result > max) return;

    if (!!onAddClick) onAddClick(result);
    setCountValue(result);
    onChangeValue(result);
  }
  const handleSubtract = () => {
    const result = count_value - interval;

    if (typeof min !== "undefined" && result < min) return;
    if (!!onSubtractClick) onSubtractClick(result)
    setCountValue(result);
    onChangeValue(result);
  }

  const handleOnChange = (e: ChangeEvent) => {
    onChangeValue(count_value);
  }

  return (
    <TextInput
      width={width || "w-full"}
      className="text-center"
      number
      left_component={
        <div className='p-2 px-5 cursor-pointer flex justify-center items-center border-light-grey' onClick={handleSubtract}>
          -
        </div>
      }
      right_component={
        <div className='p-2 px-5 cursor-pointer flex justify-center items-center border-light-grey' onClick={handleAdd}>
          +
        </div>
      }
      value={count_value}
      // onChange={(e) => {
      //   e.preventDefault();
      //   onChangeValue(count_value);
      // }}
      onChange={handleOnChange}
      {...props}
    />
  );
}

interface CounterProps extends TextInputProps {
  interval?: number
  value: number
  min?: number
  max?: number
  onAddClick?(value: number): void;
  onSubtractClick?(value: number): void;
  onChangeValue(value: number): void;
}

export default Counter;