import React, { ButtonHTMLAttributes }from 'react';
import { classNames } from '../../helpers/styles';
import { LinkProps } from 'react-router-dom';

const Button = ({
  primary,
  secondary,
  disabled,
  outlined = false,
  bg_color,
  text_size = 'text-sm',
  text_color,
  text,
  label,
  width,
  height,
  outline_color,
  border_radius,
  className: customClassName,
  as,
  ...props
}: ButtonProps) => {
  let width_class = width || 'w-md';
  let height_class = height || 'h-12';
  let bg_class = bg_color || 'bg-dark-blue/95 hover:bg-dark-blue/98 active:bg-dark-blue';
  let text_class = text_color || 'text-white';
  let border_color = '';
  const border_radius_class = border_radius || 'rounded-md';

  if (!!primary) {
    bg_class = 'bg-dark-blue/95 hover:bg-dark-blue/98 active:bg-dark-blue';
  }

  if (!!secondary) {
    bg_class = 'bg-green/95 hover:bg-green/98 active:bg-green';
  }

  if (!!outlined) {
    bg_class = 'white';
    border_color = !!outline_color ? `border border-${outline_color}` : 'border border-dark-blue';
    text_class = !!outline_color ? `text-${outline_color}` : 'text-dark-blue';

    if (!!primary) {
      bg_class = 'border border-dark-blue';
      text_class = 'text-dark-blue';
    }
  
    if (!!secondary) {
      bg_class = 'border border-green';
      text_class = 'text-green';
    }
  }

  if (!!disabled) {
    text_class = 'text-dark-grey';
    
    if (!!outlined) {
      border_color = 'border border-grey';
    } else {
      bg_class = 'bg-grey';
    }
  }

  let className = classNames('px-3 disabled:opacity-75', bg_class, width_class, border_color, height_class, border_radius_class, customClassName);

  if (!!as) {
    const BtnComponent = as;
    return (
      <BtnComponent disabled={disabled} className={className} {...props}>
        <span className={classNames('text-sm font-bold bg-transparent', text_size, text_class)}>{label || text || props.children}</span>
      </BtnComponent>
    );
  }

  return (
    <button disabled={disabled} className={className} {...props}>
      <span className={classNames('text-sm font-bold bg-transparent', text_size, text_class)}>{label || text || props.children}</span>
    </button>
  );
} 

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean
  secondary?: boolean
  disabled?: boolean
  outlined?: boolean
  outline_color?: string
  border_radius?: string
  bg_color?: string // sample: bg-red/95 hover:bg-red/98 active:bg-red
  text_color?: string // use tailwind class for text
  text?: string
  text_size?: string
  label?: string
  width?: string | 'w-md' | 'w-full'
  height?: string | "h-12" | "h-10"
  className?: string
  as?: any
  to?: string
}

export default Button;