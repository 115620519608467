import React, { Fragment, InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { Popover, Transition, Disclosure } from "@headlessui/react";
import Logo from '../../../src/assets/images/valu_menu.svg';
import Spa from '../../../src/assets/images/streamline-icon-spa-stone-1@48x48.svg';
import Hotels from '../../../src/assets/images/streamline-icon-hotel-double-bed-1@48x48.svg';
import ShoppingBag from '../../../src/assets/images/icons/shopping_bag.svg';
import Search from '../../../src/assets/images/icons/search.svg';
import BurgerIcon from '../../../src/assets/images/icons/burger_icon.svg';
import ProfilePic from '../../assets/images/profile_pic.png';
import Path from '../../assets/images/icons/path.svg';
import ShoppingBagGray from '../../assets/images/shopping_bag_gray.svg';
import SearchGrayIcon from '../../assets/images/search_gray.svg';
import NotificationIcon from '../../assets/images/icons/notification_icon.svg';
import './Header.scss';
import Button from '../Button';
import { Link } from "react-router-dom";
import { AuthUser } from "../../store/auth/types";
import Accessories from '../../assets/images/icons/categories/accessories.svg';
import ArtsAndDesign from '../../assets/images/icons/categories/arts_&_design.svg';
import Automotive from '../../assets/images/icons/categories/automotive.svg';
import CafesAndTreats from '../../assets/images/icons/categories/cafes_&_treats.svg';
import Cosmetics from '../../assets/images/icons/categories/cosmetics.svg';
import Dental from '../../assets/images/icons/categories/dental.svg';
import Electronics from '../../assets/images/icons/categories/electronics.svg';
import Entertainment from '../../assets/images/icons/categories/entertainment.svg';
import FitnessAndGym from '../../assets/images/icons/categories/fitness_&_gym.svg';
import FunAndLeisure from '../../assets/images/icons/categories/fun_&_leisure.svg';
import GiftsAndSurprises from '../../assets/images/icons/categories/gifts_&_surprises.svg';
import GroceriesAndMarkets from '../../assets/images/icons/categories/groceries_&_markets.svg';
import HomeService from '../../assets/images/icons/categories/home_service.svg';
import KidsAndToys from '../../assets/images/icons/categories/kids_&_Toys.svg';
import Medical from '../../assets/images/icons/categories/medical.svg';
import NightLife from '../../assets/images/icons/categories/night_life.svg';
import PersonalServices from '../../assets/images/icons/categories/personal_services.svg';
import Pets from '../../assets/images/icons/categories/pets.svg';
import Restaurants from '../../assets/images/icons/categories/restaurants.svg';
import Retail from '../../assets/images/icons/categories/retail.svg';
import Others from '../../assets/images/icons/categories/others.svg';
import BeautyAndWellness from '../../assets/images/icons/categories/beauty_&_wellness.svg';
import HotelsAndTravels from '../../assets/images/icons/categories/hotels_&_travels.svg';
import CartPopover from "../CartPopover";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logout } from "../../store/auth/slice";
import { authApi } from "../../services/auth";
import { AUTH_TAGS } from "../../services/auth/types";
import Searchbar from "./Searchbar";
import { formatNameForImg } from "../../helpers/utils";

type cat_icons = {
    [key: string]: string;
};

type featured = []

const category_icons: cat_icons = {
    Accessories,
    ArtsAndDesign,
    Automotive,
    CafesAndTreats,
    Cosmetics,
    Dental,
    Electronics,
    Entertainment,
    FitnessAndGym,
    FunAndLeisure,
    GiftsAndSurprises,
    GroceriesAndMarkets,
    HomeService,
    KidsAndToys,
    Medical,
    NightLife,
    PersonalServices,
    Pets,
    Restaurants,
    Retail,
    Others,
    BeautyAndWellness,
    HotelsAndTravels
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const Header = ({
    list_categories,
    user_data,
    ...props
}: HeaderProps) => {
    const token = useAppSelector(store => store.auth.token);
    const cart = useAppSelector(store => store.cart);
    const dispatch = useAppDispatch();
    const [show_searchbar, setShowSearchbar] = useState(false);
    const user_name = formatNameForImg(user_data?.name || '');

    const el = document.getElementById("PopoverButton");
    let mobileView = false;
    let login = !!token;
    let featured_categories = [{
        color: '',
        id: 0,
        is_featured: false,
        logo: '',
        name: ''
    }];
    let temp_arr = [];
    let has_business = user_data?.roles[0] === 'business owner' || user_data?.roles[1] === 'business owner' ? true : false;

    for (let i = 0; i < list_categories.length; i++) {
        if (list_categories[i].is_featured) {
            temp_arr.push(list_categories[i]);
        }
    }

    useEffect(() => {
        if (!!cart) {
            el?.click();
            setTimeout(() => {
                const attr = el?.getAttribute('data-headlessui-state') || '';
                if (attr === 'open') {
                    el?.click();
                }
            }, 3000);
        }
    }, [cart]);

    featured_categories = temp_arr;

    const handleLogout = () => {
        // window.location.reload();
        dispatch(authApi.util.invalidateTags([AUTH_TAGS.ME, AUTH_TAGS.LOGIN]));
        dispatch(authApi.util.resetApiState());
        dispatch(logout());
    }

    return (
        <header>
            <nav>
                <Popover id="HeaderComponent" className=" bg-white h-24 border-b border-solid border-slate-300 fixed w-full z-50">
                    <div className="my-5 mx-6">
                        <div className="flex items-center">
                            <div className="lg:inline-block lg:w-1/3 md:hidden sm:hidden max-sm:hidden">
                                <a href="/app">
                                    <img
                                        className="h-8 w-auto sm:h-10 lg:m-0 m-auto"
                                        src={Logo}
                                        alt=""
                                    />
                                </a>
                            </div>
                            <div className="flex justify-between lg:hidden w-full max-h-screen z-10">
                                <div className="lg:hidden">
                                    <Popover.Button>
                                        <img src={BurgerIcon} alt="" aria-hidden="true" className="focus:focus-visible:outline-none" />
                                    </Popover.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className='fixed w-screen z-10  left-0 top-12 overflow-y-auto pop-over'>
                                            <div className="bg-white">
                                                <div className="text-left font-sans text-lg leading-10 mt-12 mx-5 tracking-normal pb-8">
                                                    {login ?
                                                        <div className="flex py-8 space-x-6">
                                                            <div>
                                                                {/* <img src={ProfilePic} alt="" className="w-12 h-12" /> */}
                                                                <img src={`https://getstream.io/random_svg/?name=${user_name}`} alt="business" className="w-12 h-12 object-cover rounded-full" />
                                                            </div>
                                                            <div className="inline-block leading-4">
                                                                <span className="font-sans text-base font-bold tracking-normal">{user_data?.name}</span><br />
                                                                <span className="font-sans text-base tracking-normal">{user_data?.email}</span>
                                                            </div>
                                                        </div> : ''
                                                    }

                                                    <div>
                                                        <Link to='/app'>Home</Link>
                                                    </div>
                                                    <div>
                                                        <Disclosure as="div">
                                                            {({ open }) => (
                                                                <>
                                                                    <Disclosure.Button className="flex w-full justify-between">
                                                                        <span>Explore</span>
                                                                        <div className="flex-none pt-4">
                                                                            <img src={Path} alt="" className={classNames(open ? 'rotate-180' : '')} />
                                                                        </div>
                                                                    </Disclosure.Button>

                                                                    <Disclosure.Panel>
                                                                        <div className="mx-8 font-sans text-base tracking-normal leading-7">
                                                                            {list_categories.map((item, index) => (
                                                                                <a
                                                                                    key={index}
                                                                                    href="#"
                                                                                    className=""
                                                                                >
                                                                                    <div className="">
                                                                                        <p className="">{item.name}</p>
                                                                                    </div>
                                                                                </a>
                                                                            ))}
                                                                        </div>
                                                                    </Disclosure.Panel>
                                                                </>
                                                            )}
                                                        </Disclosure>
                                                    </div>
                                                    <div>
                                                        <Link to='/app/for-business?type=display'>For Business</Link>
                                                    </div>
                                                    <div>
                                                        <Link to='/app/for-business?type=pricing'>Pricing</Link>
                                                    </div>
                                                    <div>
                                                        <span>Contact Us</span>
                                                    </div>
                                                    {login ?
                                                        <div>
                                                            <span>Notification</span>
                                                        </div> : ''
                                                    }
                                                    {login ?
                                                        <div>
                                                            <button>Sign out</button>
                                                        </div> : ''
                                                    }

                                                    {!login ?
                                                        <div className="flex space-x-2 mt-8">
                                                            <div>
                                                                <Button text="JOIN" width="w-32" />
                                                            </div>
                                                            <div className="inline-block">
                                                                <Button text='SIGN IN' outlined={true} width='w-32' />
                                                            </div>
                                                        </div> : ''
                                                    }

                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </div>
                                <div className="m-auto">
                                    <a href="/app">
                                        <img
                                            className="h-8 w-auto sm:h-10 lg:m-0 m-auto"
                                            src={Logo}
                                            alt=""
                                        />
                                    </a>
                                </div>
                                <div className="flex lg:hidden space-x-4">
                                    <div>
                                        <img
                                            src={Search}
                                            className="cursor-pointer"
                                            alt=""
                                            onClick={() => {
                                                setShowSearchbar(true);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <img src={ShoppingBag} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className="md:hidden sm:hidden max-sm:hidden lg:inline-block lg:space-x-10 text-center lg:w-1/3">
                                <div className="flex justify-between">
                                    <div>
                                        <Link to='/app' className="text-base font-medium text-black font-sans text-center">
                                            Home
                                        </Link>
                                    </div>
                                    <div>
                                        <Popover>
                                            {({ open, close }) => (
                                                <>
                                                    <Popover.Button className="focus:outline-none">
                                                        <div className="flex space-x-3">
                                                            <a href="#" className="text-base font-medium text-black font-sans">
                                                                Explore
                                                            </a>
                                                            <img alt="" src={Path} className={classNames(open ? 'rotate-180' : '', 'w-3 h-2 mt-2')} />
                                                        </div>
                                                    </Popover.Button>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-200"
                                                        enterFrom="opacity-0 translate-y-1"
                                                        enterTo="opacity-100 translate-y-0"
                                                        leave="transition ease-in duration-150"
                                                        leaveFrom="opacity-100 translate-y-0"
                                                        leaveTo="opacity-0 translate-y-1"
                                                    >
                                                        <Popover.Panel className="absolute z-10 -ml-4 mt-3 max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                                                            <div className="explore-panel overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                                                <div>
                                                                    <div className="label-categories text-left">
                                                                        <span className="explore-by-categorie px-2">Explore by categories</span>
                                                                    </div>
                                                                    <div className="label-categories px-2 text-left">
                                                                        <span className="explore-by-categorie pl-32">Featured Categories</span>
                                                                    </div>
                                                                </div>
                                                                <div className="float-left grid grid-cols-2 gap-24">
                                                                    <div className="float-left relative grid bg-white px-5">
                                                                        {list_categories.map((item, index) => {
                                                                            if (index < 11) {
                                                                                return (
                                                                                    <a
                                                                                        key={index}
                                                                                        href="#"
                                                                                        className="explore-item -m-3 flex items-start rounded-lg p-3"
                                                                                    >
                                                                                        <div className="ml-4">
                                                                                            <p className="categories-lists">{item.name}</p>
                                                                                        </div>
                                                                                    </a>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </div>
                                                                    <div className="float-left relative grid bg-white px-5">
                                                                        {list_categories.map((item, index) => {
                                                                            if (index >= 11) {
                                                                                return (
                                                                                    <a
                                                                                        key={index}
                                                                                        href="#"
                                                                                        className="explore-item -m-3 flex items-start rounded-lg p-3"
                                                                                    >
                                                                                        <div className="ml-4">
                                                                                            <p className="categories-lists">{item.name}</p>
                                                                                        </div>
                                                                                    </a>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                <div className="float-left line mb-8 -mt-10"></div>

                                                                <div className="float-left featured-area grid grid-cols-2 gap-3 ml-1">

                                                                    <div className="bg-white">

                                                                        {featured_categories.map((item, index) => {

                                                                            if (index < 3) {
                                                                                return (
                                                                                    <a
                                                                                        key={index}
                                                                                        href="#"
                                                                                        className="-m-3 flex items-start rounded-lg p-3 card"
                                                                                    >

                                                                                        <div className="rounded-md border" style={{ backgroundColor: `${item.color}`, height: '5.5625rem', width: '6.1875rem' }}>
                                                                                            <div className="flex justify-center mt-3">
                                                                                                <img src={category_icons[item.name.replaceAll(' ', '').replaceAll('&', 'And')]} alt="" className="w-8 h-8" />
                                                                                            </div>
                                                                                            <div className="leading-4">
                                                                                                <span className="font-sans text-xs font-bold">{item.name}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                )
                                                                            }

                                                                        })}
                                                                    </div>
                                                                    <div className="bg-white">
                                                                        {featured_categories.map((item, index) => {
                                                                            if (index >= 3) {
                                                                                return (
                                                                                    <a
                                                                                        key={index}
                                                                                        href="#"
                                                                                        className="-m-3 flex items-start rounded-lg p-3"
                                                                                    >

                                                                                        <div className="rounded-md border" style={{ backgroundColor: `${item.color}`, height: '5.5625rem', width: '6.1875rem' }}>
                                                                                            <div className="flex justify-center mt-3">
                                                                                                <img src={category_icons[item.name.replaceAll(' ', '').replaceAll('&', 'And')]} alt="" className="w-8 h-8" />
                                                                                            </div>
                                                                                            <div className="leading-4">
                                                                                                <span className="font-sans text-xs font-bold">{item.name}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                <div className="inline-block mr-24 mt-5">
                                                                    <Link to='/app/categories' onClick={close} className="text-blue font-sans text-sm">See all categories</Link>
                                                                </div>
                                                            </div>
                                                        </Popover.Panel>
                                                    </Transition>
                                                </>
                                            )}
                                        </Popover>
                                    </div>
                                    <div>
                                        <Link to='/app/for-business?type=display' className="text-base font-medium text-black font-sans">For Business</Link>
                                    </div>
                                    <div>
                                        <Link to='/app/for-business?type=pricing' className="text-base font-medium text-black font-sans">Pricing</Link>
                                    </div>
                                    <div>
                                        <a href="#" className="text-base font-medium text-black font-sans">
                                            Contact Us
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="md:hidden sm:hidden max-sm:hidden lg:inline-block lg:space-x-10 lg:w-1/3">
                                <div className="flex float-right justify-between space-x-6">
                                    <div>
                                        <img src={SearchGrayIcon} alt="" className="py-3 cursor-pointer" onClick={() => setShowSearchbar(true)} />
                                    </div>
                                    <div>
                                        <Popover className="relative">
                                            {({ open, close }) => (
                                                <>
                                                    <Popover.Button className="focus:outline-none" id="PopoverButton">
                                                        <img src={ShoppingBagGray} alt="" className="py-3" />
                                                    </Popover.Button>
                                                    <CartPopover close={close} />
                                                </>
                                            )}
                                        </Popover>
                                    </div>
                                    {!login ?
                                        <div className="my-3">
                                            <span className="text-base font-medium text-black font-san">Sign in</span>
                                        </div> :
                                        <div className="relative">
                                            <img src={NotificationIcon} alt="" className="py-3" />
                                            <div className="absolute bg-red h-2 w-2 top-4 right-0 rounded-full" />
                                        </div>
                                    }

                                    {!login ?
                                        <div>
                                            <Button text="JOIN" width="w-32" />
                                        </div> : ''
                                    }
                                    {login ?
                                        <div>
                                            <Popover>
                                                {({ open, close }) => (
                                                    <>
                                                        <Popover.Button className="focus:outline-none">
                                                            <div className="flex space-x-4">
                                                                <img src={`https://getstream.io/random_svg/?name=${user_name}`} alt="business" className="w-12 h-12 object-cover rounded-full" />
                                                                <img alt="" src={Path} className="w-3 h-2 mt-5" />
                                                            </div>
                                                        </Popover.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-200"
                                                            enterFrom="opacity-0 translate-y-1"
                                                            enterTo="opacity-100 translate-y-0"
                                                            leave="transition ease-in duration-150"
                                                            leaveFrom="opacity-100 translate-y-0"
                                                            leaveTo="opacity-0 translate-y-1"
                                                        >
                                                            <Popover.Panel className='absolute z-10 -ml-52 mt-5'>
                                                                <div className="rounded-md border bg-dark-color" style={{ height: '20.8125rem', width: '17.8125rem' }}>
                                                                    <div className="mx-6">
                                                                        <Link to='/app/profile?t=0' onClick={close}>
                                                                            <div className="flex mt-7">
                                                                                {/* <img src={ProfilePic} alt="" className="h-12 w-12" /> */}
                                                                                <div className="h-12 w-12">
                                                                                    <img src={`https://getstream.io/random_svg/?name=${user_name}`} alt="business" className="w-full h-full object-cover rounded-full" />
                                                                                </div>
                                                                                <div className="text-white ml-4 leading-3 pt-1">
                                                                                    <span className="font-bold text-sm font-montserrat">{user_data?.name}</span><br />
                                                                                    <span className="text-xs font-sans">{user_data?.email}</span>
                                                                                </div>
                                                                            </div>
                                                                        </Link>

                                                                        <div className="w-full border opacity-5 my-6"></div>
                                                                        <div className="mb-1"><Link onClick={close} className="text-sm font-sans text-white" to='/app/profile/settings'>Account Settings</Link></div>
                                                                        {
                                                                            has_business ? <div className="mb-1"><Link to='/app/profile/business' className="text-sm font-sans text-white">Businesses</Link></div> : ''
                                                                        }
                                                                        <div className="mb-1"><Link onClick={close} to='/app/profile/messages' className="text-sm font-sans text-white">Messages</Link></div>
                                                                        <div className="mb-1"><Link onClick={close} to='/app/profile/help' className="text-sm font-sans text-white">Contact us</Link></div>
                                                                        <div className="mb-1"><button onClick={handleLogout} className="text-sm font-sans text-white">Sign out</button></div>
                                                                        <div className="flex opacity-50 text-light-color-opacity text-xs mt-8">
                                                                            <span>Privacy</span>
                                                                            <span className="pl-4">|</span>
                                                                            <span className="pl-4">Terms & Conditions</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Popover.Panel>

                                                        </Transition>
                                                    </>
                                                )}
                                            </Popover>

                                        </div> : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Searchbar show={show_searchbar} hideSearchbar={() => setShowSearchbar(false)} />
                </Popover>
            </nav>
        </header>
    );
}

export interface HeaderProps extends InputHTMLAttributes<HTMLInputElement> {
    list_categories: [{
        color: string,
        id: number,
        is_featured: boolean,
        logo: string,
        name: string
    }],
    user_data: AuthUser | undefined
}

export default Header;