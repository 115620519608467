import { getImageSize } from "react-image-size";

type ImageValidationOption = {
  min_width?: number,
  min_height?: number,
  ratio?: number,
}

export const isValidImageAspectRatio = async (image: string, options?: ImageValidationOption) => {
  const { width, height } = await getImageSize(image);

  if (!!options?.min_width && width < options?.min_width) {
    return false;
  }

  if (!!options?.min_height && height < options?.min_height) {
    return false;
  }

  if (!!options?.ratio) {
    const aspect_ratio = Math.round((width / height) * 100) / 100;
    const ratio_rounded = Math.round(options?.ratio * 100) / 100;

    if (aspect_ratio >= (ratio_rounded - (ratio_rounded * .1)) && aspect_ratio <= (ratio_rounded + (ratio_rounded * .1))) {
      return true;
    } else {
      return false;
    }
  }
}