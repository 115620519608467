
import { useNavigate, useParams } from "react-router-dom";
import { StarIcon } from "@heroicons/react/20/solid";
import ReportItem from "../../../components/ReportItem";
import Select from "../../../components/Select";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import User from "../../../models/User";
import ProfilePic from '../../../assets/images/profile_pic.png';
import { generateImageByName } from "../../../helpers/utils";
import { Category } from "../../../models/Options";
import { useCategoryListQuery, useDeleteMutation } from "../../../services/categories";
import AddCategory from "../Modals/AddCategory";
import { useState } from "react";
import DeleteModal from "../Modals/DeleteModal";
import Swal from "sweetalert2";

const AdminCategories = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [delete_modal, setDeleteModal] = useState(false);
  const [show_modal, setShowModal] = useState(false);
  const [modal_data, setModalData] = useState<any>({});
  const { data } = useCategoryListQuery();
  const [deleteCategory] = useDeleteMutation();
  const list = data?.data || [];

  const handleDelete = async (id: number) => {
    try {
      const xhr = await deleteCategory(id);

      if ((xhr as any)?.error?.status >= 400) {
        throw (xhr as any);
      }

      if ((xhr as any)?.error?.status >= 400) {
        throw (xhr as any);
      }

      Swal.fire({
        title: 'Success!',
        text: `Category Deleted Successfully!`,
        icon: 'success',
        confirmButtonText: 'Ok',
        didClose() {
          setDeleteModal(false);
          setModalData(undefined);
        },
      });
    } catch (e: any) {
      console.log('error ', e);

      Swal.fire({
        title: 'Error!',
        text: e?.error?.data?.message || 'An error occurred. Please try again.',
        icon: 'error',
        confirmButtonText: 'Ok',
        didClose() {
          setDeleteModal(false);
          setModalData(undefined);
        },
      });
    }
  }

  return (
    <div className="container m-auto rounded-sm py-10 px-11">
      <AddCategory
        data={modal_data}
        is_open={show_modal}
        onRequestClose={() => setShowModal(false)}
      />
      <DeleteModal
        data={modal_data}
        is_open={delete_modal}
        title="Are you sure you want to delete this category?"
        image={generateImageByName(modal_data?.name || '')}
        name={modal_data?.name || ''}
        desc={`${modal_data?.businesses_count || 0} Business${modal_data?.businesses_count !== 1 ? 'es' : ''}`}
        onRequestClose={() => {
          setDeleteModal(false);
          setModalData(undefined);
        }}
        handleSubmit={(d: any) => handleDelete(d.id)}
      />
      <div className="flex justify-between items-center">
        <div className="flex">
          <h4>Categories ({list.length})</h4>
        </div>
        <div className="flex">
          <div className="pr-2">
            <Select
              placeholder='This month'
              onChange={() => { }}
              options={[
                {
                  label: 'option 1',
                  value: 1
                },
                {
                  label: 'option 2',
                  value: 2
                },
                {
                  label: 'option 3',
                  value: 3
                },
                {
                  label: 'option 4',
                  value: 4
                },
              ]}
              value={undefined}
            />
          </div>
          <div>
            <Button width="w-150" label="+ ADD CATEGORY" onClick={() => setShowModal(true)} />
          </div>
        </div>
      </div>
      <div className="flex pt-4">
        <div className="grid grid-cols-4 w-full gap-1 items-center border-t border-border-default pt-3 pb-5 font-bold text-xs text-dark-grey px-5">
          <div className="col-span-1">
            CATEGORY NAME
          </div>
          <div className="col-span-1">
            BUSINESSES
          </div>
          <div className="col-span-1">
            URL
          </div>
          <div className="col-span-1">
            ACTION
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3 pb-5">
        {
          list.map(c => <Item
            category={c}
            onClickEdit={() => {
              setModalData(c);
              setShowModal(true);
            }}
            onClickDelete={() => {
              setModalData(c);
              setDeleteModal(true);
            }}
          />)
        }
      </div>
    </div>
  );
}
const Item = (props: { category: Category, onClickEdit: () => void, onClickDelete: () => void }) => {

  return (
    <div className="grid grid-cols-4 w-full gap-1 items-center border rounded-md border-border-default p-5">
      <div className="col-span-1 text-sm">
        {props.category.name}
      </div>
      <div className="col-span-1 text-sm">
        {props.category.business_count || 0}
      </div>
      <div className="col-span-1 text-sm">
        <Link to={props.category.url || ''} className="pr-2 text-blue">{props.category.url || ''}</Link>
      </div>
      <div className="col-span-1 text-xs">
        <div className="flex flex-row">
          <Link to="#" className="pr-2 text-blue">View Details</Link>
          <Link to="#" onClick={props.onClickEdit} className="pr-2 text-blue">Edit</Link>
          <Link to="#" onClick={props.onClickDelete} className="pr-2 text-red">Delete</Link>
        </div>
      </div>

    </div>
  );
}

export default AdminCategories;