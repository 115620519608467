
import React, { useState } from 'react';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Radio from '../../components/Radio';
import Select from '../../components/Select';
import TextInput from '../../components/TextInput';
import { BeakerIcon } from '@heroicons/react/24/solid'


const StyleGuide = () => {
  const [select, setSelect] = useState(undefined);
  return (
    <div className='container flex-auto justify-center items-center m-auto text-center'>
      <h1 className='text-h1'>Product Details</h1>
      <h2 className='text-h2'>Product Details</h2>
      <h3 className='text-h3'>Product Details</h3>
      <h4 className='text-h4'>Product Details</h4>
      <h5 className='text-h5'>Product Details</h5>
      <h6 className='text-h6'>Product Details</h6>
      <div className='pt-5'><Button text='PRIMARY' /></div>
      <div className='pt-5'><Button text='TEST' /></div>
      <div className='pt-5'><Button text='SECONDARY' secondary /></div>
      <div className='pt-5'><Button text='SECONDARY' secondary disabled /></div>
      <div className='pt-5'><Button text='CUSTOM' bg_color='bg-red/95 hover:bg-red/98 active:bg-red' /></div>
      <div className='pt-5 flex justify-center'>
        <Select
          placeholder='Select'
          onChange={setSelect}
          options={[
            {
              label: 'option 1',
              value: 1
            },
            {
              label: 'option 2',
              value: 2
            },
            {
              label: 'option 3',
              value: 3
            },
            {
              label: 'option 4',
              value: 4
            },
          ]}
          value={select}
          error
          message={'This is an error'}
        />
      </div>
      <div className='pt-5'>
        <Radio error label="Radio box" />
      </div>
      <div className='pt-5'>
        <Checkbox error label="Checkbox" />
      </div>
      <div className='pt-5'><Button text='PRIMARY' /></div>
      <div className='pt-5 flex justify-center'><TextInput disabled placeholder='Text Input' error message={["Error something", "Error something", "Error something"]} /></div>
      <div className='pt-5 flex justify-center'>
        <TextInput placeholder='Text Input'
          left_icon={<BeakerIcon className="h-6 w-6 text-blue-500"/>}
        />
      </div>
      <div className='pt-5 flex justify-center'>
        <TextInput placeholder='Text Input'
          right_icon={<BeakerIcon className="h-6 w-6 text-blue-500"/>}
        />
      </div>
      <div className='pt-5 flex justify-center'>
        <TextInput placeholder='Text Input'
          error
          left_icon={<BeakerIcon className="h-6 w-6 text-blue-500"/>}
        />
      </div>
    </div>
  );
}

export default StyleGuide;