import { PURCHASE_STATUS } from "../../constants/purchase_history";
import Button from "../Button";

const PurchaseStatusChip = (props: any) => {
  let text = "UNCLAIMED";
  let bg = "bg-sky-blue";
  switch (props.status) {
    case PURCHASE_STATUS.CLAIMED:
      text = "CLAIMED";
      bg = "bg-primary-dark";
      break;
    case PURCHASE_STATUS.UNCLAIMED:
      text = "UNCLAIMED";
      bg = "bg-sky-blue";
      break;
    case PURCHASE_STATUS.EXPIRED:
      text = "EXPIRED";
      bg = "bg-red";
      break;
  }

  return (
    <Button
      className={`${bg} px-1 h-8`}
      bg_color="hover:opacity-100"
      border_radius="rounded-md"
      width="min-w-[100px]"
      text_color="text-white font-bold text-xs"
      text={text}
    />
  );
}
export default PurchaseStatusChip;