import React, { ChangeEvent, InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { classNames } from '../../helpers/styles';
import { categories } from "../../helpers/constants";
import TextInput from "../TextInput";
import Select from "../Select";
import AddIcon from '../../assets/images/add_icon.svg';
import Button from "../Button";
import signUpSlice, { Keys as BusinessSignUpKeys, onBusinessSignUpFormChange, reset, uploadLogo } from "../../store/business_signup/slice";
import { WritableDraft } from "immer/dist/internal";
import SignUp from "../../screens/SignUp";
import { AuthUser } from "../../store/auth/types";
import { useOptionsV2Query } from "../../services/options";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getImageSize } from "react-image-size";
import { useBusinessCreateMutation, useUploadBusinessLogoMutation } from "../../services/business";
import { BusinessUpdateFields } from "../../store/business/types";
import { BusinessBasic } from "../../models/Business";
import Swal from "sweetalert2";

const BusinessDetails = ({
    business_type = '',
    ...props
}: BusinessDetailsProps) => {

    const { data: options } = useOptionsV2Query();
    const [createBusiness, { isLoading: isLoadingCreateBusiness }] = useBusinessCreateMutation();
    const [uploadBusinessLogo] = useUploadBusinessLogoMutation();
    const data = useAppSelector(store => store.business_signup);
    const {
        business_name,
        address,
        city,
        state,
        category,
        zip_code,
        business_phone_number,
        url_err: errorOnFile,
        image_url: img_url,
        image_file,
    } = data;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const states = options?.states || [];
    const categories = options?.categories || [];
    let [stateSelect, setStateSelect] = useState(undefined);
    const [categorySelect, setCategorySelect] = useState(undefined);
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);
    const [field_errors, setFieldErrors] = useState({
        business_name: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        business_phone_number: '',
        category: '',
    });

    const handleSubmit = async () => {
        if (!validateFields()) return;

        try {
            const params: BusinessBasic = {
                name: business_name,
                address_line_1: address,
                city: city,
                state: state?.value,
                zip: zip_code,
                category_id: category?.value,
                phone_number: business_phone_number
            }
            const { data } = await createBusiness(params).unwrap();
            if (!!image_file && data && data.id) { // upload business logo
                const image = new FormData();
                image.append('image', image_file || '');
                const businessLogoParams = {
                    id: data.id,
                    image,
                };
                await uploadBusinessLogo(businessLogoParams);
            }


            Swal.fire({
                title: 'Success!',
                text: 'Updated Successfully!',
                icon: 'success',
                confirmButtonText: 'Ok',
                didClose() {
                    navigate(`/app/business/${data.id}`);
                },
            });

            dispatch(reset());

        } catch (e) {

        } finally {

        }
    }

    const handleClick = () => {
        hiddenFileInput.current?.click();
    }

    const onHandleChange = (key: BusinessSignUpKeys, value: any) => {
        setFieldErrors({ ...field_errors, [key]: '' })
        dispatch(onBusinessSignUpFormChange({ key, value }));
    }

    const onFileUpload = async (key: BusinessSignUpKeys, e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const validate_image = await validateImageSize('url_err', URL.createObjectURL(e.target.files[0]));

            if (!!validate_image) {
                let url = URL.createObjectURL(e.target.files[0]);
                dispatch(uploadLogo({ url, err: false, image_file: e.target.files[0] }));
            } else {
                dispatch(uploadLogo({ url: '', err: true, image_file: undefined }));
            }
        }
    }

    const validateImageSize = async (key: BusinessSignUpKeys, image: string) => {
        const { width, height } = await getImageSize(image);
        return width >= 512 && (width === height);
    }

    const validateFields = () => {
        let has_errors = false;
        const error_msg = "This field is required."
        const new_field_errors = { ...field_errors };
        if (!business_name) {
            has_errors = true;
            new_field_errors.business_name = error_msg;
        }
        if (!address) {
            has_errors = true;
            new_field_errors.address = error_msg;
        }
        if (!city) {
            has_errors = true;
            new_field_errors.city = error_msg;
        }
        if (!state) {
            has_errors = true;
            new_field_errors.state = error_msg;
        }
        if (!zip_code) {
            has_errors = true;
            new_field_errors.zip_code = error_msg;
        }
        if (!business_phone_number) {
            has_errors = true;
            new_field_errors.business_phone_number = error_msg;
        }
        if (!category) {
            has_errors = true;
            new_field_errors.category = error_msg;
        }
        if (!state) {
            has_errors = true;
            new_field_errors.state = error_msg;
        }

        if (has_errors) {
            setFieldErrors(new_field_errors);
        }

        return !has_errors;
    }

    return (
        <div>
            <div>
                <span className='text-blue lg:text-h4 font-bold font-montserrat md:text-h5 sm:text-h5 max-sm:text-h5'>
                    {business_type === 'sign_up' ?
                        'Business Details' : 'Add New Business'
                    }
                </span>
            </div>
            <div className='mt-5'>
                <TextInput
                    placeholder="Business name"
                    width="w-full"
                    value={business_name}
                    onChange={(e) => onHandleChange('business_name', e.target.value)}
                    error={!!field_errors.business_name}
                    message={field_errors.business_name}
                />
                <TextInput
                    placeholder="Address 1"
                    width="w-full"
                    value={address}
                    onChange={(e) => onHandleChange('address', e.target.value)}
                    error={!!field_errors.address}
                    message={field_errors.address}
                />
            </div>
            <div className="lg:hidden">
                <TextInput
                    placeholder="City"
                    width="w-full"
                    value={city}
                    onChange={(e) => onHandleChange('city', e.target.value)}
                    error={!!field_errors.city}
                    message={field_errors.city}
                />
            </div>
            <div className="lg:flex space-x-3 sm:hidden md:hidden max-sm:hidden">
                <TextInput
                    placeholder="City"
                    width="w-full"
                    value={city}
                    onChange={(e) => onHandleChange('city', e.target.value)}
                    error={!!field_errors.city}
                    message={field_errors.city}
                />
                <Select
                    placeholder='State'
                    onChange={(value) => onHandleChange('state', value)}
                    options={states}
                    value={state}
                    width="w-full"
                    error={!!field_errors.state}
                    message={field_errors.state}
                />
                <TextInput
                    placeholder="Zip code"
                    width="w-full"
                    value={zip_code}
                    onChange={(e) => onHandleChange('zip_code', e.target.value)}
                    error={!!field_errors.zip_code}
                    message={field_errors.zip_code}
                />
            </div>
            <div className="flex space-x-3 lg:hidden">
                <Select
                    placeholder='State'
                    onChange={(value) => onHandleChange('state', value)}
                    options={states}
                    value={state}
                    width='w-1/2'
                    error={!!field_errors.state}
                    message={field_errors.state}
                />
                <TextInput
                    placeholder="Zip code"
                    width='w-1/2'
                    value={zip_code}
                    onChange={(e) => onHandleChange('zip_code', e.target.value)}
                    error={!!field_errors.zip_code}
                    message={field_errors.zip_code}
                />
            </div>
            <div>
                <Select
                    placeholder='Select Category'
                    onChange={(e) => onHandleChange('category', e)}
                    options={categories}
                    value={category}
                    width='w-full'
                    error={!!field_errors.category}
                    message={field_errors.category}
                />
                <TextInput
                    placeholder="Business Phone number"
                    width="w-full"
                    value={business_phone_number}
                    onChange={(e) => onHandleChange('business_phone_number', e.target.value)}
                    error={!!field_errors.business_phone_number}
                    message={field_errors.business_phone_number}
                />
            </div>
            <div className="flex space-x-6">
                <div className="flex sm:h-20 sm:w-20 md:h-20 md:w-20 max-sm:h-20 max-sm:w-20 lg:h-28 lg:w-28 border-2 border-dashed border-gray-400 rounded-full bg-grey1">
                    <img src={img_url !== '' ? img_url : AddIcon} className={classNames(img_url !== '' ? 'object-cover w-full h-full rounded-full' : 'h-4 w-4 m-auto align-middle')} />
                </div>
                <div>
                    <div className="pt-2">
                        <span className="text-base font-montserrat font-bold">Add Your Business Logo (Optional)</span><br />
                        <span className="font-sans text-sm italic">*Accepted files jpg, png and must be 512x512</span>
                        <div className="mt-2">
                            <Button text="Upload" bg_color="bg-sky-blue" width="w-md" onClick={handleClick} />
                            <input type='file' accept="image/png, image/svg, image/jpeg" ref={hiddenFileInput} style={{ visibility: 'hidden' }} onChange={(e) => onFileUpload('image_url', e)} />
                            {
                                errorOnFile ? <span className='flex text-sm text-red font-sans mt-2'>Image must be atleast 512x512 and with 1:1 ratio</span> : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-8">
                {business_type === 'sign_up' ?
                    <Button text="START 14-DAY FREE TRIAL" width="w-full" /> :
                    <div className="lg:flex space-x-6">
                        <Button text="ADD BUSINESS" width="lg:w-1/2 sm:w-full md:w-full max-sm:w-full" onClick={handleSubmit} disabled={isLoadingCreateBusiness} />
                        <button className="h-12 leading-5 font-sans text-red text-sm lg:block sm:hidden md:hidden max-sm:hidden" onClick={props.onCancel}>Cancel</button>
                        <button className="h-12 leading-5 font-sans text-red text-sm lg:hidden w-full" onClick={props.onCancel}>Cancel</button>
                    </div>
                }

            </div>
        </div>
    );
}

export interface BusinessDetailsProps extends InputHTMLAttributes<HTMLInputElement> {
    business_type: string,
    onCancel?: () => void,
    onHandleChange?: (key: BusinessSignUpKeys, value: any) => void
    data?: Object | string | boolean
    onFileUpload?: (key: BusinessSignUpKeys, e: ChangeEvent<HTMLInputElement>) => void,
    errorOnFile?: boolean,
    img_url?: string,
    category?: string,
    state?: string
    user_data?: AuthUser,
}

export default BusinessDetails;