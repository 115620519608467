import { Menu, Transition } from "@headlessui/react";
import { StarIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "../../../../components/Button";
import Select from "../../../../components/Select";
import MessageNotification from "../../../BusinessDetails/components/MessageNotification";

import business_img1 from '../../../../assets/images/samples/business_img1.png';
import price_tag from '../../../../assets/images/icons/price_tag.svg';
import percent from '../../../../assets/images/icons/percent.svg';
import NewOrders from "../../../../components/NewOrders";
import { PRODUCT_SERVICE, PROMO_CODE } from "../../../../constants/business";
import ProductCard from "../../../../components/ProductCard";
import BusinessItem from "../BusinessItem";
import { useListQuery } from "../../../../services/business";
import { useAppSelector } from "../../../../store/hooks";
import ReportItem from "../../../../components/ReportItem";

const Business = (props: any) => {
  const user = useAppSelector(store => store.auth.user);
  const { data, isLoading } = useListQuery({ user_id: user?.id });
  // const list = useAppSelector(store => store.business.list);
  const list = data?.data || [];

  return (
    <div className="container m-auto mt-10 rounded-sm bg-white py-10 px-11">
      <div className="flex justify-between items-center">
        <div className="flex">
          <h4>Reports</h4>
        </div>
        <div className="flex">
          <div className="pr-2">
            <Select
              placeholder='This month'
              onChange={() => {}}
              options={[
                {
                  label: 'option 1',
                  value: 1
                },
                {
                  label: 'option 2',
                  value: 2
                },
                {
                  label: 'option 3',
                  value: 3
                },
                {
                  label: 'option 4',
                  value: 4
                },
              ]}
              value={undefined}
            />
          </div>
        </div>
      </div>
      <div className="w-full pt-6 grid grid-flow-row grid-cols-5 gap-2">
        <ReportItem label="New Orders" value="29" />
        <ReportItem label="Total Sales" value="$292.77" />
        <ReportItem label="Customers" value="147" />
        <ReportItem label="Messages/Inquiries" value="56" />
        <ReportItem label="Profile Views" value="147" />
      </div>
      <div className="pt-12">
        <div className="flex">
          <h4>Businesses</h4>
        </div>
      </div>
      <div>
        { 
          !!isLoading &&
            <div>
              {/* loaders for business here */}
            </div>
        }
        {
          !isLoading && !!list.length && list.map((b, i) => <BusinessItem data={b} key={i} />)
        }
      </div>
    </div>
  );
}

export default Business;