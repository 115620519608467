import React, { ClassAttributes, InputHTMLAttributes } from 'react';
import { classNames } from '../../helpers/styles';

const TextInput = ({
  placeholder,
  error = false,
  message = '',
  width = 'w-md',
  left_icon_border = true,
  right_icon_border = true,
  className,
  containerClassName,
  rounded,
  number = false, 
  margin_bottom = 'mb-2',
  innerRef,
  ...props
}: TextInputProps) => {
  let errors = undefined;
  let type = 'text';
  let rounded_class = rounded || 'rounded-md';

  if (!!error) {
    if (typeof message === 'string') {
      errors = <span className='text-red text-sm font-sans'>{message}</span>;
    } else {
      errors = message.map((err, i) => <span key={i} className='text-red text-sm font-sans'>{err}</span>)
    }
  }

  if (!!number) {
    type = 'number';
  }

  return (
    <div className={classNames('flex flex-col justify-start items-start', width, margin_bottom)}>
      <div className={classNames('flex overflow-hidden w-full h-12 border border-light-grey hover:border-dark-grey mb-1', error ? 'border-red hover:border-red' : '', containerClassName, rounded_class)}>
        {(!!props.left_icon) &&
          <div className={classNames('p-2 flex justify-center items-center border-light-grey', left_icon_border ? 'border-r' : '', error ? 'border-red hover:border-red' : '')}>
            {!!props.left_icon && props.left_icon}
          </div>
        }
        {!!props.left_component && props.left_component}
        <input
          type="text"
          placeholder={placeholder}
          className={classNames('w-full h-full text-black text-sm px-3 focus:outline-none', className)}
          ref={innerRef}
          {...props}
        />
        {(!!props.right_icon) &&
          <div className={classNames('p-2 flex justify-center items-center border-light-grey', right_icon_border ? 'border-l' : '', error ? 'border-red hover:border-red' : '')}>
            {!!props.right_icon && props.right_icon}
          </div>
        }
        {!!props.right_component && props.right_component}
      </div>
      {errors}
    </div>
  );
}

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  // ... other input props
  left_component?: JSX.Element
  right_component?: JSX.Element
  left_icon?: string | JSX.Element
  right_icon?: string | JSX.Element
  left_icon_border?: boolean
  right_icon_border?: boolean
  error?: boolean
  message?: string | Array<string>
  width?: 'w-full' | 'w-3/4' | 'w-1/2' | 'w-1/4' | string // uses tailwind class for width
  className?: string
  containerClassName?: string
  rounded?: string
  number?: boolean
  margin_bottom?: string
  innerRef?: any
}

export default TextInput;

