import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import BusinessDetails from '../../components/BusinessDetails';
import Modal from '../../components/Modal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Desktop from './Desktop';
import { onSignUpFormChange } from "../../store/sign_up/slice";

import './styles.scss';

const Profile = () => {
  const user = useAppSelector(store => store.auth.user);
  const data = useAppSelector(store => store.signUp);
  const dispatch = useAppDispatch();
  const [show_add_business, setShowAddBusiness] = useState(false);
  const handleAddBusinessOnClick = () => {
    setShowAddBusiness(true);
  }

  return (
    <div id="Profile" className='flex justify-center h-full w-full m-auto pt-10'>
      <Desktop user_data={user} handleAddBusinessOnClick={handleAddBusinessOnClick}/>
      <Modal isOpen={show_add_business} contentLabel="Example Modal" className="modal-container">
        <div className='bg-white px-28 py-16 relative'>
          <button className='absolute top-6 right-6 w-6 h-6' onClick={() => setShowAddBusiness(false)}>
            <XMarkIcon />
          </button>
          <BusinessDetails business_type="" onCancel={() => setShowAddBusiness(false)} />
        </div>
      </Modal>
    </div>
  );
}

export default Profile;