import { StarIcon, XMarkIcon } from '@heroicons/react/20/solid';
import sample_profile from '../../../assets/images/samples/frog.jpeg';
import coffee_place from '../../../assets/images/samples/coffee_place.png';
import { useState } from 'react';
import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';

const ReviewItem = ({ replies, is_published = true }: { replies?: Array<any>, is_published?: boolean }) => {
  const [is_replying, setIsReplying] = useState(false);
  return (
    <>
    <div className="border rounded-md border-border-default p-5 flex flex-row">
      <div className='pr-5'>
        <div className='w-12 h-12 rounded-full overflow-hidden'>
          <img src={sample_profile} alt="profile" className='w-12 h-12 object-cover' />
        </div>
      </div>
      <div className='flex-1 flex-col'>
        <div className='flex justify-between'>
          <h6>Kath L.</h6>
          <div className='flex justify-start items-center'>
            <StarIcon width={14} height={14} color="#FFCA2C" />
            <StarIcon width={14} height={14} color="#FFCA2C" />
            <StarIcon width={14} height={14} color="#FFCA2C" />
            <StarIcon width={14} height={14} color="#FFCA2C" />
            <StarIcon width={14} height={14} color="#FFCA2C" />
          </div>
        </div>
        <div className='text-sm pt-3'>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </div>
        <div className="flex flex-row items-center pt-3">
          <div className='text-dark-grey text-sm'>May 01, 2022</div>
          <div className='bg-dark-grey w-2 h-2 rounded-full ml-2' />
          <button className='text-blue text-sm pl-2' onClick={() => setIsReplying(!is_replying)}>Reply</button>
        </div>
      </div>
      {
        !is_published && <div className='pl-10'>
          <Button text='PUBLISH' width='w-100' />
        </div>
      }
    </div>
      {is_replying && <ReplyInput onClose={() => setIsReplying(false)} />}
      {
        replies?.map(r => <ReplyItem />)
      }
    </>
  );
}

export const ReplyInput = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className='pl-5 relative'>
      <button onClick={onClose} className='absolute top-0 right-1'>
        <XMarkIcon width={24} height={24} className='absolute top-2 right-2 text-[#A6A6A6]' />
      </button>
      <div className="border rounded-md border-border-default p-5 flex flex-row border-l-4 border-l-blue bg-[#F1FAFF] flex">
        <div className='w-12 h-12 rounded-full overflow-hidden'>
          <img src={coffee_place} alt="profile" className='w-12 h-12 object-cover' />
        </div>
        <div className='w-full pl-5'>
          <h6>Coffee Place</h6>
          <div className='flex flex-row w-full pt-3'>
            <textarea
              className='rectangle-copy-6 w-full resize-none text-black text-sm focus:outline-none py-3 px-3 overflow-hidden'
              placeholder='Type Your Review'
            />
            <div className=' w-5' />
            <Button text='Reply' width='w-150' />
          </div>
        </div>
      </div>
    </div>
  );
}
export const ReplyItem = () => {
  return (
    <div className='pl-5 relative'>
      <div className="border rounded-md border-border-default p-5 flex flex-row border-l-4 border-l-blue bg-[#F1FAFF] flex">
        <div className='pr-5'>
          <div className='w-12 h-12 rounded-full overflow-hidden'>
            <img src={coffee_place} alt="profile" className='w-12 h-12 object-cover' />
          </div>
        </div>
        <div className='flex-1 flex-col'>
          <div className='flex justify-between'>
            <h6>Coffee Place</h6>
          </div>
          <div className='text-sm pt-3'>
            Thank You
          </div>
          <div className="flex flex-row items-center pt-3">
            <div className='text-dark-grey text-sm'>May 01, 2022</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewItem;