import React, { useEffect } from 'react';
import { Outlet, useNavigate } from "react-router-dom";

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import { useAppSelector } from '../../store/hooks';
import { useMeQuery } from '../../services/auth';
import { useOptionsQuery } from '../../services/categories';
import SplashScreen from '../../components/SplashScreen';

const App = () => {
  useOptionsQuery();
  const { categories } = useAppSelector(store => store.categories.options);
  const token = useAppSelector(store => store.auth.token);
  const { user } = useAppSelector(store => store.auth);
  const navigate = useNavigate();
  const { data, isLoading } = useMeQuery();
  useEffect(() => {
    if (!token) {
      navigate('/sign-in', { replace: true });
    }
  }, [token, navigate]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <>
      <Header list_categories={categories} user_data={user}/>
      <main className=' pt-24'><Outlet /></main>
      <Footer />
    </>
  );
}

export default App;