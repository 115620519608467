import React, { useEffect, useState } from 'react';
import product_img from '../../assets/images/samples/product_img.png';
import heart_white from '../../assets/images/icons/heart_white.svg';
import business_img from '../../assets/images/samples/business_img.png';

import Counter from '../../components/TextInput/Counter';
import Button from '../../components/Button';
import ProductCard from '../../components/ProductCard';
import { useProductDetailsQuery } from '../../services/business';
import { useParams } from 'react-router-dom';
import { PROMO_CODE, TYPE_PROMO_CODE } from '../../constants/business';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { add as addToCart, update as updateCart, remove, setNotify, NOTIFY } from '../../store/cart/slice';
import toast from 'react-hot-toast';

const Desktop = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { business_id, product_id } = params;
  const cart_item = useAppSelector(store => {
    let index = -1;
    const product = store.cart.list.filter((d, i) => {
      if (d.item.id === product_id)  index = i;

      return d.item.id === product_id;
    })[0];

    return {
      index,
      product,
    };
  });
  let cart_item_index = cart_item.index;
  const cart_product = cart_item.product;
  const [quantity, setQuantity] = useState(0);
  const { data: response, isLoading } = useProductDetailsQuery({ id: product_id || '', business_id: Number(business_id) });
  const { data: related } = useProductDetailsQuery({ id: product_id || '', business_id: Number(business_id) });
  const data = response?.data;

  useEffect(() => {
    if (!!cart_product) {
      setQuantity(cart_product.quantity);
    }

    window.scrollTo(0, 0);
  }, []);

  const handleAddToCartClick = () => {
    if (!data) return;

    dispatch(addToCart({
      quantity: quantity,
      item: data,
    }));
    // toast.success('Successfully added!')
    dispatch(setNotify({ notify: true, type: NOTIFY.ADD, message: "Added to cart" }));

  }

  const handleUpdateCartClick = () => {
    if (!data) return;
    if (cart_item_index < 0) return;

    dispatch(updateCart({
      quantity: quantity,
      index: cart_item_index,
    }));
    // toast.success('Successfully updated!')
    dispatch(setNotify({ notify: true, type: NOTIFY.UPDATE, message: "Item updated" }));
  }

  const handleRemoveCartItemClick = () => {
    if (!data) return;

    if (cart_item_index === -1) return;

    dispatch(setNotify({ notify: true, type: NOTIFY.REMOVE, message: "Item removed from cart" }));
  }

  return (
    <div className='container product-details-desktop h-full w-full px-5 md:px-0'>
      <div className='product-details grid grid-cols-10 gap-5 md:gap-16'>
        <div className='flex justify-center item-center relative col-span-10 sm:col-span-5 md:col-span-5  lg:col-span-4'>
          {
            data?.type === TYPE_PROMO_CODE ? 
              <PromoCodeView  description={data?.discount_description || ''} percentage={data?.discount_percentage || 0} bg_color={data?.color || ''} />
                :
              <ProductView image={data?.image} />
          }
        </div>
        <div className='flex flex-col items-start col-span-10 sm:col-span-5 md:col-span-5  lg:col-span-6'>
          <h2 className="text-h2">{data?.name}</h2>
          <div className='flex flex-row items-center pt-3 pb-5'>
            <img src={business_img} className="w-10 h-10 mr-3 rounded-full" />
            <span className='text-sm text-dark-blue'>{data?.business.name}</span>
          </div>
          <div className='text-sm bg-light-blue rounded-3xl px-5 py-2'>Validity : {data?.expiration_days} Days upon purchase</div>
          <div className='text-sm text-left pt-4 pb-5'>
            <p>
              {data?.full_description}
            </p>
          </div>
          <div className='flex flex-row items-center'>
            <div className='text-xl font-bold'>${data?.price}</div>
            <div className='border-gray border-l h-4 mx-5'/>
            <div className='text-xl text-red line-through'>${data?.original_price}</div>
          </div>
          <div className='flex flex-row w-full pt-5'>
            <div className='flex max-w-150'>
              <Counter min={0} value={quantity} onChangeValue={(val) => {
                if (val >= 0){
                  setQuantity(val);
                } else {
                  setQuantity(0);
                }
              }} />
            </div>
            <div className='flex flex-1 max-w-450 px-1 lg:pl-10 sm:pl-2'>
              {
                !cart_product ?
                  <Button label="ADD TO CART" disabled={quantity <= 0} width="w-full " onClick={handleAddToCartClick} />
                    :
                  (
                    quantity > 0 ? 
                      <Button label="UPDATE" width="w-full" onClick={handleUpdateCartClick} />
                        :
                        <Button outlined outline_color="red" width="w-full" label="REMOVE" onClick={handleRemoveCartItemClick} />
                  )
              }
              
            </div>
          </div>
          <div className='flex pt-16 text-sm text-gray-500'>
            SKU : {data?.sku}
          </div>
        </div>
      </div>


      {/* Related Products */}
      <div className='pt-16'>
        <h2 className="text-h2 text-start">Related Products</h2>
        <div className='mt-7 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 place-content-center place-items-center sm:place-items-center md:place-items-center lg:place-items-start'>
          <ProductCard>
            <div className='absolute top-3 right-4 hover:cursor-pointer'>
              <img src={heart_white} className='w-6 h-6 object-center' />
            </div>
          </ProductCard>
          <ProductCard>
            <div className='absolute top-3 right-4 hover:cursor-pointer'>
              <img src={heart_white} className='w-6 h-6 object-center' />
            </div>
          </ProductCard>
          <ProductCard>
            <div className='absolute top-3 right-4 hover:cursor-pointer'>
              <img src={heart_white} className='w-6 h-6 object-center' />
            </div>
          </ProductCard>
          <ProductCard>
            <div className='absolute top-3 right-4 hover:cursor-pointer'>
              <img src={heart_white} className='w-6 h-6 object-center' />
            </div>
          </ProductCard>
          <ProductCard>
            <div className='absolute top-3 right-4 hover:cursor-pointer'>
              <img src={heart_white} className='w-6 h-6 object-center' />
            </div>
          </ProductCard>
        </div>
      </div>

      {/* Other Products */}
      <div className='pt-16'>
        <h2 className="text-h2 text-start">Other Products</h2>
        <div className='mt-7 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 place-content-center place-items-center sm:place-items-center md:place-items-center lg:place-items-start'>
          <ProductCard>
            <div className='absolute top-3 right-4 hover:cursor-pointer'>
              <img src={heart_white} className='w-6 h-6 object-center' />
            </div>
          </ProductCard>
          <ProductCard>
            <div className='absolute top-3 right-4 hover:cursor-pointer'>
              <img src={heart_white} className='w-6 h-6 object-center' />
            </div>
          </ProductCard>
          <ProductCard>
            <div className='absolute top-3 right-4 hover:cursor-pointer'>
              <img src={heart_white} className='w-6 h-6 object-center' />
            </div>
          </ProductCard>
          <ProductCard>
            <div className='absolute top-3 right-4 hover:cursor-pointer'>
              <img src={heart_white} className='w-6 h-6 object-center' />
            </div>
          </ProductCard>
          <ProductCard>
            <div className='absolute top-3 right-4 hover:cursor-pointer'>
              <img src={heart_white} className='w-6 h-6 object-center' />
            </div>
          </ProductCard>
        </div>
      </div>
    </div>
  );
}

const ProductView = ({ image }: { image?: string }) => {
  return (
    <div className='w-full max-h-600 relative'>
      <img src={image || product_img} className='object-cover h-full'></img>
      <div className='absolute top-4 right-4 hover:cursor-pointer'>
        <img src={heart_white} className='w-8 h-8 object-center' />
      </div>
    </div>
  );
}
const PromoCodeView = (props: { description: string, percentage: number, bg_color: string }) => {
  return (
    <div className='w-full max-h-600 relative bg-red text-white flex flex-col items-center justify-center' style={{
      backgroundColor: props.bg_color,
    }}>
      <div><h1 className='text-[123px] leading-[6.5rem]'>{Math.round(props.percentage)}%</h1></div>
      <div><h1 className='text-[45px]'>{props.description}</h1></div>
    </div>
  );
}

export default Desktop;