import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import SignInImage from '../../assets/images/sign_in_image.png';
import ValuMenuIcon from '../../assets/images/valu_menu_sign_in_logo.svg';
import ArrowLeft from '../../assets/images/icons/chevron_left.svg';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import MobileLogo from '../../assets/images/valu_menu.svg';
import { useLazySignInWithGoogleQuery, useLoginMutation, useSignInWithGoogleQuery } from '../../services/auth';
import { LoginKeys, onLoginFormChange } from '../../store/login/slice';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';



const SignIn = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(store => store.auth.token);
  const errors = useAppSelector(store => store.login.errors);
  const email = useAppSelector(store => store.login.email);
  const password = useAppSelector(store => store.login.password);
  const navigate = useNavigate();
  /**
   * sample email and pass
   * john@valumenu.com
   * Passw0rd123!
  **/

  const [login, { isLoading, isSuccess }] = useLoginMutation();
  const [signInWithGoogle, { data } ] = useLazySignInWithGoogleQuery();

  const handleSignIn = async () => {
    await login({ email, password });
  }

  if (isSuccess) {
    console.log('isSuccess', Date.now());
  }

  const handleOnChange = (key: LoginKeys, value: any) => {
    dispatch(onLoginFormChange({ key, value }));
  }

  useEffect(() => {
    if (!!token) {
      navigate('/app/profile', { replace: true }); // redirect to profile temporarily since no landing page is created yet.
    }
  }, [navigate, token]);

  const handleSignInWithGoogle = async () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}api/login/google`
  }

  return (
    <div id="SignIn" className='w-screen h-full'>
      <div className='lg:flex h-full'>
        <div className='relative lg:block md:hidden sm:hidden max-sm:hidden'>
          <img src={SignInImage} alt="" className='h-full object-cover' style={{ width: '40rem' }}/>
          <div className='absolute top-8 left-10'>
            <img src={ValuMenuIcon} alt="" className=''/>
          </div>
        </div>
        <div className='w-full'>
          <div className='flex justify-between lg:mx-10 my-9 md:mx-20 sm:mx-10 max-sm:mx-5'>
            <div className='flex space-x-3 sm:w-1/3 md:w-1/3 max-sm:w-1/3'>
              <img src={ArrowLeft} alt="" className='w-2'/>
              <span className='font-sans text-base leading-6'>Back</span>
            </div>
            <div className='lg:hidden w-1/3 flex justify-center'>
              <img src={MobileLogo} alt="" className='h-7 w-16 lg:hidden'/>
            </div>
            <div className='lg:block lg:text-right sm:w-1/3 md:w-1/3 max-sm:w-1/3'>
              <Link to='/sign-up' className='font-sans text-base leading-6 text-blue lg:block md:hidden sm:hidden max-sm:hidden'>Sign up</Link>
            </div>
          </div>
          <div className='md-lg:mx-80 max-lg:mx-40 md:mx-20 sm:mx-10 max-sm:mx-5 sm:mt-16 md:mt-16 max-sm:mt-16'>
            <div>
              <span className='text-blue lg:text-h4 font-bold font-montserrat md:text-h5 sm:text-h5 max-sm:text-h5'>Sign in</span>
            </div>
            <div className='mt-5'>
              <TextInput
                placeholder='Email Address*'
                width='w-full'
                onChange={(e) => handleOnChange('email', e.target.value)}
                value={email}
                className='border border-gray-300 rounded-md'
                error={!!(errors?.email)}
                message={errors?.email}
              />
            </div>
            <div className='mt-1'>
              <TextInput
                type="password"
                placeholder='Password*'
                width='w-full'
                onChange={(e) => handleOnChange('password', e.target.value)}
                value={password}
                className='border border-gray-300 rounded-md'
                error={!!(errors?.password)}
                message={errors?.password}
              />
            </div>
            <div className='text-right mt-2 mb-4'>
              <span className='font-sans text-sm text-blue pr-1 leading-5'>Forgot Password?</span>
            </div>
            <div>
              <Button text='SIGN IN' width='w-full' onClick={handleSignIn} disabled={isLoading} />
            </div>
            <div className='flex mt-4 justify-between space-x-2'>
              <div className='w-full pt-3'>
                <div className='border border-gray-200'></div>
              </div>
              <div>
                <span className='font-sans text-sm'>Or</span>
              </div>
              <div className='w-full pt-3'>
                <div className='border border-gray-200'></div>
              </div>
            </div>
            <div className='mt-4'>
              <Button
                text='SIGN IN WITH GOOGLE'
                text_color='text-black'
                outlined={true}
                outline_color='gray-700'
                width='w-full'
                onClick={handleSignInWithGoogle}
              />
            </div>
            <div className='space-x-1 lg:mt-20 md:text-center sm:text-center max-sm:text-center lg:text-left sm:mt-40 md:mt-40 max-sm:mt-40'>
              <span className='text-base text-black font-sans'>Not a member yet?</span>
              <Link to='/sign-up' className='text-base text-blue font-sans'>Sign up here</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// type SignInProps = {

// };

export default SignIn;