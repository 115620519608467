import React, { useEffect, useRef, useState } from 'react';
import { logout } from '../../store/auth/slice';
import { useAppDispatch } from '../../store/hooks';
import TextInput from '../../components/TextInput';
import { classNames } from '../../helpers/styles';

import search_icon from '../../assets/images/search_gray.svg';
import home_bg from '../../assets/images/home_bg.jpeg';
import close_icon from '../../assets/images/icons/close.svg';
import shop from '../../assets/images/icons/shop.svg';
import sync_location from '../../assets/images/icons/sync_location.svg';
import discount_coupon from '../../assets/images/icons/discount_coupon.svg';
import Button from '../../components/Button';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link, useNavigate } from 'react-router-dom';
import SearchItem from '../SearchResult/SearchItem';
import Pagination2 from '../../components/Pagination/Pagination2';
import { useOptionsQuery } from '../../services/categories';

import BusinessTransparentPattern from '../../assets/images/business_transparent_pattern.png';
import Accessories from '../../assets/images/icons/categories/accessories.svg';
import ArtsAndDesign from '../../assets/images/icons/categories/arts_&_design.svg';
import Automotive from '../../assets/images/icons/categories/automotive.svg';
import CafesAndTreats from '../../assets/images/icons/categories/cafes_&_treats.svg';
import Cosmetics from '../../assets/images/icons/categories/cosmetics.svg';
import Dental from '../../assets/images/icons/categories/dental.svg';
import Electronics from '../../assets/images/icons/categories/electronics.svg';
import Entertainment from '../../assets/images/icons/categories/entertainment.svg';
import FitnessAndGym from '../../assets/images/icons/categories/fitness_&_gym.svg';
import FunAndLeisure from '../../assets/images/icons/categories/fun_&_leisure.svg';
import GiftsAndSurprises from '../../assets/images/icons/categories/gifts_&_surprises.svg';
import GroceriesAndMarkets from '../../assets/images/icons/categories/groceries_&_markets.svg';
import HomeService from '../../assets/images/icons/categories/home_service.svg';
import KidsAndToys from '../../assets/images/icons/categories/kids_&_Toys.svg';
import Medical from '../../assets/images/icons/categories/medical.svg';
import NightLife from '../../assets/images/icons/categories/night_life.svg';
import PersonalServices from '../../assets/images/icons/categories/personal_services.svg';
import Pets from '../../assets/images/icons/categories/pets.svg';
import Restaurants from '../../assets/images/icons/categories/restaurants.svg';
import Retail from '../../assets/images/icons/categories/retail.svg';
import Others from '../../assets/images/icons/categories/others.svg';
import BeautyAndWellness from '../../assets/images/icons/categories/beauty_&_wellness.svg';
import HotelsAndTravels from '../../assets/images/icons/categories/hotels_&_travels.svg';
import promote_business from '../../assets/images/promote_business.png';
import barbel from '../../assets/images/barbel.png';
import sandwich from '../../assets/images/sandwich.png';
import drinks from '../../assets/images/drinks.png';
import bed from '../../assets/images/bed.png';
import salon from '../../assets/images/salon.png';
import coffee from '../../assets/images/coffee.png';
import connect_with_customers from '../../assets/images/connect_with_customers.png';
import TabRadio from '../../components/Radio/TabRadio';

import './index.scss';

type cat_icons = {
    [key: string]: string;
};

const category_icons: cat_icons = {
    Accessories,
    ArtsAndDesign,
    Automotive,
    CafesAndTreats,
    Cosmetics,
    Dental,
    Electronics,
    Entertainment,
    FitnessAndGym,
    FunAndLeisure,
    GiftsAndSurprises,
    GroceriesAndMarkets,
    HomeService,
    KidsAndToys,
    Medical,
    NightLife,
    PersonalServices,
    Pets,
    Restaurants,
    Retail,
    Others,
    BeautyAndWellness,
    HotelsAndTravels
}

const SECTION_2_ITEMS = [
  {
    image: shop,
    title: 'Discover Local Businessses',
    desc: 'Discover new businessess',
    link: '#',
  },
  {
    image: sync_location,
    title: 'Over 40+ Cities Nationwide',
    desc: 'Browse by city',
    link: '#',
  },
  {
    image: discount_coupon,
    title: 'Popular Value Menus',
    desc: 'Check featured Value menus',
    link: '#',
  }
];

const Welcome = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data } = useOptionsQuery();
  const [radio_option, setRadioOption] = useState(1);
  const [search, setSearch] = useState('');

  const handleLogout = () => {
    dispatch(logout());
  }

  const categories = data?.data.categories || [];

  return (
    <div id="Welcome" className='w-full h-full'>
      <section className='searchbar relative w-full z-10 flex items-center justify-center h-450'>
        <img src={home_bg} className='h-450 w-full absolute top-0 left-0 right-0 bottom-0 object-cover z-0' />
          <div className={classNames("w-[80%] pl-3 max-w-[700px] rounded-md bg-white relative")}>
        <form onSubmit={(e) => {
          e.preventDefault();

          navigate(`/app/search-result?search=${search}`);
        }}>
          <TextInput
            placeholder="Business Name, Product Name"
            width="w-full mt-1"
            containerClassName="border-0"
            left_icon={<img src={search_icon} className="w-5 h-5 m-2" />}
            right_icon={<Button text='SEARCH' type='submit' width='w-100' />}
            left_icon_border={false}
            right_icon_border={false}
            className='text-md font-bold'
            margin_bottom="mb-0"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
          </div>
      </section>
      <section className='bg-light-grey items-center justify-center grid grid-flow-row grid-cols-3 px-40 py-5'>
        {
          SECTION_2_ITEMS.map(item => {
            return (
              <div className='max-w-[300px] flex items-center' key={item.title}>
                <div className='h-14 w-14 rounded-full bg-white overflow-hidden flex justify-center items-center'>
                  <img src={item.image} className='h-8 w-8 object-contain' />
                </div>
                <div className='pl-3'>
                  <div><h6>{item.title}</h6></div>
                  <div>
                    <Link to={item.link} className='flex items-center text-sm text-dark-blue'>
                      {item.desc}
                      <div className='flex items-center justify-center bg-dark-blue h-4 w-4 rounded-full ml-1'>
                        <ChevronRightIcon width={10} height={10} color='white' />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })
        }
      </section>
      <section className='container m-auto pt-10'>
        <div className='flex items-center'>
          <h4>Popular this week</h4>
          <Link to="#" className='flex items-center text-sm text-dark-blue ml-2'>
            See all
            <div className='flex items-center justify-center bg-dark-blue h-4 w-4 rounded-full ml-1'>
              <ChevronRightIcon width={10} height={10} color='white' />
            </div>
          </Link>
        </div>
        <div className='grid grid-flow-row grid-cols-4 gap-3 pt-5'>
          {/* <SearchItem data={{}} />
          <SearchItem data={{}} />
          <SearchItem data={{}} />
          <SearchItem data={{}} /> */}
        </div>
        <div className='w-full flex justify-center items-center pt-9'>
          <Pagination2 />
        </div>
      </section>
      <section className='w-full pt-10'>
        <div className='container m-auto flex items-center'>
          <h4>Browse by category</h4>
          <Link to="#" className='flex items-center text-sm text-dark-blue ml-2'>
            See all
            <div className='flex items-center justify-center bg-dark-blue h-4 w-4 rounded-full ml-1'>
              <ChevronRightIcon width={10} height={10} color='white' />
            </div>
          </Link>
        </div>
        <div className='max-w-[100vw] flex overflow-auto gap-2 pt-5'>
          {
            categories.map((item) => {
              return(
                <div key={item.id} className='flex flex-col items-center justify-center w-44 h-[159px] rounded-xl border py-8 px-16' style={{ backgroundColor: `${item.color}` }}>
                  <img src={category_icons[item.name.replaceAll(' ', '').replaceAll('&', 'And')]} alt="" className='h-12 w-12'/>
                  <div className='text-center pt-6'>
                    <span className='font-bold text-sm font-sans whitespace-nowrap'>{item.name}</span>
                  </div>
                </div>
              )
            })
        }
        </div>
      </section>
      <section className='container m-auto pt-10'>
        <div className='flex gap-3'>
          <div className='flex flex-col h-[600px] flex-1 justify-end px-12 pb-11 relative'>
            <img src={promote_business} className='absolute top-0 left-0 right-0 bottom-0 h-full object-cover' />
            <h2 className='text-white z-10'>Discover Local Businesses Near You!</h2>
            <div className='pt-3 z-10'>
              <Button text='JOIN' width='w-150' />
            </div>
          </div>
          <div className='flex flex-col h-[600px] flex-1 justify-end px-12 pb-11 relative'>
            <img src={promote_business} className='absolute top-0 left-0 right-0 bottom-0 h-full object-cover' />
            <h2 className='text-white z-10'>New Way To Promote Your Business</h2>
            <div className='pt-3 z-10'>
              <Button text='LEARN MORE' width='w-150' />
            </div>
          </div>
        </div>
      </section>
      <section className='container m-auto pt-10'>
        <div className='text-center w-full'>
          <div className='inline-block'>
            <TabRadio
              options={[
                {
                  label: "Featured",
                  value: 1,
                },
                {
                  label: "Near You",
                  value: 2
                },
                {
                  label: "Recommended",
                  value: 3
                },
              ]}
              value={radio_option}
              onChange={setRadioOption}
            />
          </div>
        </div>
        <div className='grid grid-flow-row grid-cols-4 gap-3 pt-5'>
          <div className='flex flex-col justify-end pl-7 pb-8 col-span-2 h-[370px] relative'>
            <img src={barbel} className='absolute h-full top-0 left-0 right-0 bottom-0 object-cover' />
            <h5 className='text-white z-10'>Business Name</h5>
            <div className='text-sm text-white z-10'>Elkhorn Rd, Las Vegas, Nevada</div>
          </div>
          <div className='flex flex-col justify-end pl-7 pb-8 col-span-1 h-[370px] relative'>
            <img src={sandwich} className='absolute h-full top-0 left-0 right-0 bottom-0 object-cover' />
            <h5 className='text-white z-10'>Business Name</h5>
            <div className='text-sm text-white z-10'>Elkhorn Rd, Las Vegas, Nevada</div>
          </div>
          <div className='flex flex-col justify-end pl-7 pb-8 col-span-1 h-[370px] relative'>
            <img src={drinks} className='absolute h-full top-0 left-0 right-0 bottom-0 object-cover' />
            <h5 className='text-white z-10'>Business Name</h5>
            <div className='text-sm text-white z-10'>Elkhorn Rd, Las Vegas, Nevada</div>
          </div>
        </div>
        <div className='grid grid-flow-row grid-cols-8 gap-3 pt-3'>
          <div className='flex flex-col justify-end pl-7 pb-8 col-span-2 h-[370px] relative'>
            <img src={bed} className='absolute h-full w-full top-0 left-0 right-0 bottom-0 object-cover' />
            <h5 className='text-white z-10'>Business Name</h5>
            <div className='text-sm text-white z-10'>Elkhorn Rd, Las Vegas, Nevada</div>
          </div>
          <div className='flex flex-col justify-end pl-7 pb-8 col-span-3 h-[370px] relative'>
            <img src={coffee} className='absolute h-full w-full top-0 left-0 right-0 bottom-0 object-cover' />
            <h5 className='text-white z-10'>Business Name</h5>
            <div className='text-sm text-white z-10'>Elkhorn Rd, Las Vegas, Nevada</div>
          </div>
          <div className='flex flex-col justify-end pl-7 pb-8 col-span-3 h-[370px] relative'>
            <img src={salon} className='absolute h-full w-full top-0 left-0 right-0 bottom-0 object-cover' />
            <h5 className='text-white z-10'>Business Name</h5>
            <div className='text-sm text-white z-10'>Elkhorn Rd, Las Vegas, Nevada</div>
          </div>
        </div>
      </section>
      <section className='container m-auto'>
        <div className='flex'>
          <div className='w-[55%] flex justify-end'>
            <div className='pr-60 pt-52'>
              <h1>Connect with your customers better.</h1>
              <div className='text-sm pb-5 pt-3'>
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              </div>
              <Button text='JOIN' width='w-150' />
            </div>
          </div>
          <div className='w-[45%] h-[985px] relative'>
            <div className='absolute top-0 left-0 right-0 bottom-0 w-[45vw] h-full bg-light-grey'>
              <img src={connect_with_customers} className='absolute -left-1/4 top-32' />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Welcome;