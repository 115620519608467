import {
  Channel,
  ChannelHeader,
  ChannelList,
  Chat,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";
import "./layout.css";
import { useClient } from "../../hooks/useClient";
import { useEffect } from "react";
import { useAppSelector } from "../../store/hooks";

//from getstream.io api
const apiKey = "kj2ut9rwg3bu";
//from backend server
const userToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiam9obiJ9.EtmbBlx4n3fGWFnWM6WKtr3Xf_AcU0bxp1KQJyH3ImU"; //john
//logged user
const userId = "john";

const filters = { type: "messaging", members: { $in: [userId] } };

const ChatDemo = () => {
  const { user } = useAppSelector(store => store.auth);

  //current user logged in
  const user_data = {
    id: user?.email,
    name: user?.name,
    image:
      // user?.image ||
      "https://getstream.io/random_png/?id=green-sound-8&name=green-sound-8",
  };

  //a  hook for connecting chat server
  const chatClient = useClient({
    apiKey: apiKey,
    userData: user_data,
    tokenOrProvider: userToken,
  });

  

  useEffect(() => {
    if(chatClient) {

    /**
     * Here you can trigger on send message this will create a new chat channel to a vendor
     * this is just for testing
     */
      const channel = chatClient.channel('messaging', 'john-and-starbucks1', {
        // add as many custom fields as you'd like
        image: 'https://upload.wikimedia.org/wikipedia/en/thumb/d/d3/Starbucks_Corporation_Logo_2011.svg/800px-Starbucks_Corporation_Logo_2011.svg.png',
        name: 'Starbucks',
        members: ['john','starbucks'],
      });

      channel.create();

    }

  },[chatClient]);



  if (!chatClient) {
    return <LoadingIndicator />;
  }



  return (
    <Chat client={chatClient} theme='str-chat__theme-light'>
      <ChannelList filters={filters} />
      <Channel >
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

export default ChatDemo;
