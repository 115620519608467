
import { useNavigate, useParams } from "react-router-dom";
import Select from "../../../components/Select";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import User from "../../../models/User";
import { generateImageByName } from "../../../helpers/utils";
import { useDeleteMutation, useUserListQuery } from "../../../services/user";
import AddUser from "../Modals/AddUser";
import { useState } from "react";
import DeleteModal from "../Modals/DeleteModal";
import Swal from "sweetalert2";

const AdminUsers = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [delete_modal, setDeleteModal] = useState(false);
  const [show_modal, setShowModal] = useState(false);
  const [modal_data, setModalData] = useState<any>({});
  const { data } = useUserListQuery({});
  const [deleteUser] = useDeleteMutation();
  const list = data?.data || [];

  const handleDelete = async (id: number) => {
    try {
      const xhr = await deleteUser(id);

      if ((xhr as any)?.error?.status >= 400) {
        throw (xhr as any);
      }

      if ((xhr as any)?.error?.status >= 400) {
        throw (xhr as any);
      }

      Swal.fire({
        title: 'Success!',
        text: `User Deleted Successfully!`,
        icon: 'success',
        confirmButtonText: 'Ok',
        didClose() {
          setDeleteModal(false);
          setModalData(undefined);
        },
      });
    } catch (e: any) {
      console.log('error ', e);

      Swal.fire({
        title: 'Error!',
        text: e?.error?.data?.message || 'An error occurred. Please try again.',
        icon: 'error',
        confirmButtonText: 'Ok',
        didClose() {
          setDeleteModal(false);
          setModalData(undefined);
        },
      });
    }
  }

  return (
    <div className="container m-auto rounded-sm py-10 px-11">
      <AddUser
        data={modal_data}
        is_open={show_modal}
        onRequestClose={() => {
          setShowModal(false);
          setModalData(undefined);
        }}
      />
      <DeleteModal
        data={modal_data}
        is_open={delete_modal}
        title="Are you sure you want to delete this user?"
        image={generateImageByName(modal_data?.name || '')}
        name={modal_data?.name || ''}
        desc={modal_data?.email || ''}
        onRequestClose={() => {
          setDeleteModal(false);
          setModalData(undefined);
        }}
        handleSubmit={(d: any) => handleDelete(d.id)}
      />
      <div className="flex justify-between items-center">
        <div className="flex">
          <h4>Users ({list.length || 0})</h4>
        </div>
        <div className="flex">
          <div className="pr-2">
            <Select
              placeholder='This month'
              onChange={() => { }}
              options={[
                {
                  label: 'option 1',
                  value: 1
                },
                {
                  label: 'option 2',
                  value: 2
                },
                {
                  label: 'option 3',
                  value: 3
                },
                {
                  label: 'option 4',
                  value: 4
                },
              ]}
              value={undefined}
            />
          </div>
          <div>
            <Button width="w-150" label="+ ADD USER" onClick={() => setShowModal(true)} />
          </div>
        </div>
      </div>
      <div className="flex pt-4">
        <div className="grid grid-cols-7 w-full gap-1 items-center border-t border-border-default pt-3 pb-5 font-bold text-xs text-dark-grey px-5">
          <div className="col-span-2">
            FULL NAME
          </div>
          <div className="col-span-1">
            USERNAME
          </div>
          <div className="col-span-2">
            EMAIL ADDRESS
          </div>
          <div className="col-span-1">
            BUSINESS
          </div>
          <div className="col-span-1">
            ACTION
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3 pb-5">
        {
          list.map((user) => <Item
            data={user}
            onClickEdit={() => {
              setModalData(user);
              setShowModal(true);
            }}
            onClickDelete={() => {
              setModalData(user);
              setDeleteModal(true);
            }}
          />)
        }
      </div>
    </div>
  );
}
const Item = (props: { data: User, onClickEdit: () => void, onClickDelete: () => void }) => {

  return (
    <div className="grid grid-cols-7 w-full gap-1 items-center border rounded-md border-border-default p-5">
      <div className="col-span-2 text-sm">
        <div className="flex flex-row items-center justify-start">
          <img src={generateImageByName(props.data.name)} className="w-10 h-10 object-cover rounded-full mr-5" />
          {/* <img src={ProfilePic} className="w-10 h-10 object-cover rounded-full mr-5" /> */}
          {props.data.name}
        </div>
      </div>
      <div className="col-span-1 text-sm">
        {props.data.username}
      </div>
      <div className="col-span-2 text-sm">
        {props.data.email}
      </div>
      <div className="col-span-1 text-sm">
        {0}
      </div>
      <div className="col-span-1 text-xs">
        <div className="flex flex-row">
          {/* <Link to="#" className="pr-2 text-blue">View Details</Link> */}
          <Link to="#" onClick={props.onClickEdit} className="pr-2 text-blue">Edit</Link>
          <Link to="#" onClick={props.onClickDelete} className="pr-2 text-red">Delete</Link>
        </div>
      </div>

    </div>
  );
}

export default AdminUsers;