import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Option } from "./types";

export interface CategoriesState {
    options: {
        categories: [{
            color:string,
            id:number,
            is_featured:boolean,
            logo:string,
            name:string
        }]
    }
}

const initialState: CategoriesState = {
    options: {
        categories: [{
            color:'',
            id:0,
            is_featured:false,
            logo:'',
            name:''
        }]
    }
}

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        saveOption: (state, action: PayloadAction<Option>) => {
            state.options = action.payload
        },
    }
})

export const { saveOption } = categoriesSlice.actions;

export default categoriesSlice;