import { InputHTMLAttributes, useId, useState } from 'react'
import { classNames } from '../../helpers/styles';
import './styles.css';

export default function Checkbox({
  label,
  error = false,
  ...props
}: CheckboxProps) {

  return (
    <label className="input-checkbox container text-sm font-sans"><span>{label}</span>
      <input type="checkbox" {...props} />
      <span className={classNames("checkbox checkmark border dark-grey", !!error ? 'border-red' : '')}></span>
    </label>
  )
}

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  // ... other input props
  label?: string
  error?: boolean
}