import React from 'react';
import massage from '../../assets/images/samples/massage.png';
import arrow_left from '../../assets/images/icons/arrow_left.svg';
import check_icon from '../../assets/images/icons/checkmark.svg';
import paypal from '../../assets/images/icons/paypal.png';
import Button from '../../components/Button';
import { OrderStatusChip } from '../../components/Chip';
import { ORDER_STATUS } from '../../constants/purchase_history';
import TextInput from '../../components/TextInput';
import { Link, useNavigate } from 'react-router-dom';

const OrderDetails = () => {
  const navigate = useNavigate();
  const handleBackOnClick = () => {
    navigate(-1)
  }
  return (
    <div className='w-full'>
      <div className='container m-auto flex flex-col py-10'>
        <div className='flex'>
          <button className='p-2 w-12 h-10 flex justify-center items-center rounded-md border border-hairline' onClick={handleBackOnClick}>
            <img src={arrow_left} alt="back" />
          </button>
          <div className='px-3'>
            <h5>#VM0000005</h5>
            <div className='text-sm mt-2'>June 10, 2022 8:00 PM</div>
          </div>
          <OrderStatusChip status={ORDER_STATUS.PARTIALLY_FULFILLED} />
        </div>
        <div className='grid grid-flow-row grid-cols-4 gap-5 pt-5'>
          <div className='col-span-3'>
            <div className=' flex-1 rounded-md border border-hairline px-5 py-3'>
              <CartItem status={ORDER_STATUS.UNFULFILLED} />
              <CartItem status={ORDER_STATUS.UNFULFILLED} />
              <CartItem status={ORDER_STATUS.UNFULFILLED} />
              <CartItem status={ORDER_STATUS.FULFILLED} />
              <CartItem status={ORDER_STATUS.FULFILLED} />
            </div>

            <div className='flex flex-col px-5 mt-3 rounded-md py-6 border border-grey3'>
              <h6>Payment details</h6>
              <div className='pt-5 flex justify-between items-center'>
                <span className='text-sm'>Full body Massage</span>
                <span className='text-sm'>$27.00</span>
              </div>
              <div className='pt-5 flex justify-between items-center'>
                <span className='text-sm'>Full body Massage</span>
                <span className='text-sm'>$27.00</span>
              </div>
              <div className='pt-5 flex justify-between items-center'>
                <span className='text-sm'>Foot Massage</span>
                <span className='text-sm'>$27.00</span>
              </div>
              <div className='pt-5 flex justify-between items-center'>
                <span className='text-sm'>Subtotal</span>
                <span className='text-sm'>$27.00</span>
              </div>
              <div className='py-5 flex justify-between items-center'>
                <span className='text-sm'>Discount</span>
                <span className='text-sm'>$27.00</span>
              </div>
              <div className='pt-5 flex justify-between items-center font-bold border-t border-hairline'>
                <span className='text-sm'>Total</span>
                <span className='text-sm'>$27.00</span>
              </div>
            </div>
          </div>

          <div className='col-span-1'>
            <div className='flex flex-col px-5 py-6 rounded-md border border-grey3'>
              <h6>Customer details</h6>
              <span className='text-sm pt-5'>Michelle Johnson</span>
              <Link className='text-sm pt-2 text-dark-blue' to="#">michellej@gmail.com</Link>
            </div>
            <div className='flex flex-col px-5 mt-3 rounded-md py-6 border border-grey3'>
              <h6>Paid Via</h6>
              <div className='pt-5 flex justify-between items-center'>
                <span className='text-sm'>Paypal</span>
                <img src={paypal} className='h-3' alt="Paypal" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const CartItem = (props: { status: string }) => {
  return (
    <div className='flex pr-5 relative py-5 last:border-0 border-b border-grey3'>
      <img src={massage} className='w-20 h-20' alt="Cart Item" />
      <div className='flex flex-1 flex-col pl-4'>
        <div className='flex'>
          <span className="text-sm product-title text-span text-start font-bold pt-1 overflow-hidden text-ellipsis pr-3">Full Body Massage</span>
          <div className='flex bg-green-1 px-2 py-1 pr-4 rounded-2xl w-min h-min'>
            <div className='w-5 h-5 bg-green-2 rounded-full flex justify-center items-center'>
              <img src={check_icon} className='w-3 h-3' alt="Fulfilled" />
            </div>
            <span className='text-sm font-bold pl-2'>Fulfilled</span>
          </div>
        </div>
        <div className='text-sm text-left'>
          <p>Beauty Spa</p>
        </div>
        <div className='flex flex-row items-center'>
          <div className='text-sm font-bold'>${10}</div>
          <div className='border-gray border-l h-4 mx-3' />
          <div className='flex-1 text-sm text-grey2'>20% OFF</div>
        </div>
      </div>
      {
        props.status === ORDER_STATUS.FULFILLED ?
          (
            <div className='flex flex-col gap-1'>
              <span className='text-sm'>*VMEHHDA1345444</span>
              <span className='text-xs text-dark-grey'>July 10, 2022 8:00 AM</span>
            </div>
          ) :
          (
            <div className='flex flex-row gap-3 justify-end'>
              <TextInput placeholder='Enter code' />
              <Button text='FULFILL' width='w-100' />
            </div>
          )
      }
    </div>
  );
}

export default OrderDetails;