import React from "react";
import {
  createBrowserRouter,
} from "react-router-dom";
import Main from "../screens/Dashboard";
import ErrorPage from "../screens/ErrorPage";
import Welcome from "../screens/Welcome";
import SignIn from "../screens/SignIn";
import SignUp from "../screens/SignUp";
import AddProdPromo from "../screens/AddProdPromo";
import ProductDetails from "../screens/ProductDetails";
import StyleGuide from "../screens/StyleGuide";
import BusinessDetails from "../screens/BusinessDetails";
import ForBusiness from "../screens/ForBusiness";
import SignUpBusinessDetails from "../screens/SignUp/SignUpBusinessDetails";
import Profile from "../screens/Profile";
import Categories from "../screens/Categories";
import V2BusinessDetails from "../screens/BusinessDetailsV2";
import Claim from "../screens/Claim";
import Cart from "../screens/Cart";
import Protected from "./Protected";
import Checkout from "../screens/Checkout";
import OrderConfirmed from "../screens/OrderConfirmed";
import OrderDetails from "../screens/OrderDetails";
import ChatDemo from "../screens/ChatDemo";
import SearchResult from "../screens/SearchResult";

// admin
import SignInAdmin from "../screens/SignInAdmin";
import AdminDashboard from "../screens/AdminDashboard";
import AdminOverview from "../screens/AdminDashboard/AdminTabs/AdminOverview";
import AdminUsers from "../screens/AdminDashboard/AdminTabs/AdminUsers";
import AdminBusinesses from "../screens/AdminDashboard/AdminTabs/AdminBusinesses";
import AdminCategories from "../screens/AdminDashboard/AdminTabs/AdminCategories";

export const router_paths = {
  INDEX: "/",
  APP: "/app",
  ADD_PRODUCT: "/app/business/:business_id/new",
  BUSINESS_DETAILS_V2: "/app/business-details/:business_id",
  BUSINESS: "/app/business",
  BUSINESS_DETAILS: "/app/business/:business_id",
  PRODUCT_DETAILS: "/app/business/:business_id/product/:product_id",
  BUSINESS_DETAILS_TAB: "/app/business/:business_id/:tab",
  PROFILE_TAB: "/app/profile/:tab",
  PROFILE: "/app/profile",
  SETTINGS: "/app/profile/settings",
  CATEGORIES: "/app/categories",
  FOR_BUSINESS: "/app/for-business",
  CLAIM: "/app/claim/:id",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  STYLE_GUIDE: "/style-guide",
  SIGN_UP_BUSINESS: "/sign-up-business-details",
  CART: "/app/cart",
  CHECKOUT: "/app/checkout",
  ORDER_CONFIRMED: "/order/success",
  ORDER_DETAILS: "/app/order-details",
  CHAT_DEMO: "/app/chat-demo",
  SEARCH_RESULT: "/app/search-result",

  // -------------------ADMIN----------------------- //

  ADMIN: "/admin",
  ADMIN_LOGIN: "/admin/sign-in",
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN_USERS: "/admin/users",
  ADMIN_TRANSACTIONS: "/admin/transactions",
  ADMIN_BUSINESSES: "/admin/businesses",
  ADMIN_CATEGORIES: "/admin/categories",

  ADMIN_BUSINESS_DETAILS_V2: "/admin/businesses/business-details/:business_id",
};

const router = createBrowserRouter([
  {
    path: router_paths.INDEX,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <SignIn />,
      },
      {
        path: router_paths.APP,
        element: <Main />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Welcome />,
          },
          {
            path: router_paths.PRODUCT_DETAILS,
            element: <Protected><ProductDetails /></Protected>,
          },
          {
            path: router_paths.ADD_PRODUCT,
            element: <Protected><AddProdPromo /></Protected>,
          },
          {
            path: router_paths.BUSINESS_DETAILS,
            element: <Protected><BusinessDetails /></Protected>,
          },
          {
            path: router_paths.BUSINESS_DETAILS_TAB,
            element: <Protected><BusinessDetails /></Protected>,
          },
          {
            path: router_paths.BUSINESS_DETAILS_V2,
            element: <Protected><V2BusinessDetails /></Protected>,
          },
          {
            path: router_paths.PROFILE_TAB,
            element: <Protected><Profile /></Protected>,
          },
          {
            path: router_paths.PROFILE,
            element: <Protected><Profile /></Protected>,
          },
          {
            path: router_paths.CATEGORIES,
            element: <Protected><Categories /></Protected>,
          },
          {
            path: router_paths.FOR_BUSINESS,
            element: <Protected><ForBusiness /></Protected>,
          },
          {
            path: router_paths.CLAIM,
            element: <Protected><Claim /></Protected>,
          },
          {
            path: router_paths.CART,
            element: <Cart />
          },
          {
            path: router_paths.ORDER_DETAILS,
            element: <Protected><OrderDetails /></Protected>
          },
          {
            path: router_paths.CHAT_DEMO,
            element: <Protected><ChatDemo /></Protected>
          },
          {
            path: router_paths.SEARCH_RESULT,
            element: <Protected><SearchResult /></Protected>
          },
        ]
      },
      {
        path: router_paths.SIGN_IN,
        element: <SignIn />,
        errorElement: <ErrorPage />,
      },
      {
        path: router_paths.SIGN_UP,
        element: <SignUp />,
        errorElement: <ErrorPage />
      },
      {
        path: router_paths.STYLE_GUIDE,
        element: <StyleGuide />,
        errorElement: <ErrorPage />
      },
      {
        path: router_paths.SIGN_UP_BUSINESS,
        element: <SignUpBusinessDetails />
      },
      {
        path: router_paths.CHECKOUT,
        element: <Protected><Checkout /></Protected>
      },
      {
        path: router_paths.ORDER_CONFIRMED,
        element: <Protected><OrderConfirmed /></Protected>
      },
    ],
  },
  {
    path: router_paths.ADMIN,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <SignInAdmin />,
      },
      {
        path: router_paths.ADMIN_LOGIN,
        element: <SignInAdmin />,
      },
      {
        path: router_paths.ADMIN,
        element: <AdminDashboard />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: router_paths.ADMIN_DASHBOARD,
            element: <AdminOverview />,
          },
          {
            path: router_paths.ADMIN_USERS,
            element: <AdminUsers />,
          },
          {
            path: router_paths.ADMIN_BUSINESSES,
            element: <AdminBusinesses />,
          },
          {
            path: router_paths.ADMIN_CATEGORIES,
            element: <AdminCategories />,
          },
          {
            path: router_paths.ADMIN_BUSINESS_DETAILS_V2,
            element: <V2BusinessDetails />,
          },
        ],
      }
    ],
  }
]);

export default router;