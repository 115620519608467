import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import logo_white from '../../assets/images/valu_menu_sign_in_logo.svg';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import { useLazySignInWithGoogleQuery, useLoginMutation, useSignInWithGoogleQuery } from '../../services/auth';
import { LoginKeys, onLoginFormChange } from '../../store/login/slice';
import { useNavigate } from 'react-router-dom';


const SignInAdmin = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(store => store.auth.token);
  const errors = useAppSelector(store => store.login.errors);
  const email = useAppSelector(store => store.login.email);
  const password = useAppSelector(store => store.login.password);
  const navigate = useNavigate();
  /**
   * sample email and pass
   * john@valumenu.com
   * Passw0rd123!
  **/

  const [login, { isLoading, isSuccess }] = useLoginMutation();
  const [signInWithGoogle, { data }] = useLazySignInWithGoogleQuery();

  const handleSignIn = async (e: any) => {
    e.preventDefault();

    await login({ email, password, is_admin: true });
  }

  if (isSuccess) {
    console.log('isSuccess', Date.now());
  }

  const handleOnChange = (key: LoginKeys, value: any) => {
    dispatch(onLoginFormChange({ key, value }));
  }

  useEffect(() => {
    if (!!token) {
      navigate('/admin/dashboard', { replace: true }); // redirect to profile temporarily since no landing page is created yet.
    }
  }, [navigate, token]);

  const handleSignInWithGoogle = async () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}api/login/google`
  }

  return (
    <div id="SignInAdmin" className='bg-dark-blue flex flex-col justify-center h-full w-full m-auto pt-10'>
      <div className='flex flex-col justify-center items-center'>
        <div className='h-10 w-20'>
          <img src={logo_white} className='h-full w-full' />
        </div>
        <div className='mt-10 backdrop-blur-sm bg-white/10 w-500 p-10'>
          <form onSubmit={handleSignIn}>
            <div className='w-full'>
              <div className='text-center'>
                <span className='text-white text-center lg:text-h4 font-bold font-montserrat md:text-h5 sm:text-h5 max-sm:text-h5'>Admin Portal</span>
              </div>
              <div className='mt-5'>
                <TextInput
                  placeholder='Email Address*'
                  width='w-full'
                  onChange={(e) => handleOnChange('email', e.target.value)}
                  value={email}
                  className='border border-gray-300 rounded-md'
                  error={!!(errors?.email)}
                  message={errors?.email}
                />
              </div>
              <div className='mt-1'>
                <TextInput
                  type="password"
                  placeholder='Password*'
                  width='w-full'
                  onChange={(e) => handleOnChange('password', e.target.value)}
                  value={password}
                  className='border border-gray-300 rounded-md'
                  error={!!(errors?.password)}
                  message={errors?.password}
                />
              </div>
              <div>
                <Button type='submit' text='SIGN IN' width='w-full' bg_color='bg-green' />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='text-center text-white p-10'>
        Copyright © 2021 Valu Menu. All Rights Reserved
      </div>
    </div>
  );
}

export default SignInAdmin;