
import React from 'react';

import Desktop from './Desktop';


const ProductDetails = () => {
  return (
    <div id="ProductDetails" className='flex justify-center items-center text-center h-full w-full m-auto pt-10 pb-32 '>
      <Desktop />
    </div>
  );
}

export default ProductDetails;