import React, { useEffect } from 'react';
import massage from '../../assets/images/samples/massage.png';
import visa from '../../assets/images/icons/visa.png';
import american_express from '../../assets/images/icons/american_express.png';
import mastercard from '../../assets/images/icons/mastercard.png';
import paypal from '../../assets/images/icons/paypal.png';
import logo from '../../assets/images/valu_menu.svg';
import Button from '../../components/Button';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import TextInput from '../../components/TextInput';
import Checkbox from '../../components/Checkbox';
import Radio from '../../components/Radio';
import Select from '../../components/Select';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useDispatch } from 'react-redux';
import { clear } from '../../store/cart/slice';
import { useViewOrderQuery } from '../../services/cart';

const OrderConfirmed = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const id = new URLSearchParams(location.search).get('id');
  const token = useAppSelector(store => store.auth.token);
  const year_options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(x => ({ value: x + 2023, label: x + 2023, }));
  const { data } = useViewOrderQuery(id || '01HSP118W1YTPCF52XV1TCNRZX');
  const order_data = data?.data || {};
  const customer = order_data?.customer || {};
  const items: Array<any> = order_data?.items || [];


  const amount_total = order_data?.amount_total || 0;
  const amount_subtotal = order_data?.amount_subtotal || 0;
  const has_discount = (amount_total - amount_subtotal) > 0;
  const discount = ((1 - (amount_subtotal / amount_total)) * 100).toFixed();
  const amount_discount = (+(order_data?.amount_discount || 0)).toFixed();

  useEffect(() => {
    dispatch(clear());
  }, []);

  return (
    <div className='w-full container m-auto'>
      <div className='grid grid-flow-row grid-cols-2 h-full w-full'>
        <div className='col-span-1 pr-10 flex justify-end pb-20'>
          <div className='max-w-  [600px] w-full mt-[5%]'>
            <div className='flex justify-center'>
              <img
                className="h-20 w-20 lg:m-0 m-auto self-center"
                src={order_data?.qr || ''}
                alt=""
              />
            </div>
            <div className='pt-10'>
              <span className='text-md'>Order #{order_data?.tracking}</span>
              <h4>Thank you {order_data?.customer?.name || ''}!</h4>
              <div className='flex flex-col justify-center items-center mt-8 py-8 px-5 border border-hairline'>
                <div className=' w-12 h-12 rounded-full bg-green flex justify-center items-center'>
                  <CheckIcon width={32} height={32} color='white' />
                </div>
                <h6>Your Order is Confirmed!</h6>
                <span>
                  <span>{`Please proceed to your `}</span>
                  <Link to="/app" className='text-blue'>dashboard</Link>
                  <span>{` to claim your order.`}</span>
                </span>
              </div>
              <div className='flex flex-col justify-center mt-4 py-8 px-5 border border-hairline'>
                <h6>Customer information</h6>
                <span className='text-sm font-bold pt-2'>Contact information</span>
                <span className='text-sm pt-2'>{customer?.name}</span>
                <span className='text-sm pt-2'>{customer?.email}</span>
                {/* <div className='pt-5'>
                  <span className='text-sm font-bold'>Payment method</span>
                  <div className='flex flex-row pt-3'>
                    <div>
                      <img src={mastercard} width={40} />
                    </div>
                    <div className='flex flex-col pl-3'>
                      <span className='text-sm'>Master Card</span>
                      <span className='text-sm'>**** 7811</span>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className='flex justify-between pt-8'>
                <div className='flex items-center'>
                  <span>Need our help?</span>
                  <Link to="#" className='pl-2 text-blue'>Contact us</Link>
                </div>
                <Button text='CONTINUE SHOPPING' onClick={() => navigate('/app')} />
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-1 border-l flex flex-col pl-10 border-grey bg-pale-blue w-full'>
          <div className='max-w-[600px] mt-[5%]'>
            <div>
              {/* <h4>Order Summary</h4> */}
              <div className='flex flex-col gap-3 pb-8 border-b border-hairline1'>
                {
                  items.map(item => <CartItem data={item} />)
                }
              </div>
              <div>
                <div className="py-7 border-b border-hairline1">
                  <div className='flex w-full justify-between'>
                    <span className='text-sm'>Subtotal</span>
                    <span className='text-sm'>${order_data?.amount_subtotal || 0}</span>
                  </div>
                  {
                    !!has_discount && <div className='flex w-full justify-between pt-3'>
                      <span className='text-sm'>Discount</span>
                      <span className='text-sm text-red'>-${amount_discount} ({discount}% off )</span>
                    </div>
                  }
                </div>
                <div className="py-7">
                  <div className='flex w-full justify-between'>
                    <span className='text-sm font-bold'>Total</span>
                    <h5>${order_data?.amount_total}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



const CartItem = (props: any) => {
  const data = props?.data || {};
  const amount_total = data?.amount_total || 0;
  const amount_subtotal = data?.amount_subtotal || 0;
  const has_discount = (amount_total - amount_subtotal) > 0;
  const discount = ((1 - (amount_subtotal / amount_total)) * 100).toFixed();
  return (
    <div className='flex pr-10 relative max-w-450'>
      <img src={massage} className='w-20 h-20' />
      <div className='pl-4'>
        <span className="text-sm product-title text-span text-start font-bold pt-1 overflow-hidden text-ellipsis">Full Body Massage</span>
        <div className='text-sm text-left'>
          <p>Beauty Spa</p>
        </div>
        <div className='flex flex-row items-center'>
          <div className='text-sm'>x{data?.quantity}</div>
          <div className='border-gray border-l h-4 mx-3' />
          {has_discount && <div className='flex-1 text-sm text-grey2'>{discount}% OFF</div>}
        </div>
      </div>
      <div className='absolute top-0 right-0 text-sm font-bold'>
        ${data?.amount_total || 0}
      </div>
    </div>
  );
}

export default OrderConfirmed;