
import { useNavigate, useParams } from "react-router-dom";
import { StarIcon } from "@heroicons/react/20/solid";
import ReportItem from "../../../components/ReportItem";
import Select from "../../../components/Select";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import User from "../../../models/User";
// import ProfilePic from '../../../assets/images/profile_pic.png';
import ProfilePic from '../../../assets/images/valu_menu.svg';
import { formatNameForImg, generateImageByName } from "../../../helpers/utils";
import { Business } from "../../../store/business/types";
import Chip from "../../../components/Chip";
import BusinessStatusChip from "../../../components/Chip/BusinessStatus";
import { BUSINESS_STATUS } from "../../../constants/business";
import { useOptionsQuery } from "../../../services/categories";
import { getCagetory } from "../../../helpers/product";
import { Category } from "../../../models/Options";
import { useBusinessDeleteMutation, useListQuery } from "../../../services/business";
import AddBusiness from "../Modals/AddBusiness";
import { useEffect, useState } from "react";
import DeleteModal from "../Modals/DeleteModal";
import Swal from "sweetalert2";

const AdminBusinesses = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [delete_modal, setDeleteModal] = useState(false);
  const [show_modal, setShowModal] = useState(false);
  const [modal_data, setModalData] = useState<any>({});
  const { data, isLoading } = useListQuery({});
  const { data: options_data } = useOptionsQuery();
  const [deleteBusiness] = useBusinessDeleteMutation();
  const list = data?.data || [];

  const handleDelete = async (id: number) => {
    try {
      const xhr = await deleteBusiness(id);

      if ((xhr as any)?.error?.status >= 400) {
        throw (xhr as any);
      }

      if ((xhr as any)?.error?.status >= 400) {
        throw (xhr as any);
      }

      Swal.fire({
        title: 'Success!',
        text: `Business Deleted Successfully!`,
        icon: 'success',
        confirmButtonText: 'Ok',
        didClose() {
          setDeleteModal(false);
          setModalData(undefined);
        },
      });
    } catch (e: any) {
      console.log('error ', e);

      Swal.fire({
        title: 'Error!',
        text: e?.error?.data?.message || 'An error occurred. Please try again.',
        icon: 'error',
        confirmButtonText: 'Ok',
        didClose() {
          setDeleteModal(false);
          setModalData(undefined);
        },
      });
    }
  }

  return (
    <div className="container m-auto rounded-sm py-10 px-11">
      <AddBusiness
        data={modal_data}
        is_open={show_modal}
        onRequestClose={() => setShowModal(false)}
      />
      <DeleteModal
        data={modal_data}
        is_open={delete_modal}
        header={modal_data?.name || ''}
        title="Are you sure you want to delete this business?"
        image={modal_data?.logo || generateImageByName(modal_data?.name || '')}
        name={modal_data?.name || ''}
        desc={!!modal_data?.owner ? `By ${modal_data?.owner?.name || ''}` : ''}
        onRequestClose={() => {
          setDeleteModal(false);
          setModalData(undefined);
        }}
        handleSubmit={(d: any) => handleDelete(d.id)}
      />
      <div className="flex justify-between items-center">
        <div className="flex">
          <h4>Businesses ({list.length})</h4>
        </div>
        <div className="flex">
          <div className="pr-2">
            <Select
              placeholder='This month'
              onChange={() => { }}
              options={[
                {
                  label: 'option 1',
                  value: 1
                },
                {
                  label: 'option 2',
                  value: 2
                },
                {
                  label: 'option 3',
                  value: 3
                },
                {
                  label: 'option 4',
                  value: 4
                },
              ]}
              value={undefined}
            />
          </div>
          <div>
            <Button width="w-150" label="+ ADD BUSINESS" onClick={() => setShowModal(true)} />
          </div>
        </div>
      </div>
      <div className="flex pt-4">
        <div className="grid grid-cols-7 w-full gap-1 items-center border-t border-border-default pt-3 pb-5 font-bold text-xs text-dark-grey px-5">
          <div className="col-span-2">
            BUSINESS NAME
          </div>
          <div className="col-span-1">
            STATUS
          </div>
          <div className="col-span-1">
            BY
          </div>
          <div className="col-span-1">
            PHONE NUMBER
          </div>
          <div className="col-span-1">
            CATEGORY
          </div>
          <div className="col-span-1">
            ACTION
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3 pb-5">
        {
          list.map(b => <Item
            data={b}
            category={getCagetory(options_data?.data.categories || [], b.category_id)}
            onClickEdit={() => {
              setModalData(b);
              setShowModal(true);
            }}
            onClickDelete={() => {
              setModalData(b);
              setDeleteModal(true);
            }}
          />)
        }
      </div>
    </div>
  );
}
const Item = (props: { data: Business, category?: Category, onClickEdit: () => void, onClickDelete: () => void }) => {

  return (
    <div className="grid grid-cols-7 w-full gap-1 items-center border rounded-md border-border-default p-5">
      <div className="col-span-2 text-sm">
        <div className="flex flex-row items-center justify-start">
          <img src={props.data.logo || `https://getstream.io/random_svg/?name=${formatNameForImg(props.data.name)}`} className="w-10 h-10 object-cover rounded-full mr-5" />
          {props.data.name}
        </div>
      </div>
      <div className="col-span-1 text-sm">
        <BusinessStatusChip status={props.data.status === "active" ? BUSINESS_STATUS.ACTIVE : props.data.status === "inactive" ? BUSINESS_STATUS.INACTIVE : BUSINESS_STATUS.ON_TRIAL} text={props.data.status} />
      </div>
      <div className="col-span-1 text-sm">
        {props?.data?.owner?.name || ''}
      </div>
      <div className="col-span-1 text-sm">
        {props.data.phone_number}
      </div>
      <div className="col-span-1 text-sm">
        {props.category?.name}
      </div>
      <div className="col-span-1 text-xs">
        <div className="flex flex-row">
          <Link to={`business-details/${props.data.id}`} className="pr-2 text-blue">View Profile</Link>
          <Link to="#" onClick={props.onClickEdit} className="pr-2 text-blue">Edit</Link>
          <Link to="#" onClick={props.onClickDelete} className="pr-2 text-red">Delete</Link>
        </div>
      </div>

    </div>
  );
}

export default AdminBusinesses;