import React, { ClassAttributes, InputHTMLAttributes, useState } from 'react';
import { classNames } from '../../helpers/styles';
import PlusIcon from '../../assets/images/icons/plus_icon.svg';
import MinusIcon from '../../assets/images/icons/minus_icon.svg';

const Accordion = ({
    width = 'w-md',
    content= '',
    className = '',
    title = '',
    height,
    titleClass,
    ...props
}:AccordionProps) => {
    const [isActive, setIsActive] = useState(false);
    return (
        <div className={classNames('', width, className)}>
            <div className={classNames('flex justify-between hover:cursor-pointer', height)} onClick={() => setIsActive(!isActive)}>
                <span className={classNames('font-montserrat font-bold py-5 pl-5',titleClass)}>{title}</span>
                <img src={isActive ? MinusIcon : PlusIcon} alt="" className='h-4 w-4 my-7 m-5'/>
            </div>
            {
                isActive ? 
                <div className='pb-5 px-5'>
                    <span className='font-sans text-h6 text-dark-grey'>{content}</span>
                </div> : ''
            }
        </div>
    );
}

export interface AccordionProps {
    content: JSX.Element | string,
    title: string,
    className: string,
    width?: 'w-full' | 'w-3/4' | 'w-1/2' | 'w-1/4' | string
    height: string
    titleClass: string
}

export default Accordion;