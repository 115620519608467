import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BusinessState, Business } from './types';

const initialState: BusinessState = {
  list: [],
}

const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    retrieveBusinessList: (state, action: PayloadAction<Array<Business>>) => {
      state.list = action.payload
    },
  },
});

export const { retrieveBusinessList } = businessSlice.actions;

export default businessSlice;
