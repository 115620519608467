import { AuthUser } from "../../store/auth/types"

export type AuthUserResponse = {
  data: AuthUser
}

export type Credentials = {
  email: string
  password: string
  is_admin?: boolean
}

export type LoginResponse = {
  token: string
  id: number
  email: string
  name: string
  roles: Array<string>
  stream_id: string
}

export type CommunicationTokenResponse = {
  data: {
    id: number
    token: string
    stream_id: string
  }
}

export const AUTH_TAGS = {
  ME: 'ME',
  LOGIN: 'LOGIN',
}