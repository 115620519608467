import ProductCard from "../../../../components/ProductCard";
import heart_white from '../../../../assets/images/icons/heart_white.svg';
import SimpleProductCard from "../../../../components/SimpleProductCard";
import { useParams } from "react-router-dom";
import { useProductListQuery } from "../../../../services/business";

const OverviewTab = () => {
  const params = useParams();
  const id = params.business_id;
  const { data, isLoading } = useProductListQuery({ id: Number(id) })
  const product_list = data?.data || [];

  return (
    <div className="container m-auto pt-8 px-5 xl:px-0">
      <div className="flex justify-between items-center">
        <div className="flex">
          <h3>Most popular products</h3>
        </div>
      </div>
      <div className="pt-3 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 place-content-center place-items-center sm:place-items-center md:place-items-center lg:place-items-start">
        {
          !isLoading && product_list.map(p => (
            <ProductCard
              key={p.id}
              id={p.id}
              business_id={p.business.id}
              show_add_to_cart={false}
              show_percent_discount
              title={p.name}
              image={p.image}
              business_name={p.business.name}
              business_url={p.business.website_url}
              short_description={p.short_description}
              price={p.price}
              original_price={p.original_price}
              type={p.type}
              discount_description={p.discount_description}
              discount_percentage={p.discount_percentage}
              color={p.color}
            >
              <div className='absolute top-4 right-4 hover:cursor-pointer'>
                <img src={heart_white} className='w-6 h-5 object-center' alt="favorite" />
              </div>
            </ProductCard>
          ))
        }
      </div>
      <div className="flex justify-between items-center pt-14">
        <div className="flex">
          <h3>Valu Menus</h3>
        </div>
      </div>
      <div className="pt-3 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 place-content-center place-items-center sm:place-items-center md:place-items-center lg:place-items-start">
        {
          [1,2,3,4,5,6,7,8,9,10].map(p => (
            <SimpleProductCard key={p} />
          ))
        }
      </div>
      <div className="flex justify-between items-center pt-14">
        <div className="flex">
          <h3>Recently Viewed</h3>
        </div>
      </div>
      <div className="pt-3 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 place-content-center place-items-center sm:place-items-center md:place-items-center lg:place-items-start">
        {
          product_list.map(p => (
            <ProductCard
              key={p.id}
              id={p.id}
              business_id={p.business.id}
              show_add_to_cart={false}
              show_percent_discount
              title={p.name}
              image={p.image}
              business_name={p.business.name}
              business_url={p.business.website_url}
              short_description={p.short_description}
              price={p.price}
              original_price={p.original_price}
              type={p.type}
              discount_description={p.discount_description}
              discount_percentage={p.discount_percentage}
              color={p.color}
            >
              <div className='absolute top-4 right-4 hover:cursor-pointer'>
                <img src={heart_white} className='w-6 h-5 object-center' alt="favorite" />
              </div>
            </ProductCard>
          ))
        }
      </div>
    </div>
  );
}

export default OverviewTab;