import { useEffect } from 'react';
import './index.scss';
import ReactModal from 'react-modal';
import { classNames } from '../../helpers/styles';
const Modal = (props: any) => {
  let size = props.size || '';
  useEffect(() => {
    ReactModal.setAppElement('#root');
  }, []);

  return (
    <ReactModal {...props} className="Modal">
      <div className={classNames('modal-wrapper', size)}>
        <div className='content'>
          {props.children}
        </div>
      </div>
    </ReactModal>
  );
}

export default Modal;