import { Fragment } from 'react'
import { Listbox, Transition, } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { classNames } from '../../helpers/styles'
import Option from '../../models/Options'

export default function Select({
  options,
  value,
  placeholder,
  error,
  message = '',
  width = 'w-150',
  onChange,
}: SelectProps<any>) {

  let errors = undefined;

  if (!!error) {
    if (typeof message === 'string') {
      errors = <span className='text-red text-sm'>{message}</span>;
    } else {
      errors = message.map((err, i) => <span key={i} className='text-red text-sm'>{err}</span>)
    }
  }

  const option_item = (selected: boolean, option: Option) => {
    return (
      <div className='flex'>
        {
          option.icon && <div className='h-5 w-5 mr-2'>{option.icon}</div>
        }
        <span
          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
            }`}
        >
          {option.label}
        </span>
      </div>
    );
  }

  const selected_value = (value: OptionProps<any>) => {
    return (
      <div className='flex'>
        {
          value?.icon && <div className='h-5 w-5 mr-2'>{value.icon}</div>
        }
        <span className={classNames("block truncate", !value ? 'text-input-placeholder' : '')}>{value?.label || placeholder}</span>
      </div>
    );
  }

  return (
    <div className={classNames('flex flex-col justify-start items-start', width)}>
      <Listbox value={value} onChange={onChange}>
        <div className="relative w-full">
          <Listbox.Button className={classNames("relative w-full h-12 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-light-grey hover:border-dark-grey focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm", error ? ' border border-red' : '')}>
            <span className="block truncate">{selected_value(value)}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm text-left">
              {options.map((option, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-3 pr-10 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      {option_item(selected, option)}
                      {selected ? (
                        <span className="absolute inset-y-0 right-0 pr-2 flex items-center text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <div className='mt-2' />
      {errors}
    </div>
  )
}


export interface OptionProps<T> {
  label: string | undefined
  value: T | undefined
  icon?: JSX.Element | undefined
}
export interface SelectProps<T> {
  // ... other input props
  options: Array<Option>
  value: T
  placeholder?: string
  width?: string
  error?: boolean
  message?: string | Array<string>
  onChange(value: T): void;
  required?: boolean
}
