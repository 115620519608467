import React from 'react';
import sample_qr from '../../assets/images/samples/sample_qr.png';

const Claim = () => {
  return (
    <div className='w-full'>
      <div className='container m-auto flex flex-col items-center py-10'>
        <h5 className='text-center'>Show this to the staff when claiming</h5>
        <div className='flex flex-col rounded-md border border-border-default p-10 mt-5 items-center'>
            <img src={sample_qr} className='w-40 h-40' />
            <div className='bg-light-blue rounded-full px-4 py-2 text-dark-blue mt-3'>
              <h6>*VMEHHDA1345444</h6>
            </div>
            <div className='w-full px-5'>
              <div className='border-b border-border-default pt-5 pb-2'>
                <h6>Order Details</h6>
              </div>
              <div className='grid grid-cols-2 gap-10 border-b border-border-default text-sm py-5'>
                <span>Order Number</span>
                <span>VM0000005</span>
              </div>
              <div className='grid grid-cols-2 gap-10 border-b border-border-default text-sm py-5'>
                <span>Product name</span>
                <span>1 20% Off Full Body Massage</span>
              </div>
              <div className='grid grid-cols-2 gap-10 border-b border-border-default text-sm py-5'>
                <span>Date Purchased</span>
                <span>VM0000005</span>
              </div>
              <div className='grid grid-cols-2 gap-10 border-b border-border-default text-sm py-5'>
                <span>Order Number</span>
                <span>May 18, 12:00 PM</span>
              </div>
              <div className='grid grid-cols-2 gap-10 border-b border-border-default text-sm py-5'>
                <span>Customer</span>
                <span>Peter Klaus</span>
              </div>
              <div className='grid grid-cols-2 gap-10 border-b border-border-default text-sm py-5'>
                <span>Amount</span>
                <span>$12</span>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Claim;