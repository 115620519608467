import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Modal from '../../../components/Modal';
import BaseModal from '../../../components/Modal/BaseModal';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';

import AddIcon from '../../../assets/images/add_icon.svg';
import { classNames } from '../../../helpers/styles';
import { isValidImageAspectRatio } from '../../../helpers/image';
import Toggle from '../../../components/Switch';
import { useCreateMutation, useUpdateMutation } from '../../../services/categories';
import Swal from 'sweetalert2';
import { SketchPicker } from 'react-color';

const AddCategory = (props: any) => {
    const data = props?.data || {};
    const [createCategory, { isSuccess, isError }] = useCreateMutation();
    const [updateCategory] = useUpdateMutation();
    const hiddenFileInput = useRef<any>(undefined);
    const [name, setName] = useState<string>('');
    const [is_featured, setIsFeatured] = useState<boolean>(false);
    const [image_error, setImageError] = useState(false);
    const [image, setImage] = useState<any>({
        file: undefined,
        url: ''
    });
    const [color, setColor] = useState('#fff');

    useEffect(() => {
        setImage({
            file: undefined,
            url: ''
        });
    }, []);
    useEffect(() => {
        if (isSuccess) {
            Swal.fire({
                title: 'Success!',
                text: 'Created Successfully!',
                icon: 'success',
                confirmButtonText: 'Ok',
                didClose() {
                    props?.onRequestClose();
                },
            });
        }

    }, [isSuccess]);

    useEffect(() => {
        if (!!data && data?.id) {
            setName(data?.name);
            setIsFeatured(data?.is_featured);
            if (!!data?.color) setColor(data?.color);
        }
    }, [data]);

    const handleOnUploadClick = () => {
        hiddenFileInput.current?.click();
    }

    const handleOnImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const img_file = e.target.files[0];
            const img_url = URL.createObjectURL(img_file);
            const is_valid = await isValidImageAspectRatio(img_url, { min_height: 512, min_width: 512, ratio: 1 / 1 });

            if (!is_valid) {
                setImageError(true);
                return;
            }

            setImage({
                file: img_file,
                url: img_url,
            });
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!!data?.id) {
            handleUpdate();
            return;
        }

        const params = {
            name,
            is_featured,
            color,
        };
        try {
            const xhr: any = await createCategory(params);
            if (xhr?.error?.status >= 400) {
                throw xhr;
            }
            console.log('xhr ', xhr);
        } catch (e: any) {
            console.log(e);
            Swal.fire({
                title: 'Error!',
                text: e?.error?.data?.message || 'An Error Occurred!',
                icon: 'error',
                confirmButtonText: 'Ok',
            });
        }
    }

    const handleUpdate = async () => {
        const params = {
            name,
            is_featured,
            color,
            id: data?.id,
        };
        try {
            const xhr: any = await updateCategory(params);
            if (xhr?.error?.status >= 400) {
                throw xhr;
            }
            Swal.fire({
                title: 'Success!',
                text: 'Updated Successfully!',
                icon: 'success',
                confirmButtonText: 'Ok',
                didClose() {
                    props?.onRequestClose();
                },
            });
        } catch (e: any) {
            console.log(e);
            Swal.fire({
                title: 'Error!',
                text: e?.error?.data?.message || 'An Error Occurred!',
                icon: 'error',
                confirmButtonText: 'Ok',
            });
        }
    }

    return (
        <BaseModal
            title={`${data.id ? 'Update' : 'Add New'} Category`}
            className="modal-container"
            is_open={props?.is_open}
            onRequestClose={props?.onRequestClose}
        >
            <form onSubmit={handleSubmit}>
                <div className="w-full pt-6">
                    <TextInput type="text" width="w-full" margin_bottom='' placeholder="Category Name" value={name} onChange={e => setName(e.target.value)} required />
                    <div className="flex flex-row pt-5 items-center">
                        <span className="pr-10 text-sm">Is Featured</span>
                        <Toggle checked={is_featured} onChange={setIsFeatured} />
                    </div>
                    <div className="flex flex-row pt-5 items-center">
                        <span className="pr-10 text-sm">Color</span>
                        <SketchPicker color={color} onChange={(c) => setColor(c.hex)} />
                    </div>
                </div>
                {/* <div className="flex space-x-6 pt-5">
                    <div
                        className="flex sm:h-20 sm:w-20 md:h-20 md:w-20 max-sm:h-20 max-sm:w-20 lg:h-28 lg:w-28 border-2 border-dashed border-gray-400 rounded-xs bg-grey1 cursor-pointer"
                        onClick={handleOnUploadClick}
                    >
                        <img src={image?.url !== '' ? image.url : AddIcon} className={classNames(image.url !== '' ? 'object-cover w-full h-full rounded-full' : 'h-4 w-4 m-auto align-middle')} />
                    </div>
                    <div>
                        <div className="pt-2">
                            <span className="text-base font-montserrat font-bold">Profile Photo (Optional)</span><br />
                            <span className="font-sans text-sm italic">*Accepted files jpg, png and must be 512x512</span>
                            <div className="mt-2">
                                <Button type="button" height="h-8" text="Upload" bg_color="bg-sky-blue" text_size='text-xs' width="w-100" onClick={handleOnUploadClick} />
                                <input type='file' accept="image/*" ref={hiddenFileInput} style={{ visibility: 'hidden' }} onChange={handleOnImageChange} />
                                {
                                    image_error && <span className='flex text-sm text-red font-sans mt-2'>Image must be atleast 512x512 and with 1:1 ratio</span>
                                }
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="mt-8">
                    <Button type="submit" text={`${data?.id ? "EDIT" : "ADD"} CATEGORY`} width="lg:w-full sm:w-full md:w-full max-sm:w-full" onSubmit={handleSubmit} />
                </div>
            </form>
        </BaseModal>
    );
}

export default AddCategory;