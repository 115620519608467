import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Business from './Business';
import Dashboard from './Dashboard';
import Help from './Help';
import './index.scss';
import Messages from './Messages';
import Settings from './Settings';
import { useEffect, useState } from 'react';
import { router_paths } from '../../../../routes';
import { useCommunicationTokenQuery } from '../../../../services/auth';

const ProfileTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { data, isLoading } = useCommunicationTokenQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const url_params = useParams();

  const tab_list = [
    {
      label: "Dashboard",
      path: `${router_paths.PROFILE}`,
    },
    {
      label: "Business",
      path: `${router_paths.PROFILE}/business`,
    },
    {
      label: "Messages",
      path: `${router_paths.PROFILE}/messages`,
    },
    {
      label: "Settings",
      path: `${router_paths.PROFILE}/settings`,
    },
    {
      label: "Help",
      path: `${router_paths.PROFILE}/help`,
    },
  ];

  useEffect(() => {
    const tab_index = tab_list.findIndex(tl => tl.path === location.pathname) || 0;

    setTabIndex(tab_index);
  }, [url_params]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleTabSelect = (index: number) => {
    setTabIndex(index);
    navigate(tab_list[index].path);
  }

  return (
    <Tabs selectedIndex={tabIndex} onSelect={handleTabSelect}>
      <div className='container m-auto'>
        <TabList>
          <Tab>Dashboard</Tab>
          <Tab>Business</Tab>
          <Tab className="react-tabs__tab notified">Messages</Tab>
          <Tab>Settings</Tab>
          <Tab>Help</Tab>
        </TabList>
      </div>
      <TabPanel>
        <Dashboard />
      </TabPanel>
      <TabPanel>
        <Business />
      </TabPanel>
      <TabPanel>
        <Messages />
      </TabPanel>
      <TabPanel>
        <Settings />
      </TabPanel>
      <TabPanel>
        <Help />
      </TabPanel>
    </Tabs>
  )
}
  


export default ProfileTabs;