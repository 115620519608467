import { ORDER_STATUS } from "../../constants/purchase_history";
import Button from "../Button";

const OrderStatusChip = (props: any) => {
  let text = "";
  let bg = "";
  switch (props.status) {
    case ORDER_STATUS.FULFILLED:
      text = "Fulfilled";
      bg = "bg-[#86AD16]";
      break;
    case ORDER_STATUS.PARTIALLY_FULFILLED:
      text = "Partially Fulfilled";
      bg = "bg-[#01539B]";
      break;
    case ORDER_STATUS.UNFULFILLED:
      text = "Unfulfilled";
      bg = "bg-[#787878]";
      break;
  }

  return (
    <Button
      className={`${bg} h-7`}
      bg_color="hover:opacity-100"
      border_radius="rounded-md"
      width="min-w-auto"
      text_color="text-white font-bold text-xs"
      text={text}
    />
  );
}

export default OrderStatusChip;