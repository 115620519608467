import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import OverviewTab from './OverviewTab';
import './index.scss';
import Reviews from './Reviews';

const BusinessDetailsTabs = () => (
  <Tabs default defaultIndex={0}>
    <div className='container m-auto'>
      <TabList>
        <Tab>Overview</Tab>
        <Tab>Reviews</Tab>
        <Tab>About</Tab>
      </TabList>
    </div>
    <TabPanel>
      <OverviewTab />
    </TabPanel>
    <TabPanel>
      <Reviews />
    </TabPanel>
    <TabPanel>
      <Tab>About</Tab>
    </TabPanel>
  </Tabs>
);

export default BusinessDetailsTabs;