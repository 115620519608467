import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';

import { combineReducers } from '@reduxjs/toolkit';
import logger from 'redux-logger';


import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import authSlice from './auth/slice';
import addProdPromoSlice from './add_prod_promo/slice';
import signUpSlice from './sign_up/slice';
import categoriesSlice from './categories/slice';
import loginSlice from './login/slice';
import businessSlice from './business/slice';
import businessSignUpSlice from './business_signup/slice';

import storage from 'redux-persist/lib/storage';
import { authApi } from '../services/auth';
import { signUpApi } from '../services/sign_up';
import { categoriesApi } from '../services/categories';
import { addProdPromoApi } from '../services/add_prod_promo';
import { businessApi } from '../services/business';
import { fileApi } from '../services/file';
import { optionsApi } from '../services/options';
import cartSlice from './cart/slice';
import { cartApi } from '../services/cart';
import { userApi } from '../services/user';



const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ["auth", "cart", optionsApi.reducerPath],
}


const rootReducer = combineReducers({
  // ...
  auth: authSlice.reducer,
  login: loginSlice.reducer,
  counter: counterReducer,
  addProdPromo: addProdPromoSlice.reducer,
  signUp: signUpSlice.reducer,
  categories: categoriesSlice.reducer,
  business: businessSlice.reducer,
  business_signup: businessSignUpSlice.reducer,
  cart: cartSlice.reducer,

  [userApi.reducerPath]: userApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [signUpApi.reducerPath]: signUpApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [addProdPromoApi.reducerPath]: addProdPromoApi.reducer,
  [businessApi.reducerPath]: businessApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [optionsApi.reducerPath]: optionsApi.reducer,
  [cartApi.reducerPath]: cartApi.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export default rootReducer;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      serializableCheck: false,
    })
      .concat([
        authApi.middleware,
        signUpApi.middleware,
        categoriesApi.middleware,
        addProdPromoApi.middleware,
        businessApi.middleware,
        fileApi.middleware,
        optionsApi.middleware,
        cartApi.middleware,
        userApi.middleware,
      ])
  // .concat(logger),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
