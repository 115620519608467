import React from 'react';
import ActiveDailyCustomersIcon from '../../assets/images/icons/active_daily_customers.svg';
import CitiesNationWideIcon from '../../assets/images/icons/cities_nationwide.svg';
import Business from '../../assets/images/icons/business.svg';
import BusinessOnTheGoIcon from '../../assets/images/icons/business_on_the_go.svg';
import BusinessTransparentPattern from '../../assets/images/business_transparent_pattern.png';
import BusinessImage1 from '../../assets/images/for_business_1.png';
import BusinessImage2 from '../../assets/images/for_business_2.png';
import BusinessImage3 from '../../assets/images/for_business_3.png';
import AttractCustomersIcon from '../../assets/images/attract_new_customers.png';
import Button from '../../components/Button';
import NewAudiencesIcon from '../../assets/images/icons/new_audiences.svg';
import BoostBrandLoyaltyIcon from '../../assets/images/icons/boost_brand_loyalty.svg';
import DestinationChoiceIcon from '../../assets/images/icons/destination_choice.svg';
import Accordion from '../../components/Accordion';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckCircle from '../../assets/images/icons/check_circle.svg';
import BusinessPic1 from '../../assets/images/business_pic_1.png';
import BusinessPic2 from '../../assets/images/business_pic_2.png';
import BusinessPic3 from '../../assets/images/business_pic_3.png';
import BusinessPic4 from '../../assets/images/business_pic_4.png';
import BusinessPic5 from '../../assets/images/business_pic_5.png';
import BusinessPic6 from '../../assets/images/business_pic_6.png';
import BusinessPic7 from '../../assets/images/business_pic_7.png';
import BusinessPic8 from '../../assets/images/business_pic_8.png';
import BusinessPic9 from '../../assets/images/business_pic_9.png';
import BusinessPic10 from '../../assets/images/business_pic_10.png';
import ConnetCustomersDirectly from '../../assets/images/icons/connect_customers_directly.svg';
import BrandLoyaltySmall from '../../assets/images/icons/boost_brand_loyalty_small.svg';
import { classNames } from '../../helpers/styles';
import AllanJamesWorth from '../../assets/images/allan_jamesworth_pic.png'
import LiquorCompanyLogo from '../../assets/images/liquor_company_logo.png';


type business_images = {
    [key: string]: string;
};

const business_img: business_images = {
    BusinessPic1,
    BusinessPic2,
    BusinessPic3,
    BusinessPic4,
    BusinessPic5,
    BusinessPic6,
    BusinessPic7,
    BusinessPic8,
    BusinessPic9,
    BusinessPic10,
}

const img_cls = [
    {
        height: '175px',
        width: '218.53px',
        marginTop: '0rem'
    },
    {
        height: '121.39px',
        width: '121.39px',
        marginTop: '1rem'
    },
    {
        height: '121.39px',
        width: '134.14px',
        marginTop: '1rem'
    },
    {
        height: '121.39px',
        width: '126.06px',
        marginTop: '1rem'
    },
    {
        height: '85px',
        width: '166.6px',
        marginTop: '1.5rem'
    },
    {
        height: '130px',
        width: '130px',
        marginTop: '0rem'
    },
    {
        height: '172.87px',
        width: '172.87px',
        marginTop: '0rem'
    },
    {
        height: '172.87px',
        width: '172.87px',
        marginTop: '0rem'
    },
    {
        height: '121.39px',
        width: '125.46px',
        marginTop: '1.5rem'
    },
    {
        height: '85px',
        width: '138.13px',
        marginTop: '2rem'
    }
]

const faq = [
    {
        title: 'Frequently Asked Question',
        content: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.'
    },
    {
        title: 'Frequently Asked Question',
        content: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.'
    },
    {
        title: 'Frequently Asked Question',
        content: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.'
    },
    {
        title: 'Frequently Asked Question',
        content: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.'
    },
    {
        title: 'Frequently Asked Question',
        content: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.'
    }
];

const valu_menu_display = [
    {
        icon: ActiveDailyCustomersIcon,
        title: 'Thounsands of active daily users',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem ipsum',
        bgColor: 'bg-light-orange'
    },
    {
        icon: CitiesNationWideIcon,
        title: 'Over 40+ Cities Nationwide',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem ipsum',
        bgColor: 'bg-dark-blue-green'
    },
    {
        icon: BusinessOnTheGoIcon,
        title: 'Promote Business \n From Your Phone',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem ipsum',
        bgColor: 'bg-dark-pink'
    },
    {
        icon: Business,
        title: 'Trusted by 500+ Businesses',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem ipsum',
        bgColor: 'bg-dark-green'
    },
    {
        icon: BrandLoyaltySmall,
        title: 'Boost Brand \n Loyalty',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem ipsum',
        bgColor: 'bg-light-maroon'
    },
    {
        icon: ConnetCustomersDirectly,
        title: 'Connect with Your Customers Directly',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem ipsum',
        bgColor: 'bg-pale-light-blue'
    }
]

const Mobile = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');

    const navigate = useNavigate();

    const routePage = () => {
        navigate('/app/for-business?type=pricing');
    }

    return (
        <div id="MobileForBusiness">
            {type === 'display' ?
                <div className='bg-light-orange bg-blend-darken' style={{ height: '44.375rem', backgroundImage: `url(${BusinessTransparentPattern})` }}>
                    <div className='text-center left-0 right-0 top-28 mx-6'>
                        <div className='pt-12 w-full'>
                            <span className='text-h3 font-montserrat text-black font-bold'>New & Better Way To Promote Your Business</span>
                        </div>
                        <div className='mt-2'>
                            <span className='font-sans text-black text-sm leading-5'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</span>
                        </div>
                        <div className='flex justify-center my-6 space-x-20'>
                            <div className='justify-between w-32'>
                                <img src={ActiveDailyCustomersIcon} alt="" className='h-16 w-16 ml-8'/>
                                <span className='text-black text-sm font-bold font-montserrat'>1400+ Active Daily Customers</span>
                            </div>
                            <div className='justify-between w-32'>
                                <img src={Business} alt="" className='h-16 w-16 ml-8'/>
                                <span className='text-black text-sm font-bold font-montserrat'>Trusted by 500+ Businesses</span>
                            </div>
                        </div>
                        <div className='flex justify-center my-6 space-x-20'>
                            <div className='justify-between w-32'>
                                <img src={CitiesNationWideIcon} alt="" className='h-16 w-16 ml-8'/>
                                <span className='text-black text-sm font-bold font-montserrat'>Over 40+ Cities Nationwide</span>
                            </div>
                            <div className='justify-between w-32'>
                                <img src={BusinessOnTheGoIcon} alt="" className='h-16 w-16 ml-8'/>
                                <span className='text-black text-sm font-bold font-montserrat'>Your Business on the Go.</span>
                            </div>
                        </div>
                        <div className='flex justify-center space-x-1'>
                            <div>
                                <img src={BusinessImage1} alt="" className='pt-4 object-cover' style={{ height: '18.25rem', width:'10.22rem' }}/>
                            </div>
                            <div>
                                <div className='h-full'>
                                    <img src={BusinessImage2} alt="" className='w-80 mb-1' style={{ height: '11.64rem', width:'10.22rem' }}/>
                                    <img src={BusinessImage3} alt="" className='w-80 mt-1' style={{ height: '6.40rem', width:'10.22rem' }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :
                <div className='bg-pale-green' style={{ height: '31.8125rem', backgroundImage: `url(${BusinessTransparentPattern})` }}>
                    <div className='flex justify-center mx-6'>
                        <div className='mt-12'>
                            <div className='text-center w-80'>
                                <span className='font-montserrat font-bold text-h3'>Level up your Business with ValuMenu.</span>
                            </div>
                            <div className='text-center w-80 mt-4'>
                                <span className='font-sans text-base'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</span>
                            </div>
                            <div className='rounded-md border bg-white mt-11' style={{ height: '27.1875rem', width: '20.8125rem' }}>
                                <div className='mx-7'>
                                    <div className='text-center mt-9'>
                                        <div><span className='text-lg font-montserrat font-bold text-dark-blue'>BUSINESS</span></div>
                                        <div className='mt-2'><span className='text-5xl font-bold font-montserrat'>$49.00</span></div>
                                        <div className='mt-3'><span className='font-sans text-base text-dark-grey'>per month and 1% per transaction</span></div>
                                    </div>
                                    <div className='text-left mt-16'>
                                        <div className='text-left flex'>
                                            <img src={CheckCircle} className='-ml-12 -mr-7 -my-9'/>
                                            <span className='text-base font-sans pt-2'>Benefit number 1 should be here</span>
                                        </div>
                                        <div className='text-left flex'>
                                            <img src={CheckCircle} className='-ml-12 -mr-7 -my-9'/>
                                            <span className='text-base font-sans pt-2'>Benefit number 1 should be here</span>
                                        </div>
                                        <div className='text-left flex'>
                                            <img src={CheckCircle} className='-ml-12 -mr-7 -my-9'/>
                                            <span className='text-base font-sans pt-2'>Benefit number 1 should be here</span>
                                        </div>
                                        <div className='mt-6'>
                                            <Button text='SEND MESSAGE' width='w-full'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {type=== 'display' ?
                <div className='my-32 justify-center'>
                    <div className='flex justify-center'>
                        <div className='text-left mx-10'>
                            <span className='text-black font-montserrat text-h5 w-80'>Attract New Customers and Build Brand Loyalty</span><br/><br/>
                            <span className='text-black font-sans text-sm'>You have something unique to offer, and you need to reach the people who want to experience it. With ValuMenu, you can. We have one of the largest platforms in the world, and a tech-forward approach to getting your offer to the right people. </span><br/><br/>
                            <span className='text-black font-sans text-sm'>Everyday excellence. Extraordinary experiences. No matter the need, you make it happen. We help you find visitors, and you give them something to remember.</span><br/><br/>
                            <Button text='JOIN' onClick={routePage}/>
                        </div>
                    </div>
                    <div className='flex justify-center mx-10'>
                        <img src={AttractCustomersIcon} alt="" className='pt-12' style={{ width: '24rem', height: '15.31rem'}}/>
                    </div>
                </div> : 
                <div className='mt-72 mb-16 flex justify-center'>
                    <div className='mx-7 text-center'>
                        <div className='px-12'>
                            <span className='text-h5 font-bold font-montserrat'>Loved by 1000+ of Businesses Like Yours</span>
                        </div>
                        <div className='grid grid-cols-2 gap-4'>
                            {
                                img_cls.map((item, index) => (
                                    <div key={index} className='flex justify-center'>
                                        <img src={business_img[`BusinessPic${index+1}`]} style={{ height: `${item.height}`, width: `${item.width}`, marginTop: `${item.marginTop}`}} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }

            {type==='pricing' ?
                <div className='flex justify-center mb-20'>
                    <div>
                        <div className='text-center'>
                            <span className='font-montserrat text-h4 font-bold'>Why Use Valu Menu</span>
                        </div>
                        <div className='text-center mt-4 px-12'>
                            <span className='font-sans text-sm'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</span>
                        </div>
                        <div className='grid grid-cols-1 gap-4 mt-8'>
                            {
                                valu_menu_display.map((item, index) => (
                                    <div key={index}  className='flex justify-center'>
                                        <div style={{ height: '15.8125rem', width: '20.8125rem' }} className={classNames('rounded-xl border', item.bgColor)}>
                                            <div className='flex justify-center mt-4'>
                                                <img src={item.icon} alt="" className='w-16 h-16'/>
                                            </div>
                                            <div className='text-center mx-5 mt-2'>
                                                <span className='text-lg font-bold font-montserrat whitespace-pre-line'>{item.title}</span>
                                            </div>
                                            <div className='text-center mx-5 mt-2'>
                                                <span className='text-lg font-sans'>
                                                    {item.description}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div> : ''
            }

            <div className='flex justify-center mb-32'>
                <div>
                    <div className='flex justify-center'>
                        <img src={AllanJamesWorth} alt="" className='h-20 w-20'/>
                    </div>
                    <div className='text-center mt-4 px-10'>
                        <span className='font-montserrat font-bold text-h5'>“Since we promoted our products and services at Valu Menu, our sales have been doubled!”</span>
                    </div>
                    <div className='text-center mt-6 px-20'>
                        <span className='font-bold font-sans text-sm'>Allan Jamesworth, CEO and Founder of </span>
                        <span className='font-sans text-sm'>Big Country Liquor</span>
                    </div>
                    <div className='flex justify-center mt-6'>
                        <img src={LiquorCompanyLogo} alt="" className='h-14 w-14'/>
                    </div>
                </div>
            </div>
            
            {type==='display' ?
                <div className='bg-pale-green bg-blend-darken' style={{ backgroundImage: `url(${BusinessTransparentPattern})` }}>
                    <div className='text-center left-0 right-0 top-28 mx-6'>
                        <div className='pt-12 w-full'>
                            <span className='text-h5 font-montserrat text-black font-bold'>ValuMenu is the Marketplace for Local experiences</span><br/><br/>
                            <span className='text-black font-sans text-sm'>Groupon is more than a place to shop for deals. It’s where customers around the world explore where to go, what to do, or what to buy today. Here’s how Groupon Merchant helps promote your business. </span><br/><br/>
                            <Button text='JOIN' onClick={routePage}/>
                        </div>
                        <div className='flex justify-center mt-8'>
                            <div className='bg-white rounded-md mb-3' style={{ width: '20.81rem', height: '15.875rem' }}>
                                <div className='flex justify-center'>
                                    <img src={NewAudiencesIcon} alt="" className='h-14 w-14 mt-6'/>
                                </div>
                                <div className='mt-6 text-center'>
                                    <div className='mb-2'>
                                        <span className='text-base font-montserrat'>Find New Audiences</span><br/>
                                    </div>
                                    <div className='px-5 mb-2'>
                                        <span className='text-base font-sans mt-6'>We invest millions in marketing campaigns across web and mobile to bring customers to the ValuMenu platform.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center mt-2'>
                            <div className='bg-white rounded-md mb-3' style={{ width: '20.81rem', height: '15.875rem' }}>
                                <div className='flex justify-center'>
                                    <img src={BoostBrandLoyaltyIcon} alt="" className='h-14 w-14 mt-6'/>
                                </div>
                                <div className='mt-6 text-center'>
                                    <div className='mb-2'>
                                        <span className='text-base font-montserrat'>Boost Brand Loyalty</span><br/>
                                    </div>
                                    <div className='px-5 mb-2'>
                                        <span className='text-base font-sans mt-6'>We invest millions in marketing campaigns across web and mobile to bring customers to the ValuMenu platform.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center mt-2 pb-8'>
                            <div className='bg-white rounded-md mb-3' style={{ width: '20.81rem', height: '15.875rem' }}>
                                <div className='flex justify-center'>
                                    <img src={DestinationChoiceIcon} alt="" className='h-14 w-14 mt-6'/>
                                </div>
                                <div className='mt-6 text-center'>
                                    <div className='mb-2'>
                                        <span className='text-base font-montserrat'>Be the Destination of Choice</span><br/>
                                    </div>
                                    <div className='px-5 mb-2'>
                                        <span className='text-base font-sans mt-6'>People are always looking for their new favorite thing to do. We’ll use our tools to help them find you.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ''
            }
            
            <div className='my-20 text-center'>
                <div className='flex justify-center'>
                    <span className='text-black font-montserrat text-h5'>Frequently Asked Questions</span><br/><br/>
                </div>
                <div className='flex justify-center'>
                    <div>
                        {
                            faq.map((item, index) => (
                                <Accordion key={index} content={item.content} title={item.title} className='bg-white rounded border-2 border-gray-200 mb-4 h-' width='w-80' height='h-16' titleClass='text-base'/>
                            ))
                        }
                    </div>
                </div>
            </div>

            {type === 'display' ?
                <div className='bg-light-grey text-center'>
                    <div className='pt-9 mx-8'>
                        <div className='mb-2'>
                            <span className='text-black font-montserrat text-h5'>Read to Level up your Business?</span>
                        </div>
                        <span className='text-black font-sans text-base'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. </span><br/><br/>
                    </div>
                    <div  className='pt-2 pb-10'>
                        <Button text="JOIN" onClick={routePage}/>
                    </div>
                </div> : ''
            }
            
        </div>
    );
}

export default Mobile;