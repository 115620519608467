import Logo from '../../../src/assets/images/valu_menu.svg';
import './index.scss';
const SplashScreen = () => {
  return (
    <div className='h-full w-full flex items-center justify-center'>
      <img src={Logo} className='SplashScreen-App-logo' />
    </div>
  );
}

export default SplashScreen;