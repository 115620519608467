import TextInput from "../TextInput";
import search_icon from '../../assets/images/search_gray.svg';
import close_icon from '../../assets/images/icons/close.svg';
import { classNames } from "../../helpers/styles";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

const Searchbar = (props: { show: boolean, hideSearchbar: () => void, ref?: any }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const search_ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!!props.show) {
      search_ref.current?.focus();
    }
  }, [props.show]);



  return (
    <div className={classNames("w-full py-1 pt-2 border-b px-[5%] bg-white", props.show ? 'visible' : 'hidden')}>
      <form onSubmit={(e) => {
        e.preventDefault();

        if (!(search.trim())) return;
        props.hideSearchbar();
        navigate(`/app/search-result?search=${search}`);
      }}>
        <TextInput
          innerRef={search_ref}
          placeholder="Business Name, Product Name"
          width="w-full"
          containerClassName="border-0"
          left_icon={<img src={search_icon} className="w-5 h-5 m-2" />}
          right_icon={<img src={close_icon} className="w-5 h-5 m-2 cursor-pointer" onClick={props.hideSearchbar} />}
          left_icon_border={false}
          right_icon_border={false}
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </form>
    </div>
  );
}

export default Searchbar;