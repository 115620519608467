import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithCSRF } from '../fetchBaseQuery';
import { store } from '../../store/store';
import { saveOption } from '../../store/categories/slice';
import { Options, OptionsResponse } from '../../models/Options';

export const optionsApi = createApi({
    reducerPath: 'optionsApi',
    baseQuery: baseQueryWithCSRF,
    endpoints: (builder) => ({
        optionsV2: builder.query<Options, void>({
            query() {
                return {
                    url: '/api/v1/options',
                    method: 'GET',
                };
            },
            transformResponse: ({ data }: OptionsResponse) => {
                const options: Options = {
                    categories: [],
                    states: [],
                };

                options.categories = data.categories.map(c => ({ ...c, label: c.name, value: c.id }));
                options.states = Object.keys(data.states).map(k => ({ label: data.states[k], value: k }));
                return options;
            }
        })
    })
})

export const { useOptionsV2Query } = optionsApi