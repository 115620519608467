import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithCSRF } from '../fetchBaseQuery';
import { store } from '../../store/store';
import { OptionsResponse, SignUpResponse, Credentials } from './types';
import { saveOption } from '../../store/sign_up/slice';
import { saveToken } from '../../store/auth/slice';

export const signUpApi = createApi({
    reducerPath: 'signUpApi',
    baseQuery: baseQueryWithCSRF,
    endpoints: (builder) => ({
        options: builder.query<OptionsResponse, void>({
            query() {
                return {
                    url: '/api/v1/options',
                    method: 'GET',
                };
            },
            transformResponse: (response: OptionsResponse) => {
                store.dispatch(saveOption(response.data));
                return response;
            }
        }),
        signUp: builder.mutation<SignUpResponse, Credentials>({
            query(credentials) {
                return {
                    url: '/api/register',
                    method: 'POST',
                    body: credentials
                }
            },
            transformResponse: (response: SignUpResponse) => {
                if (!!response.token) store.dispatch(saveToken(response.token));
                return response;
            },
        }),
        adminSignUp: builder.mutation<SignUpResponse, Credentials>({
            query(credentials) {
                return {
                    url: '/api/register',
                    method: 'POST',
                    body: credentials
                }
            },
            transformResponse: (response: SignUpResponse) => {
                response.token = '';
                return response;
            },
        }),
        adminUserUpdate: builder.mutation<SignUpResponse, Credentials>({
            query(credentials) {
                return {
                    url: `/api/int/users/${credentials.id}`,
                    method: 'PUT',
                    body: credentials
                }
            },
        })
    })
})

export const { useOptionsQuery, useSignUpMutation, useAdminSignUpMutation, useAdminUserUpdateMutation } = signUpApi