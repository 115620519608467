import { useEffect, useRef, useState } from "react";
import { StreamChat } from "stream-chat";

export const useClient = (props: any) => {
  const { userData, tokenOrProvider } = props;
  const apiKey = process.env.REACT_APP_STREAM_CHAT_API_KEY;
  const prevUserData = useRef(userData);

  const [chatClient, setChatClient] = useState<any>(null);

  useEffect(() => {
    if (!!apiKey) {
      const client = StreamChat.getInstance(apiKey);

      let didUserConnectInterrupt = false;

      if (!!userData.id && !!tokenOrProvider) {

        let connectionPromise: any = undefined;

        if (!!prevUserData.current.id && prevUserData.current.id !== userData.id) {
          if (client.user) {
            connectionPromise = client.disconnectUser()
              .then(() => {
                setChatClient(null);
              })
              .then(() => {
                client.connectUser(userData, tokenOrProvider)
                  .then(() => {
                    setChatClient(client);
                  });
              });
          }
        } else {
          if (client.user) {
            connectionPromise = client.disconnectUser()
              .then(() => {
                setChatClient(null);
              })
              .then(() => {
                client.connectUser(userData, tokenOrProvider)
                  .then(() => {
                    setChatClient(client);
                  });
              });
          } else {
            connectionPromise = client.connectUser(userData, tokenOrProvider)
              .then(() => {
                setChatClient(client);
              });
          }
        }

        return () => {
          didUserConnectInterrupt = true;
          setChatClient(null);
          // wait for connection to finish before initiating closing sequence
          connectionPromise
            .then(() => client.disconnectUser())
            .then(() => {
              console.log('connection closed');
            });
        };
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, userData.id, tokenOrProvider]);

  return chatClient;
}