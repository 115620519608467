import React from 'react';

import Desktop from './Desktop';
// import Mobile from './Mobile';


const Categories = () => {
    return (
        <div id="Categories">
            <div className='lg:block justify-center'>
                <Desktop/>
            </div>
            <div className='lg:hidden'>
                {/* <Mobile/> */}
            </div>
        </div>
    );
}

export default Categories;