import { InputHTMLAttributes, useId, useState } from 'react'
import { classNames } from '../../helpers/styles';
import './styles.css';

export default function Radio({
  label,
  error = false,
  labelClassName,
  ...props
}: RadioProps) {

  return (
    <label className={classNames("input-radio container text-sm", labelClassName)}><span>{label}</span>
      <input type="radio" {...props} />
      <span className={classNames("radio checkmark border dark-grey", !!error ? 'border-red' : '')}></span>
    </label>
  )
}

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  // ... other input props
  label?: string
  error?: boolean
  labelClassName?: string
}