export const PRODUCT_SERVICE = 1;
export const PROMO_CODE = 2;

export const TYPE_PRODUCT_SERVICE = "Product";
export const TYPE_PROMO_CODE = "PromoCode";

export enum BUSINESS_STATUS {
    ACTIVE,
    INACTIVE,
    ON_TRIAL,
}