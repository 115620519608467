import React, { ChangeEvent } from "react";
import ValuMenuIcon from '../../assets/images/valu_menu_sign_in_logo.svg';
import AllanJameworthPic from '../../assets/images/allan_jamesworth_pic.png';
import ArrowLeft from '../../assets/images/icons/chevron_left.svg';
import MobileLogo from '../../assets/images/valu_menu.svg';
import BusinessDetails from "../../components/BusinessDetails";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { SignUpKeys, onSignUpFormChange } from "../../store/sign_up/slice";
import { getImageSize } from 'react-image-size';
import { Link } from "react-router-dom";
import { useOptionsQuery } from "../../services/sign_up";

const SignUpBusinessDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useOptionsQuery();

    const routePage = () => {
        navigate('/sign-up');
    }

    const handleOnChange = (key: SignUpKeys, value: any) => {
        dispatch(onSignUpFormChange({ key, value }));
    }

    const handleFileChange = (key:SignUpKeys, e:ChangeEvent<HTMLInputElement>) => {
        if(e.target.files){
            const validate_image = validateImageSize('url_err', URL.createObjectURL(e.target.files[0]));
            if (!validate_image) {
                let value = URL.createObjectURL(e.target.files[0]);
                dispatch(onSignUpFormChange({ key, value }));
            }
        }
    }

    const validateImageSize = async (key:SignUpKeys, image:string) => {
        const { width, height } = await getImageSize(image);

        if (width < 512 && height < 512) {
            let value = true;
            dispatch(onSignUpFormChange({ key, value }));
            return true;
        } else if (width === height) {
            let value = false;
            dispatch(onSignUpFormChange({ key, value }));
            return false;
        }
    }


    return (
        <div id="BusinessDetailsSignUp" className='w-screen'>
            <div className="flex h-screen">
                <div className='relative lg:block md:hidden sm:hidden max-sm:hidden'>
                    <div style={{ width: '26rem' }} className='h-full bg-dark-blue'/>
                    <div className='absolute top-8 left-10'>
                        <img src={ValuMenuIcon} alt="" className=''/>
                    </div>
                    <span className='text-h5 font-montserrat text-white absolute top-36 left-10 w-80'>"Since we promoted our products and services at Valu Menu, our sales have been doubled!"</span>
                    <div className='absolute top-72 left-10 w-80'>
                        <div className='flex'>
                            <img src={AllanJameworthPic} alt="" className='h-12 w-12 rounded-full'/>
                            <div className='w-52 ml-4 leading-5 pt-1'>
                                <span className='text-sm text-neutral-200 font-sans'>Allan Jamesworth, CEO and Founder of Big Country Liquor</span>
                            </div>
                        </div>
                    </div>
                    <div className='absolute bottom-6 w-80 left-10'>
                        <span className='text-neutral-300 text-sm font-sans'>Copyright @ 2021 Valu Menu. All Rights Reserved</span>
                    </div>
                </div>
                <div className='lg:w-full'>
                    <div className='flex justify-between lg:mx-10 my-9 md:mx-20 sm:mx-10 max-sm:mx-5'>
                        <div className='flex space-x-3 w-1/3 hover:cursor-pointer' onClick={routePage}>
                            <img src={ArrowLeft} alt="" className='w-2'/>
                            <Link to='/sign-up' className='text-base font-sans'>Back</Link>
                        </div>
                        <div className="lg:hidden w-1/3 flex justify-center">
                            <img src={MobileLogo} alt="" className='h-7 w-16'/>
                        </div>
                        <div className="lg:hidden w-1/3"/>
                    </div>
                    <div className='md-lg:mx-72 max-lg:mx-20 md:mx-20 sm:mx-10 max-sm:mx-5 sm:mt-16 md:mt-16 max-sm:mt-16'>
                        <div>
                            <BusinessDetails
                                business_type="sign_up"
                                // onHandleChange={handleOnChange}
                                // data={data}
                                // onFileUpload={handleFileChange}
                                // errorOnFile={data.url_err}
                                // img_url={data.image_url}
                                // cat_val={data.category}
                                // state_val={data.state}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUpBusinessDetails;