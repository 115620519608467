import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface LoginState {
  errors: LoginErrors | undefined
  email: string,
  password: string,
}

export interface LoginErrors {
  email?: Array<string> | string | undefined
  password?: Array<string> | string | undefined
}

export interface OnLoginFormChangeType {
  key: LoginKeys
  value: any
}

export type LoginKeys = 'email' | 'password';

const initialState: LoginState = {
  errors: undefined,
  email: '',
  password: '',
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    onLoginError: (state, action: PayloadAction<LoginErrors>) => {
      state.errors = action.payload;
    },
    onLoginFormChange: (state, action: PayloadAction<OnLoginFormChangeType>) => {
      state[action.payload.key] = action.payload.value;
      const errors = { ...state.errors };

      delete errors[action.payload.key];
      
      state.errors = errors;
    }
  },
});

export const { onLoginError, onLoginFormChange } = loginSlice.actions;

export default loginSlice;
