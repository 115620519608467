import React from 'react';

import product_img from '../../assets/images/samples/product_img.png';
import { classNames } from '../../helpers/styles';
import Button from '../Button';
import './index.scss';
import Box from '../Skeleton/Box';
import { TYPE_PRODUCT_SERVICE, TYPE_PROMO_CODE } from '../../constants/business';
import { computeDiscount } from '../../helpers/product';
import { useNavigate } from 'react-router-dom';
import { Product } from '../../store/business/types';

const ProductCard = ({
  show_add_to_cart = true,
  show_percent_discount = false,
  title,
  image,
  business_name,
  business_url,
  short_description,
  price,
  percent_discount,
  original_price,
  width = 'w-full max-w-250 min-w-150',
  loading = false,
  ...props
}: ProductCardProps) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (!props.business_id || !props.id) return;

    navigate(`/app/business/${props.business_id}/product/${props.id}`);
  }

  if (!!loading) {
    return (
      <div className={classNames('product-card w-full max-w-450 min-w-150 relative hover:cursor-pointer', width)}>
        <div className='w-full rounded-t-md overflow-hidden h-[150px] bg-grey flex justify-center items-center'>
          <Box className="w-full h-full rounded-b-none" />
        </div>
        <div className='p-2 border border-gray rounded-b-md'>
          <Box className="rounded-md h-8 w-24" />
          <div className='flex flex-row items-center pt-1'>
            <Box className="rounded-md h-4 w-24" />
          </div>
          <div className='text-sm text-left pt-2 pb-3'>
            <Box className="rounded-md h-4 w-24" />
          </div>
          <Box className="rounded-md h-10 w-full" />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('product-card w-full max-w-450 min-w-150 relative hover:cursor-pointer', width)} onClick={handleOnClick}>
      {
        props.type === TYPE_PROMO_CODE ? <PromoCodeDisplay description={props.discount_description || ''} percentage={props.discount_percentage || 0} bg_color={props.color || ''} /> : <ProductDisplay image={image || product_img} />
      }
      <div className='p-2 border border-gray rounded-b-md'>
        <h5 className="product-title text-h5 text-start pt-1 overflow-hidden text-ellipsis">{title || 'Product Name'}</h5>
        <div className='flex flex-row items-center pt-1'>
          <span className='text-sm text-dark-blue'>{business_name || 'Coffee Place'}</span>
        </div>
        <div className='text-sm text-left pt-2 pb-3'>
          <p>{short_description || 'Short Description'}</p>
        </div>
        <div className='flex flex-row items-center'>
          <div className='text-lg font-bold'>${price || 0}</div>
          <div className='border-gray border-l h-4 mx-3'/>
          {
            props.type === TYPE_PRODUCT_SERVICE &&
              (
                !show_percent_discount ? 
                  <div className='text-md text-red line-through'>${(original_price || 0) - (price || 0)}</div>
                  :
                  <div className='flex-1 text-md text-red'>{computeDiscount(original_price || 0, price || 0)}% OFF</div>
              )
          }
        </div>
        {
          !!show_add_to_cart && <div className='pt-3'>
            <Button label='ADD TO CART' width='w-full' outlined/>
          </div>
        }
      </div>
      {props.children}
    </div>
  );
}

const ProductDisplay = ({ image }: { image: string | undefined }) => {
  return (
    <div className='w-full rounded-t-md overflow-hidden h-[150px] bg-grey flex justify-center items-center'>
      { image ?
        <img src={image?.toString() || product_img} className="product-img w-full h-full object-cover" />
        : <span className='text-sm text-grey2'>No Image</span>
      }
    </div>
  );
}

const PromoCodeDisplay = ({ description, percentage, bg_color, }: { description: string, percentage: number, bg_color: string } ) => {
  return (
    <div className={`w-full rounded-t-md overflow-hidden h-[150px] flex justify-center items-center flex flex-col`} style={{
      backgroundColor: bg_color,
    }}>
      <h1 className='text-white'>{percentage || 0}%</h1>
      <h6 className='text-white'>{description}</h6>
    </div>
  );
}


interface ProductCardProps  {
  title?: string
  image?: string
  id?: string
  business_id?: number
  business_name?: string
  business_url?: string
  short_description?: string
  price?: number
  original_price?: number
  percent_discount?: number
  show_add_to_cart?: boolean
  show_percent_discount?: boolean
  width?: string
  loading?: boolean
  type?: string
  discount_description?: string
  color?: string
  discount_percentage?: number
  children?: any
  data?: Product
}

export default ProductCard;