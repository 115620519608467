import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const BreadCrumb = () => {
  return (
    <div className="flex items-center text-sm">
      <Link to="#" className="text-sm px-2 py-5">Parent Category</Link>
      <ChevronRightIcon width={16} height={16} />
      <Link to="#" className="text-sm px-2 py-5">Category Name</Link>
    </div>
  );
}

export default BreadCrumb;