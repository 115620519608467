import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CartState } from './types';
import { Product } from '../business/types';

export const NOTIFY = {
  ADD: 1,
  UPDATE: 2,
  REMOVE: 3,
};

const initialState: CartState = {
  list: [],
  notify: false,
  type: 0,
  message: '',
}

const cartSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<{
      item: Product,
      quantity: number,
    }>) => {
      let index = -1;
      const item_exists = state.list.some((item, i) => {
        if (item.item.id === action.payload.item.id) {
          index = i;
          return true;
        }
        return false;
      });
      if (item_exists) {
        state.list[index].quantity = action.payload.quantity;
      } else {
        state.list.push(action.payload);
      }
    },
    update: (state, action: PayloadAction<{
      index: number,
      quantity: number,
    }>) => {
      state.list[action.payload.index].quantity = action.payload.quantity;
    },
    remove: (state, action: PayloadAction<{
      index: number,
    }>) => {
      if (action.payload.index >= 0) state.list = state.list.filter((d, i) => i !== action.payload.index);
    },
    clear: (state) => {
      state.list = [];
    },
    setNotify: (state, action: PayloadAction<{
      notify: boolean,
      type: number,
      message: string,
    }>) => {
      state.notify = action.payload.notify;
      state.type = action.payload.type;
      state.message = action.payload.message;
    }
  },
});

export const { add, update, remove, clear, setNotify } = cartSlice.actions;

export default cartSlice;
