export const UNCLAIMED = "UNCLAIMED";
export const CLAIMED = "CLAIMED";
export const EXPIRED = "EXPIRED";

export enum PURCHASE_STATUS {
  UNCLAIMED,
  CLAIMED,
  EXPIRED
}

export const ORDER_STATUS = {
  PARTIALLY_FULFILLED: 'partially_fulfilled',
  FULFILLED: 'fulfilled',
  UNFULFILLED: 'unfulfilled',
}