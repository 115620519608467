import Button from "../../../../components/Button";
import Select from "../../../../components/Select";
import product_img from '../../../../assets/images/samples/product_img.png';
import { Link, useNavigate } from "react-router-dom";
import { CLAIMED, EXPIRED, PURCHASE_STATUS, UNCLAIMED } from "../../../../constants/purchase_history";
import Pagination from "../../../../components/Pagination";
import Chip from "../../../../components/Chip/Chip";
import { PurchaseStatusChip } from "../../../../components/Chip";
import { useOrdersQuery } from "../../../../services/cart";
import { useState } from "react";
import moment from 'moment';

const Dashboard = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data } = useOrdersQuery({ page });
  const list = data?.data || [];
  const { links, last_page, per_page, current_page } = data?.meta || {};
  const handleOnClaim = () => {
    navigate('/app/claim/123');
  }

  console.log('ph ', data);
  console.log('ph  1', links, last_page, per_page, current_page);

  return (
    <div className="container m-auto mt-10 rounded-sm border border-grey3 bg-white py-10 px-11">
      <h4>Purchase History</h4>
      <div className="flex justify-between items-center pt-4">
        <div className="flex flex-wrap gap-2">
          <Chip text="All" selected onClick={() => { }} />
          <Chip text="ValuMenu" onClick={() => { }} />
          <Chip text="Promo Codes" onClick={() => { }} />
          <Chip text="Unclaimed" onClick={() => { }} />
          <Chip text="Claimed" onClick={() => { }} />
          <Chip text="Expired" onClick={() => { }} />
        </div>
        <div className="flex">
          <div className="pr-2">
            <Select
              placeholder='By Date'
              onChange={() => { }}
              options={[
                {
                  label: 'option 1',
                  value: 1
                },
                {
                  label: 'option 2',
                  value: 2
                },
                {
                  label: 'option 3',
                  value: 3
                },
                {
                  label: 'option 4',
                  value: 4
                },
              ]}
              value={undefined}
            />
          </div>
        </div>
      </div>
      <div className="flex pt-4">
        <div className="grid grid-cols-8 w-full gap-1 items-center border-t border-border-default pt-3 pb-5 font-bold text-xs text-dark-grey px-5">
          <div className="col-span-2">
            PRODUCT
          </div>
          <div className="col-span-2">
            PURCHASED / EXPIRATION
          </div>
          <div className="">
            STATUS
          </div>
          <div className="">
            COST
          </div>
          <div className="col-span-2">
            ACTION
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3 pb-5">
        {list.map((d: any) => {
          return (<Item data={d} status={PURCHASE_STATUS.CLAIMED} handleOnClaim={handleOnClaim} />);
        })}
        {/* <Item status={PURCHASE_STATUS.CLAIMED} handleOnClaim={handleOnClaim} />
        <Item status={PURCHASE_STATUS.UNCLAIMED} handleOnClaim={handleOnClaim} />
        <Item status={PURCHASE_STATUS.EXPIRED} handleOnClaim={handleOnClaim} />
        <Item status={PURCHASE_STATUS.CLAIMED} handleOnClaim={handleOnClaim} />
        <Item status={PURCHASE_STATUS.UNCLAIMED} handleOnClaim={handleOnClaim} />
        <Item status={PURCHASE_STATUS.EXPIRED} handleOnClaim={handleOnClaim} />
        <Item status={PURCHASE_STATUS.CLAIMED} handleOnClaim={handleOnClaim} />
        <Item status={PURCHASE_STATUS.UNCLAIMED} handleOnClaim={handleOnClaim} />
        <Item status={PURCHASE_STATUS.EXPIRED} handleOnClaim={handleOnClaim} /> */}
      </div>
      <Pagination
        data={{ links, last_page, per_page, current_page }}
        onPageChange={setPage}
        onPerPageChange={per_page => { }}
      />
    </div>
  );
}

const Item = ({ data, ...props }: { status: PURCHASE_STATUS, handleOnClaim: () => void, data: any }) => {
  const placholder_ims = product_img;
  const business = data?.item?.business || {};
  return (
    <div className="grid grid-cols-8 w-full gap-1 items-center border rounded-md border-border-default p-5">
      <div className="col-span-2">
        <div className="flex items-center">
          <img src={data?.item?.image || product_img} className="w-20 h-20 object-cover rounded-md" />
          <div className="flex flex-col pl-5">
            <span className="text-sm">#{data?.tracking}</span>
            <h6 className="text-black">{data?.item?.name}</h6>
            <Link to={`/app/business/${business?.id}`} className="text-sm text-dark-blue">{business?.name || ''}</Link>
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <div className="flex items-center">
          <div className="flex flex-col">
            <span className="text-sm">Purchased: {moment(data?.created_at).format("MMM DD, YYYY")}</span>
            <span className="text-sm text-red">Valid until: {moment(data?.created_at).add(data?.item?.expiration_days, 'days').format("MMM DD, YYYY")}</span>
          </div>
        </div>
      </div>
      <div className="">
        <PurchaseStatusChip status={props.status} />
      </div>
      <div className="">
        ${data?.item?.price}
      </div>
      <div className="col-span-2">
        <div className="flex items-center gap-1">
          <Button text="CLAIM" text_color="text-white font-bold text-xs" onClick={props.handleOnClaim} disabled={!(PURCHASE_STATUS.UNCLAIMED === props.status)} />
          <Button outlined text="BUY AGAIN" text_color="text-dark-blue font-bold" text_size="text-xs" />
        </div>
      </div>
    </div>
  );
}

// const StatusChip = (props: any) => {
//   let text = "UNCLAIMED";
//   let bg = "bg-sky-blue";
//   switch (props.status) {
//     case PURCHASE_STATUS.CLAIMED:
//       text = "CLAIMED";
//       bg = "bg-primary-dark";
//       break;
//     case PURCHASE_STATUS.UNCLAIMED:
//       text = "UNCLAIMED";
//       bg = "bg-sky-blue";
//       break;
//     case PURCHASE_STATUS.EXPIRED:
//       text = "EXPIRED";
//       bg = "bg-red";
//       break;
//   }

//   return (
//     <Button
//       className={`${bg} px-1 h-8`}
//       bg_color="hover:opacity-100"
//       border_radius="rounded-md"
//       width="min-w-[100px]"
//       text_color="text-white font-bold text-xs"
//       text={text}
//     />
//   );
// }

export default Dashboard;