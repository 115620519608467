import Select from "../../../../components/Select";
import { useNavigate } from "react-router-dom";
import { ORDER_STATUS } from "../../../../constants/purchase_history";
import { OrderStatusChip } from "../../../../components/Chip";

const Orders = () => {
  const navigate = useNavigate();

  const handleOnClaim = () => {
    navigate('/app/claim/123');
  }

  const handleOrderItemClick = () => {
    navigate('/app/order-details');
  }

  return (
    <div className="container m-auto rounded-sm py-10 px-11">
      <div className="flex justify-between">
        <h4>Orders (10)</h4>
        <div className="pr-2">
          <Select
            placeholder='Sort By'
            onChange={() => { }}
            options={[
              {
                label: 'option 1',
                value: 1
              },
              {
                label: 'option 2',
                value: 2
              },
              {
                label: 'option 3',
                value: 3
              },
              {
                label: 'option 4',
                value: 4
              },
            ]}
            value={undefined}
          />
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex" />
        <div className="flex">

        </div>
      </div>
      <div className="flex pt-4">
        <div className="grid grid-cols-7 w-full gap-1 items-center border-t border-border-default pt-3 pb-5 font-bold text-xs text-dark-grey px-5">
          <div className="col-span-2">
            ORDER #
          </div>
          <div className="col-span-1">
            DATE
          </div>
          <div className="col-span-1">
            STATUS
          </div>
          <div className="col-span-1">
            CUSTOMER
          </div>
          <div className="col-span-1">
            ITEMS
          </div>
          <div className="col-span-1">
            TOTAL
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3 pb-5">
        <Item status={ORDER_STATUS.FULFILLED} handleOnClaim={handleOnClaim} handleOnClick={handleOrderItemClick} />
        <Item status={ORDER_STATUS.PARTIALLY_FULFILLED} handleOnClaim={handleOnClaim} handleOnClick={handleOrderItemClick} />
        <Item status={ORDER_STATUS.UNFULFILLED} handleOnClaim={handleOnClaim} handleOnClick={handleOrderItemClick} />
        <Item status={ORDER_STATUS.FULFILLED} handleOnClaim={handleOnClaim} handleOnClick={handleOrderItemClick} />
        <Item status={ORDER_STATUS.PARTIALLY_FULFILLED} handleOnClaim={handleOnClaim} handleOnClick={handleOrderItemClick} />
        <Item status={ORDER_STATUS.UNFULFILLED} handleOnClaim={handleOnClaim} handleOnClick={handleOrderItemClick} />
        <Item status={ORDER_STATUS.FULFILLED} handleOnClaim={handleOnClaim} handleOnClick={handleOrderItemClick} />
        <Item status={ORDER_STATUS.PARTIALLY_FULFILLED} handleOnClaim={handleOnClaim} handleOnClick={handleOrderItemClick} />
        <Item status={ORDER_STATUS.UNFULFILLED} handleOnClaim={handleOnClaim} handleOnClick={handleOrderItemClick} />
      </div>
      {/* <Pagination /> */}
    </div>
  );
}

const Item = (props: { status: string, handleOnClaim: () => void, handleOnClick: () => void }) => {
  return (
    <div className="grid grid-cols-7 w-full gap-1 items-center border rounded-md border-border-default p-5 cursor-pointer" onClick={props.handleOnClick}>
      <div className="col-span-2 text-sm">
        VM0000005
      </div>
      <div className="col-span-1 text-sm">
        May 18, 12:00 PM
      </div>
      <div className="col-span-1 text-sm">
        <OrderStatusChip status={props.status} />
      </div>
      <div className="col-span-1 text-sm">
        Peter Klaus
      </div>
      <div className="col-span-1 text-sm">
        2 items
      </div>
      <div className="col-span-1 text-sm">
        $25.00
      </div>
    </div>
  );
}

// const StatusChip = (props: any) => {
//   let text = "";
//   let bg = "";
//   switch (props.status) {
//     case ORDER_STATUS.FULFILLED:
//       text = "Fulfilled";
//       bg = "bg-[#86AD16]";
//       break;
//     case ORDER_STATUS.PARTIALLY_FULFILLED:
//       text = "Partially Fulfilled";
//       bg = "bg-[#01539B]";
//       break;
//     case ORDER_STATUS.UNFULFILLED:
//       text = "Unfulfilled";
//       bg = "bg-[#787878]";
//       break;
//   }

//   return (
//     <Button
//       className={`${bg} px-1 h-8`}
//       bg_color="hover:opacity-100"
//       border_radius="rounded-md"
//       width="min-w-auto"
//       text_color="text-white font-bold text-xs"
//       text={text}
//     />
//   );
// }

export default Orders;