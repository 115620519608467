import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import Cookies from 'js-cookie';
import { RootState, store } from '../store/store';
import { logout } from '../store/auth/slice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders(headers, { getState }) {
    const token = (getState() as RootState).auth.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    if (!headers.get('Accept')) {
      headers.set("Accept", 'application/json');
    }

    return headers;
  },
});

export const baseQueryWithCSRF: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {

  let xsrf_token = Cookies.get('XSRF-TOKEN');

  if (!xsrf_token) {
    await baseQuery({
      url: '/sanctum/csrf-cookie',
      credentials: 'include'
    }, api, {});
  }

  xsrf_token = Cookies.get('XSRF-TOKEN');

  // add xsrf header
  const headers = { ...(args as FetchArgs).headers, 'X-CSRF-Token': xsrf_token };

  const result = await baseQuery({ ...(args as FetchArgs), headers }, api, extraOptions);
  if (result.error?.status === 401) {
    store.dispatch(logout());
  }
  return result
}