import price_tag from '../../assets/images/icons/price_tag.svg';

const NewOrders = () => {
  return (
    <div className='inline-block'>
      <div id="NewOrdersNotification" className="flex items-center rounded-full bg-pale-green bg-opacity-20 px-2 py-1">
        <div className="inline-block relative notify pl-1">
          <img src={price_tag} className="w-5" alt="New Orders" />
        </div>
        <div className="text-sm px-2"><span className="font-bold">7</span><span> New Orders</span></div>
      </div>
    </div>
  );
}

export default NewOrders;