import { Menu, Transition } from "@headlessui/react";
import { StarIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { Link, Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";
import Select from "../../../../components/Select";
import MessageNotification from "../../../BusinessDetails/components/MessageNotification";

import business_img1 from '../../../../assets/images/samples/business_img1.png';
import price_tag from '../../../../assets/images/icons/price_tag.svg';
import percent from '../../../../assets/images/icons/percent.svg';
import NewOrders from "../../../../components/NewOrders";
import { PRODUCT_SERVICE, PROMO_CODE } from "../../../../constants/business";
import ProductCard from "../../../../components/ProductCard";
import BusinessItem from "../BusinessItem";
import { useListQuery } from "../../../../services/business";
import { useAppSelector } from "../../../../store/hooks";
import ReportItem from "../../../../components/ReportItem";
import MessageTab from "../Messages/MessageTab";
import { Sidebar } from "../Messages/Sidebar";

import {
  Channel,
  ChannelHeader,
  ChannelList,
  Chat,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  Window,
  SearchBar,
  InfiniteScroll,
} from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";
// import "./layout.css";


import './messages_tab.scss';
import MessageBody from "../Messages/MessageBody";
import { useCommunicationUserTokenQuery } from "../../../../services/auth";
import { useClient } from "../../../../hooks/useClient";
import { StreamChat } from "stream-chat";
import ClientChat from "../Messages/ClientChat";

const Messages = (props: any) => {
  const navigate = useNavigate();
  const user = useAppSelector(store => store.auth.user);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const business_id = queryParams.get('bid');
  // const user_comm_token_data = useCommunicationUserTokenQuery(user?.id || 0);
  const user_businesses = useListQuery({ user_id: user?.id });
  const user_businesses_data = user_businesses.data?.data || [];
  const [current_tab, setCurrentTab] = useState(business_id || '');

  return (
    <div id="MessagesTab" className="container m-auto rounded-sm bg-white py-10 px-11 flex-col">
      <div className="flex">
        <div className="flex">
          <h4>Messages</h4>
        </div>
      </div>
      {
        !!(user_businesses_data || []).length && 
          <div className="w-full py-5 flex flex-row flex-nowrap overflow-auto gap-2">
            <MessageTab
              key={user?.id}
              active={false}
              count={0}
              is_user={true}
              logo={business_img1}
              name={user?.name || ''}
              desc={user?.email || ''}
              onClick={() => {
                navigate(`/app/profile/messages`);
                setCurrentTab('');
              }}
            />
            {(user_businesses_data || []).map((b, i) => {
              return (
                <MessageTab
                  key={b.id}
                  active={false}
                  count={0}
                  is_user={false}
                  logo={b.logo}
                  name={b.name}
                  desc={b.website_url}
                  onClick={() => {
                    navigate(`/app/profile/messages?bid=${b.id}`);
                    setCurrentTab(b.id + '');
                  }}
                />
              );
            })}
          </div>
      }
      <ClientChat current_tab={current_tab} />
    </div>
  );

  // return (
  //   <div id="MessagesTab" className="container m-auto rounded-sm bg-white py-10 px-11">
  //     <div className="flex">
  //       <div className="flex">
  //         <h4>Messages</h4>
  //       </div>
  //     </div>
  //     <div className="w-full pt-5 grid grid-flow-row grid-cols-5 gap-2">
  //       {/* <MessageTab active={true} count={1} />
  //       <MessageTab active={false} count={0} />
  //       <MessageTab active={false} count={0} /> */}
  //     </div>
  //     <div className="pt-5 grid grid-flow-row grid-cols-7 gap-2">
  //       <div className="col-span-2 border rounded-l-xl">
  //         <Sidebar />
  //       </div>
  //       <div className="col-span-5 border rounded-r-xl">
  //         <MessageBody />
  //       </div>
  //     </div>
  //     <div>
  //     </div>
  //   </div>
  // );
}

export default Messages;