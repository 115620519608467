import { StreamChat } from 'stream-chat';
import business_img1 from '../../../../assets/images/samples/business_img1.png';
import { classNames } from '../../../../helpers/styles';
import { Business } from '../../../../store/business/types';
import { AuthUser } from '../../../../store/auth/types';

const MessageTab = ({
  active = false,
  count = 0,
  is_user = false,
  logo,
  name,
  desc,
  onClick,
}:
{
  active: boolean,
  count: number,
  is_user: boolean,
  logo: string,
  name: string,
  desc: string,
  onClick: any
}) => {

  return (
    <div className={classNames("border border-hairline px-3 py-2 rounded-md relative cursor-pointer snap-end", active && 'border-blue')} onClick={onClick}>
      { !!count && <Badge count={count} /> }
      <div className='flex flex-1 flex-row items-center w-[200px]'>
        <div className='border border-hairline h-10 w-10 rounded-full overflow-hidden'>
          <img src={logo} className='h-full w-full object-contain rounded-full' />
        </div>
        <div className='flex flex-col pl-3'>
          <div className='text-sm font-bold'>{name}</div>
          <div className='text-xs text-dark-grey'>{desc}</div>
        </div>
      </div>
    </div>
  );
}

const Badge = ({ count }: { count: number }) => {
  return (
    <div className='rounded-full h-6 w-6 bg-red absolute -top-2 -right-1 flex justify-center items-center text-xs text-white font-bold'>
      {count}
    </div>
  );
}

export default MessageTab;