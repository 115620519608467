
import { useState } from "react";
import business_img1 from '../../../../assets/images/samples/business_img1.png';
import Button from "../../../../components/Button";
import Select from "../../../../components/Select";
import TextInput from "../../../../components/TextInput";
import { TicketIcon } from "@heroicons/react/20/solid";
import { useAppSelector } from "../../../../store/hooks";
import { formatNameForImg } from "../../../../helpers/utils";
const Settings = () => {
  const user = useAppSelector(store => store.auth.user);
  const [form, setForm] = useState({
    name: user?.name,
    email: user?.email,
  });
  const [payment_method, setPaymentMethod] = useState(undefined);
  const user_name = formatNameForImg(user?.name || '');

  return (
    <div className="container m-auto mt-10 rounded-sm border border-grey3 bg-white py-10 px-11">
      <h4>Account Details</h4>
      <div className="flex flex-row pt-11">
        <div className='border border-grey rounded-full w-24 h-24 overflow-hidden'>
          <img src={`https://getstream.io/random_svg/?name=${user_name}`} alt="business" className="w-full h-full object-cover rounded-full" />
        </div>
        <div className="flex flex-col items-start px-7">
          <Button outlined label="UPDATE" width="w-150" />
          <button className="mt-5 text-sm text-red">
            Remove
          </button>
        </div>
      </div>
      <div className="w-full md:w-2/3 pt-6">
        <div className="grid grid-flow-row grid-cols-2 gap-3">
          <TextInput width="w-full" placeholder="Name" value={form.name} />
          <TextInput width="w-full" placeholder="Username" />
        </div>
        <TextInput width="w-full" placeholder="Email"  value={form.email}/>
      </div>
      <div className="pt-12">
        <h4>Change Password</h4>
        <div className="w-full md:w-2/3 pt-6">
          <TextInput type="password" width="w-full" placeholder="Current Password" /> 
          <TextInput type="password" width="w-full" placeholder="New Password" /> 
          <TextInput type="password" width="w-full" placeholder="Confirm Password" /> 
        </div>
      </div>
      <div className="pt-12">
        <h4>Payout Details</h4>
        <div className="text-sm pt-3">
          Only available for users with business
        </div>
        <div className="w-full md:w-2/3 pt-6">   
          <Select
            placeholder='Payment Method'
            onChange={setPaymentMethod}
            options={[
              {
                label: 'option 1',
                value: 1,
                icon: <TicketIcon />
              },
              {
                label: 'option 2',
                value: 2,
                icon: <TicketIcon />
              },
              {
                label: 'option 3',
                value: 3,
                icon: <TicketIcon />
              },
              {
                label: 'option 4',
                value: 4,
                icon: <TicketIcon />
              },
            ]}
            value={payment_method}
            width="w-full"
          />
          <TextInput width="w-full" placeholder="Email" />
        </div>
      </div>
      <div className="pt-12">
        <h4>Account</h4>
        <div className="pt-5 flex flex-row items-center">
          <Button outlined outline_color="red" label="DELETE ACCOUNT" />
          <div className="text-sm italic pl-4">*this can’t be undone</div>
        </div>
      </div>
      <div className="pt-12">
        <h4>Payment Methods</h4>
        <div className="w-full md:w-2/3 pt-6">
          <div className="text-sm">
            You don't have any saved payment methods yet.
          </div>
          <div className="text-sm pt-5">
            You can save a payment method to your account by clicking 'Remember this card' when placing your next order.
          </div>
          <div className="pt-7">
            <Button label="SAVE CHANGES" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;