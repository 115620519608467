import { useState } from 'react'
import { Switch } from '@headlessui/react'

function Toggle({ sr, checked, onChange, ...props }: ToggleProps) {

  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={`${
        checked ? 'bg-green' : 'bg-gray-200'
      } relative inline-flex h-7 w-14 items-center rounded-full`}
    >
      <span className="sr-only">{sr}</span>
      <span
        className={`${
          checked ? 'translate-x-7' : 'translate-x-1'
        } inline-block h-6 w-6 transform rounded-full bg-white transition`}
      />
    </Switch>
  )
}

interface ToggleProps {
  sr?: string
  checked?: boolean
  onChange?(value: boolean): void;

}

export default Toggle;
