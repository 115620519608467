import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { router_paths } from '../../../routes';


const AdminTabs = (props: any) => {
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const location = useLocation();
    const url_params = useParams();
    const tab_list = [
        {
            label: "Dashboard",
            path: router_paths.ADMIN_DASHBOARD,
        },
        {
            label: "Users",
            path: router_paths.ADMIN_USERS,
        },
        // {
        //     label: "Transactions",
        //     path: router_paths.ADMIN_TRANSACTIONS,
        // },
        {
            label: "Business",
            path: router_paths.ADMIN_BUSINESSES,
        },
        {
            label: "Categories",
            path: router_paths.ADMIN_CATEGORIES,
        },
    ];

    useEffect(() => {
        const tab_index = tab_list.findIndex(tl => tl.path === location.pathname) || 0;

        setTabIndex(tab_index);
    }, [url_params]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleTabSelect = (index: number) => {
        setTabIndex(index);
        navigate(tab_list[index].path);
    }

    return (
        <>
            <Tabs selectedIndex={tabIndex} onSelect={(i) => navigate(tab_list[i].path)}>
                <div className='container m-auto pt-3'>
                    <TabList>
                        {tab_list.map(t => <Tab>{t.label}</Tab>)}
                    </TabList>
                </div>
                <TabPanel />
                <TabPanel />
                <TabPanel />
                <TabPanel />
                <TabPanel />
                {props.children}
            </Tabs>
        </>
    );
}

export default AdminTabs;