import Button from "../Button";

const Chip = (props: ChipProps) => {
  let className = "border border-border-default bg-white px-8";
  if (props.selected) {
    className = "bg-sky-blue px-8";
  }
  return (
    <Button
      className={className}
      bg_color="hover:opacity-80 active:opacity-50"
      border_radius="rounded-full"
      width="w-auto"
      text_color={props.selected ? "text-white font-bold" : "text-black font-normal"}
      text={props.text}
      onClick={props.onClick}
    />
  );
}

interface ChipProps {
  selected?: boolean
  text: string
  onClick?: () => void
}

export default Chip;