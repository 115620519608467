import React from 'react';
import Desktop from './Desktop';

import './styles.scss';
import { useBusinessQuery } from '../../services/business';
import { useLocation, useParams } from 'react-router-dom';

const BusinessDetails = () => {

  const params = useParams();
  const id = params.business_id;
  const { data } = useBusinessQuery(Number(id));

  return (
    <div id="BusinessDetails" className='flex justify-center h-full w-full m-auto pb-32 pt-10'>
      <Desktop data={data?.data} />
    </div>
  );
}

export default BusinessDetails;