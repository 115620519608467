import { Menu, Transition } from "@headlessui/react";
import { StarIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import MessageNotification from "../../BusinessDetails/components/MessageNotification";

import business_img1 from '../../../assets/images/samples/business_img1.png';
import price_tag from '../../../assets/images/icons/price_tag.svg';
import percent from '../../../assets/images/icons/percent.svg';
import NewOrders from "../../../components/NewOrders";
import { PRODUCT_SERVICE, PROMO_CODE } from "../../../constants/business";
import ProductCard from "../../../components/ProductCard";
import { Business } from "../../../store/business/types";
import { useProductListQuery } from "../../../services/business";
import { isNumberObject } from "util/types";

const BusinessItem = ({ data: business_data, ...props }: BusinessItemProps ) => {
  const business_id = Number(business_data.id)
  const { data } = useProductListQuery({ id: business_id });
  const product_list = data?.data || [];
  
  return (
    <div className="pt-6 pb-10 grid grid-cols-3 gap-5 border-grey [&:not(:first-child)]:border-t [&:not(:first-child)]:pt-10">
        <div className='flex flex-col w-full'>
          <div className='flex col-span-5'>
            <div className='border border-grey rounded-md w-24 h-24 overflow-hidden'>
              <img src={business_data.logo || business_img1} className="w-24 h-24 object-contain" alt="JC Cafe" />
            </div>
            <div className='pl-8'>
              <h5>{business_data.name}</h5>
              <div className='pt-1 text-sm'>{business_data.address_line_1}</div>

              <div className='pt-1 flex items-start flex-col'>
                <div className='flex justify-start items-center'>
                  <StarIcon width={14} height={14} />
                  <StarIcon width={14} height={14} />
                  <StarIcon width={14} height={14} />
                  <StarIcon width={14} height={14} />
                  <StarIcon width={14} height={14} />
                  <span className='text-sm self-end pl-3'>4.5 of 35 Reviews</span>
                </div>
              </div>
            </div>
          </div>

          <div className='pt-5 flex justify-center items-center w-full'>
                <NewOrders />
                <div className="px-2" />
                <MessageNotification />
              </div>
          <div className='pt-8 col-span-5 grid grid-flow-row grid-cols-1 gap-2 place-content-start place-items-center'>
            <div className='flex justify-evenly'>
              <Button as={Link} to={`/app/business/${business_data.id}`} label="VIEW DETAILS" width='w-150 mx-2' height="h-10" outlined className="flex justify-center items-center" />
              <Menu as="div" className="relative inline-block text-left" onChange={(e: any) => console.log(e)}>
                <div className='mx-2'>
                  <Button as={Menu.Button} label="+ ADD ITEM" width='w-150' height="h-10" />
                </div>
                <Transition
                  as={Fragment}
                  enterFrom="transform opacity-0 scale-95"
                  enter="transition ease-out duration-100"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className="absolute right-0 mt-2 w-60 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                  >
                    <div className="px-2 py-2">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/app/business/${business_id}/new?t=${PROMO_CODE}`}
                            className={`${
                              active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                            } group flex w-full items-center rounded-md px-2 py-5 text-sm`}
                          >
                            <div
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            >
                              <img src={percent} alt="Add New Promo Code" />
                            </div>
                            Add New Promo Code
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/app/business/${business_id}/new?t=${PRODUCT_SERVICE}`}
                            className={`${
                              active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                            } group flex w-full items-center rounded-md px-2 py-5 text-sm`}
                          >
                            <div
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            >
                              <img src={price_tag} alt="Add New Product or Service" />
                            </div>
                            Add New Product or Service
                          </Link>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <div className="col-span-2 grid grid-cols-4 gap-2">
          {
            product_list.filter((p, i) => i < 4).map((p, i) => <ProductCard
                key={i}
                show_percent_discount
                show_add_to_cart={false}
                title={p.name}
                image={p.image}
                business_name={business_data.name}
                business_url={business_data.website_url}
                short_description={p.short_description}
                price={p.price}
                // percent_discount={p.original_price}
              />)
          }
        </div>
      </div>
  );
}

type BusinessItemProps = {
  data: Business
}

export default BusinessItem;