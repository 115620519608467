import { PencilIcon } from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";
import ProductCard from "../../../../components/ProductCard";
import Select from "../../../../components/Select";
import pencil_icon from '../../../../assets/images/icons/pencil.svg';
import trash_can_icon from '../../../../assets/images/icons/trash_can.svg';
import { useProductListQuery } from "../../../../services/business";
import { TYPE_PROMO_CODE } from "../../../../constants/business";
export default () => {
  const params = useParams();
  const id = params.business_id;
  const { data, isLoading } = useProductListQuery({ id: Number(id), type: TYPE_PROMO_CODE })
  const product_list = data?.data || [];

  return (
    <div className="container m-auto pt-8 px-5 xl:px-0">
      <div className="flex justify-between items-center">
        <div className="flex">
          <h5>Promo Codes</h5>
          <span className="text-xl pl-1">({product_list.length})</span>
        </div>
        <div className="flex">
          <div className="pr-2">
            <Select
              placeholder='All'
              onChange={() => {}}
              options={[
                {
                  label: 'option 1',
                  value: 1
                },
                {
                  label: 'option 2',
                  value: 2
                },
                {
                  label: 'option 3',
                  value: 3
                },
                {
                  label: 'option 4',
                  value: 4
                },
              ]}
              value={undefined}
            />
          </div>
          <div className="pl-2">
            <Select
              placeholder='Sort By'
              onChange={() => {}}
              options={[
                {
                  label: 'option 1',
                  value: 1
                },
                {
                  label: 'option 2',
                  value: 2
                },
                {
                  label: 'option 3',
                  value: 3
                },
                {
                  label: 'option 4',
                  value: 4
                },
              ]}
              value={undefined}
              width="w-150"
            />
          </div>
        </div>
      </div>
      <div className="pt-3 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 place-content-center place-items-center sm:place-items-center md:place-items-center lg:place-items-start">
        {
          !!isLoading && <>
            <ProductCard
              show_add_to_cart={false}
              show_percent_discount
              title=""
              image=""
              business_name=""
              business_url=""
              short_description=""
              price={0}
              loading
            />
            <ProductCard
              show_add_to_cart={false}
              show_percent_discount
              title=""
              image=""
              business_name=""
              business_url=""
              short_description=""
              price={0}
              loading
            />
            <ProductCard
              show_add_to_cart={false}
              show_percent_discount
              title=""
              image=""
              business_name=""
              business_url=""
              short_description=""
              price={0}
              loading
            />
          </>
        }
        {
          !isLoading && product_list.map(p => (
            <ProductCard
              key={p.id}
              id={p.id}
              business_id={p.business.id}
              show_add_to_cart={false}
              show_percent_discount
              title={p.name}
              image={p.image}
              business_name={p.business.name}
              business_url={p.business.website_url}
              short_description={p.short_description}
              price={p.price}
              original_price={p.original_price}
              type={p.type}
              discount_description={p.discount_description}
              discount_percentage={p.discount_percentage}
              color={p.color}
            >
              <div className="absolute top-3 right-3 flex">
                <Link to="#">
                  <div className="h-10 w-10 rounded-full bg-black flex justify-center items-center opacity-60 hover:opacity-75">
                    <img src={pencil_icon} />
                  </div>
                </Link>
                <div className="px-1" />
                <Link to="#">
                  <div className="h-10 w-10 rounded-full bg-black flex justify-center items-center opacity-60 hover:opacity-75">
                    <img src={trash_can_icon} />
                  </div>
                </Link>
              </div>
            </ProductCard>
          ))
        }
      </div>
    </div>
  );
}