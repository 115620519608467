import { StarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";

import sample_profile from '../../../assets/images/samples/frog.jpeg';

const ReviewForm = ({ open, setOpen, onSubmit } : { open: boolean, setOpen: () => void, onSubmit: () => void }) => {
  return (
    <Modal isOpen={open} contentLabel="Example Modal" className="modal-container" onRequestClose={setOpen}>
      <div className='bg-white px-28 py-16 relative'>
        <button className='absolute top-6 right-6 w-6 h-6' onClick={setOpen}>
          <XMarkIcon />
        </button>
          <div className="">
            <h4>Write a review</h4>
            <div className="flex py-5 border-b">
              <div className='w-12 h-12 rounded-full overflow-hidden'>
                <img src={sample_profile} alt="profile" className='w-12 h-12 object-cover' />
              </div>
              <div className="pl-5">
                <h6>Kath L.</h6>
                <div className="text-sm text-dark-grey">kathleen.l@gmail.com</div>
              </div>
            </div>
            <div className="flex flex-row py-5">
              Overall Ratings:
              <div className='flex justify-start items-center pl-5'>
                <StarIcon width={25} height={25} color="#FFCA2C" />
                <StarIcon width={25} height={25} color="#FFCA2C" />
                <StarIcon width={25} height={25} color="#FFCA2C" />
                <StarIcon width={25} height={25} color="#FFCA2C" />
                <StarIcon width={25} height={25} color="#FFCA2C" />
              </div>
            </div>
            <textarea className='rectangle-copy-6 w-full resize-none py-4 px-4 border-border-default border rounded-md text-sm' placeholder='Type your review'/>
            <div className="w-full h-150 dropzone border mt-1 mb-5 rounded-md"></div>
            <Button text="SUBMIT REVIEW" width="w-150" onClick={onSubmit} />
          </div>
      </div>
    </Modal>
  );
}

export default ReviewForm;