import React from 'react';
import business_img1 from '../../assets/images/samples/business_img1.png';
import Button from '../../components/Button';
import Tabs from './components/Tabs';
import { formatNameForImg } from '../../helpers/utils';

const Desktop = (props: any) => {
  const user = props.user_data || {};
  const user_name = formatNameForImg(user?.name || '');
  return (
    <div className='w-full'>
      <div className='container m-auto'>
        <div className='grid grid-flow-row grid-cols-5 gap-2 w-full'>
          <div className='flex col-span-5 md:col-span-3 items-center'>
            <div className='border border-grey rounded-full w-24 h-24 overflow-hidden'>
              <img src={`https://getstream.io/random_svg/?name=${user_name}`} alt="business" className="w-full h-full object-cover rounded-full" />
              
            </div>
            <div className='pl-6'>
              <h4>{user.name || ''}</h4>
              <div>@username</div>
            </div>
          </div>
          <div className='col-span-5 md:col-span-2 grid grid-flow-row grid-cols-1 gap-2 place-content-start place-items-center md:place-items-end'>
            <div className='flex justify-evenly items-center'>
              {/* <div>
                <h5>$750.84</h5>
                <span className='text-sm text-dark-grey'>Future Payout</span>
              </div> */}
              {/* <div className=" h-8 border-r border-grey mx-5" /> */}
              <Button label="+ Add BUSINESS" width='w-150 mx-2' bg_color='bg-green' onClick={props.handleAddBusinessOnClick} />
            </div>
          </div>
        </div>
      </div>

      <div className='w-full pt-5'>
        <Tabs />
      </div>
    </div>
  );
}

export default Desktop;