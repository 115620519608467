import { Menu, Transition } from "@headlessui/react";
import { StarIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "../../../../components/Button";
import Select from "../../../../components/Select";
import MessageNotification from "../../../BusinessDetails/components/MessageNotification";

import business_img1 from '../../../../assets/images/samples/business_img1.png';
import price_tag from '../../../../assets/images/icons/price_tag.svg';
import percent from '../../../../assets/images/icons/percent.svg';
import NewOrders from "../../../../components/NewOrders";
import { PRODUCT_SERVICE, PROMO_CODE } from "../../../../constants/business";
import ProductCard from "../../../../components/ProductCard";
import BusinessItem from "../BusinessItem";
import { useBusinessQuery, useListQuery } from "../../../../services/business";
import { useAppSelector } from "../../../../store/hooks";
import ReportItem from "../../../../components/ReportItem";
import MessageTab from "./MessageTab";
import { Sidebar } from "./Sidebar";

import {
  Channel,
  ChannelHeader,
  ChannelList,
  Chat,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  Window,
  SearchBar,
  InfiniteScroll,
  useChatContext,
} from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";
// import "./layout.css";


import '../Tabs/messages_tab.scss';
import MessageBody from "./MessageBody";
import { useCommunicationBusinessTokenQuery, useCommunicationUserTokenQuery } from "../../../../services/auth";
import { useClient } from "../../../../hooks/useClient";
import { StreamChat } from "stream-chat";
import InnerChat from "./InnerChat";
import { formatNameForImg } from "../../../../helpers/utils";

const ClientChat = (props: any) => {
  const user = useAppSelector(store => store.auth.user);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const business_id = +(queryParams.get('bid') || 0);


  
  const { data: business_data } = useBusinessQuery(+(business_id), { skip: !business_id });
  const { data: business_comm, isSuccess: businessIsSuccess } = useCommunicationBusinessTokenQuery(+(business_id || 0), { skip: !business_id });
  const { data: user_comm_token_data, isSuccess: userIsSuccess } = useCommunicationUserTokenQuery(user?.id || 0);
  const user_comm_token = user_comm_token_data?.data;
  const [client_data, setClientData] = useState(
    {
      tokenOrProvider: '',
      userData: {
        id: '',
        name: '',
        image: '',
      }
    }
  );

  const filters = {
    members: { $in: [!!business_id ? business_comm?.data.stream_id || '' : user_comm_token?.stream_id || ''] }  
  };

  //a  hook for connecting chat server
  const chat_client = useClient(client_data);

  useEffect(() => {
    initChatClientData(business_id);
  }, []);

  useEffect(() => {
    initChatClientData(business_id);
  }, [props.current_tab, business_id, user_comm_token, business_comm]);

  const initChatClientData = (bid?: number) => {
    if (!!bid && businessIsSuccess) { // has business id so we use business chat client
      setClientData({
        tokenOrProvider: business_comm?.data.token,
        userData: {
          id: business_comm?.data.stream_id,
          name: business_data?.data.name || '',
          image: business_data?.data.logo || `https://getstream.io/random_svg/?name=${formatNameForImg(business_data?.data.name || '')}`
        }
      });
    } else if (userIsSuccess) {
      setClientData({
        tokenOrProvider: user_comm_token?.token || '',
        userData: {
          id: user_comm_token?.stream_id || '',
          name: user?.name || '',
          image: `https://getstream.io/random_svg/?name=${formatNameForImg(user?.name || '')}`
        }
      });
    }
  }

  if (!chat_client) {
    return (
      <div className="border rounded-md bg-white py-1 h-[600px] flex items-center justify-center">
        <LoadingIndicator size={32} />
      </div>
    );
  }


  return (
    <div className="border rounded-md bg-white flex py-1 h-[600px]">
      <Chat client={chat_client} theme='str-chat__theme-light'>
        <InnerChat
          filters={{
            members: { $in: [!!business_id ? business_comm?.data.stream_id || '' : user_comm_token?.stream_id || ''] }  
          }}
          my_stream_id={user_comm_token?.stream_id || ''}
        />
      </Chat>
    </div>
  );
}

export default ClientChat;