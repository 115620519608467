const Pagination = (
  {
    data,
    onPageChange,
    onPerPageChange,
    ...props
  }: PaginationProps) => {
  const { links = [], last_page, per_page, current_page } = data;

  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  const handlePerPageChange = (e: any) => {
    const { value } = e.target;

    onPerPageChange(value);
  };

  const handlePrevOnClick = () => {
    onPageChange(current_page - 1);
  };

  const handleNextOnClick = () => {
    onPageChange(current_page + 1);
  };

  const handleLastPageOnClick = () => {
    onPageChange(last_page);
  };

  const handleFirstPageOnClick = () => {
    onPageChange(1);
  };

  return (
    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm gap-2" aria-label="Pagination">
      <a
        href="#"
        className="relative inline-flex items-center rounded-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        onClick={(e) => {
          e.preventDefault();
          handlePrevOnClick();
        }}
      >
        <span className="sr-only">Previous</span>
        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
        </svg>
      </a>
      {/* <!-- Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" --> */}
      {links
        .filter((l) => !!Number(l.label))
        .map((l) => (
          <a
            href="#"
            aria-current="page"
            className="relative z-10 inline-flex rounded-md items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(Number(l.label))
            }}
          >{l.label}</a>
        ))}
      {/* <a href="#" aria-current="page" className="relative z-10 inline-flex rounded-md items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">1</a>
      <a href="#" className="relative inline-flex rounded-md items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">2</a>
      <a href="#" className="relative hidden rounded-md items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">3</a>
      <span className="relative inline-flex rounded-md items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
      <a href="#" className="relative hidden rounded-md items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">8</a>
      <a href="#" className="relative inline-flex rounded-md items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">9</a>
      <a href="#" className="relative inline-flex rounded-md items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">10</a> */}
      <a
        href="#"
        className="relative inline-flex rounded-md items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        onClick={(e) => {
          e.preventDefault();
          handleNextOnClick();
        }}
      >
        <span className="sr-only">Next</span>
        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
        </svg>
      </a>
    </nav>
  );
}

interface PaginationProps {
  data: {
    links: Array<any>
    last_page: number
    per_page: number
    current_page: number
  },
  onPageChange: (page: number) => void,
  onPerPageChange: (per_page: number) => void,
}

export default Pagination;