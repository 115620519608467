import React from 'react';
import Desktop from './Desktop';

import './styles.scss';

const V2BusinessDetails = () => {
  return (
    <div id="V2BusinessDetails" className='flex justify-center h-full w-full m-auto pb-32'>
      <Desktop />
    </div>
  );
}

export default V2BusinessDetails;