import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthState, AuthUser } from './types';

const initialState: AuthState = {
  authenticated: false,
  token: undefined,
  login_errors: undefined,
  user: undefined,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticate: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.authenticated = true;
    },
    saveToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.authenticated = false;
      state.token = undefined;
      state.user = undefined;
      state.login_errors = undefined;
    },
    saveAuthUser: (state, action: PayloadAction<AuthUser>) => {
      state.user = action.payload
    },
  },
});

export const { authenticate, saveToken, logout, saveAuthUser } = authSlice.actions;

export default authSlice;
