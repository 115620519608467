import Button from "../../../../components/Button";
import Select from "../../../../components/Select";
import { useNavigate, useParams } from "react-router-dom";
import { ORDER_STATUS } from "../../../../constants/purchase_history";
import { OrderStatusChip } from "../../../../components/Chip";
import ReportItem from "../../../../components/ReportItem";
import { useProductListQuery } from "../../../../services/business";
import ProductCard from "../../../../components/ProductCard";
import { StarIcon } from "@heroicons/react/20/solid";
import ReviewItem from "../ReviewItem";
import Option from "../../../../models/Options";
import { classNames } from "../../../../helpers/styles";
import { useEffect, useState } from "react";

const Reviews = () => {
  const [published, setPublished] = useState<number>(1);
  const published_reviews = [1,2,3];
  const unpublished_reviews = [1,2];

  const [for_display, setForDisplay] = useState<Array<Number>>([]);

  useEffect(() => {
    
    if (published === 1) {
      setForDisplay(published_reviews);
    } else {
      setForDisplay(unpublished_reviews);
    }
  }, [published]);

  return (
    <div className="container m-auto rounded-sm py-10 px-11">
      <div className="flex flex-col pt-6">
        <div className="flex justify-between">
          <h4>Rating & Reviews (9)</h4>
          <Radio
            options={[
              {
                label: "Published",
                value: 1,
              },
              {
                label: "Unpublished",
                value: 2
              },
            ]}
            value={published}
            onChange={setPublished}
          />
        </div>
        <div className="flex gap-5 pt-6">
          <div className="flex flex-col pr-10">
            <span className=" text-[45px]">4.5</span>
            <span>of 35 Reviews</span>
            <div className='flex justify-start items-center'>
              <StarIcon width={14} height={14} color="#FFCA2C" />
              <StarIcon width={14} height={14} color="#FFCA2C" />
              <StarIcon width={14} height={14} color="#FFCA2C" />
              <StarIcon width={14} height={14} color="#FFCA2C" />
              <StarIcon width={14} height={14} color="#FFCA2C" />
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-2">
            {
              for_display.map(fd => <ReviewItem is_published={published === 1} />)
            }
          </div>
        </div>
      </div>
    </div>
  );
}

const Radio = ({ options, value, onChange }: { options: Array<Option>, value: any, onChange(value: any): void }) => {
  return (
    <div className="rounded-md border border-border-default overflow-hidden">
      {
        options.map(o => {
          return (
            <button onClick={() => onChange(o.value)} className={classNames("border-l border-border-default h-12 px-10 first-of-type:border-0", o.value != value ? "bg-grey" : "" )}>
              <span className={classNames("text-sm bg-transparent", o.value == value ? "font-bold" : "")}>{o.label}</span>
            </button>
          );
        })
      }
    </div>
  );
}
export default Reviews;