import React from 'react';

import product_img from '../../assets/images/samples/product_img.png';
import red_tag from '../../assets/images/icons/red_tag.svg';
import { classNames } from '../../helpers/styles';
import './index.scss';

const SimpleProductCard = ({ show_add_to_cart = true, show_percent_discount = false, width = 'w-full max-w-250 min-w-150', ...props }: ProductCardProps) => {
  return (
    <div className={classNames('product-card w-full max-w-450 min-w-150 relative hover:cursor-pointer', width)}>
      <div className='w-full rounded-xl overflow-hidden relative'>
        <img src={product_img} className="product-img w-full h-full object-cover" alt="product" />
        <div className=' absolute top-3 left-3'>
          <img src={red_tag} alt="tag" />
        </div>
        <div className='absolute bottom-0 right-0 rounded-tl-xl rounded-br-xl bg-blue text-white text-2xl px-4 py-1 font-bold'>
          $5.89
        </div>
      </div>
      {props.children}
    </div>
  );
}


interface ProductCardProps  {
  show_add_to_cart?: boolean
  show_percent_discount?: boolean
  width?: string
  children?: any
}

export default SimpleProductCard;