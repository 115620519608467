import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddProdPromoResponse, ImageResponse } from "../../services/add_prod_promo/types";

export interface AddProdPromoState {
    add_type: string,
    prod_promo_title: string,
    card_color: string,
    short_desc: string,
    full_desc: string,
    price_in_usd: number,
    sku: string,
    expiration_days: number,
    no_expiration: boolean,
    percentage: number,
    cash_back: string,
    original_price: number,
    percentage_display: number,
    img_src:string,
    on_save: boolean,
    img_error: boolean,
    response_data: AddProdPromoResponse,
    image?: FormData,
    image_response: ImageResponse
}

const initialState: AddProdPromoState = {
    add_type: 'Product Service',
    prod_promo_title: '',
    card_color: '#C6C6C6',
    short_desc: '',
    full_desc: '',
    price_in_usd: 0,
    sku: '',
    expiration_days: 30,
    no_expiration: false,
    percentage: 0,
    cash_back: 'Discount',
    original_price: 0,
    percentage_display: 0,
    img_src: '',
    on_save:false,
    img_error:false,
    response_data: {},
    image_response: {}
}

const addProdPromoSlice = createSlice({
    name: 'add',
    initialState,
    reducers: {
        reset: (state) => {
            state = initialState;
        },
        onChangeTitle: (state, action) => {
            state.prod_promo_title = action.payload;
        },
        onChangeCardColor: (state, action) => {
            state.card_color = action.payload;
        },
        onChangeShortDesc: (state, action) => {
            state.short_desc = action.payload;
        },
        onChangeFullDesc: (state, action) => {
            state.full_desc = action.payload;
        },
        onChangePrice: (state, action) => {
            state.price_in_usd = action.payload;
        },
        onChangeSKU: (state, action) => {
            state.sku = action.payload;
        },
        onChangeExpirationDays: (state, action) => {
            if (action.payload === 'Positive') {
                state.expiration_days += 1;
            } else if (action.payload === 'Negative') {
                state.expiration_days -= 1;
            } else {
                state.expiration_days = 30;
            }
        },
        onChangeNoExpiration: (state, action) => {
            state.no_expiration = action.payload;
        },
        onChangePercentage: (state, action) => {
            state.percentage = action.payload;
        },
        onChangeCashBack: (state, action) => {
            state.cash_back = action.payload;
        },
        onChangeOriginalPrice: (state, action) => {
            state.original_price = action.payload;
        },
        onChangePercentageDisplay: (state, action) => {
            state.percentage_display = action.payload;
        },
        onChangeImage: (state, action) => {
            state.img_src = action.payload;
        },
        onSave: (state, action) => {
            state.on_save = action.payload;
        },
        onUpdateImgError: (state, action) => {
            state.img_error = action.payload;
        },
        saveAddProdPromoResponse: (state, action: PayloadAction<AddProdPromoResponse>) => {
            state.response_data = action.payload;
        },
        changeImageForm: (state, action) => {
            state.image = action.payload
        },
        saveImageResponse: (state, action: PayloadAction<ImageResponse>) => {
            state.image_response = action.payload;
        },
    }
});

export const { saveAddProdPromoResponse, saveImageResponse } = addProdPromoSlice.actions

export const actions = addProdPromoSlice.actions;

export default addProdPromoSlice;