import { useLocation } from "react-router-dom";
import { Channel, ChannelHeader, ChannelList, InfiniteScroll, MessageInput, MessageList, Thread, Window, useChatContext } from "stream-chat-react";
import { useBusinessQuery } from "../../../../services/business";
import { useEffect } from "react";

const InnerChat = ({ filters, my_stream_id }: { filters: any, my_stream_id: string } ) => {
  const { client, setActiveChannel } = useChatContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { data: contact_business_data, isSuccess } = useBusinessQuery(+(queryParams.get('contact_id') || 0), { skip: !queryParams.get('contact_id') });

  useEffect(() => {
    if (!client) return;

    if (isSuccess) {
      createChannel();
    }

  }, [isSuccess]);

  const createChannel = async () => {
    if (!!contact_business_data?.data.stream_id) {
      const conversation = client.channel('messaging', {
        members: [my_stream_id, contact_business_data?.data.stream_id],
      });

      await conversation.create();
      await conversation.watch();
      setActiveChannel(conversation);
    }
  }

  return (
    <>
      <ChannelList
        filters={filters}
        Paginator={InfiniteScroll}
        sort={{ has_unread: -1 }}
        showChannelSearch
      />
      <Channel>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </>
  );
}

export default InnerChat;