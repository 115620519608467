import React from 'react';
import Modal from './index';
import { XMarkIcon } from '@heroicons/react/24/outline';

const BaseModal = (props: {
    onRequestClose: () => void,
    is_open: boolean,
    title: string,
    header?: string,
    children: any,
    className?: string,
}) => {
    return (
        <Modal
            isOpen={props.is_open}
            contentLabel={props.title}
            className={props?.className}
            onRequestClose={props.onRequestClose}
        >
            <div className='bg-white px-28 py-16 relative'>
                <button className='absolute top-6 right-6 w-6 h-6' onClick={props.onRequestClose}>
                    <XMarkIcon />
                </button>
                <div className='text-center'>
                    {
                        props.header && <p className='text-h5 text-black font-bold font-montserrat'>
                            {props.header}
                        </p>
                    }
                    <span className='text-black lg:text-h4 font-bold font-montserrat md:text-h5 sm:text-h5 max-sm:text-h5'>
                        {props.title}
                    </span>
                </div>
                <div>
                    {props.children}
                </div>
            </div>
        </Modal>
    );
}

export default BaseModal;