import React from 'react';
import Desktop from './Desktop';

const Footer = () => {
  return (
    <footer className='bg-dark-blue'>
      <Desktop />
    </footer>
  );
}

export default Footer;