import React from 'react';
import BusinessTransparentPattern from '../../assets/images/business_transparent_pattern.png';
import Accessories from '../../assets/images/icons/categories/accessories.svg';
import ArtsAndDesign from '../../assets/images/icons/categories/arts_&_design.svg';
import Automotive from '../../assets/images/icons/categories/automotive.svg';
import CafesAndTreats from '../../assets/images/icons/categories/cafes_&_treats.svg';
import Cosmetics from '../../assets/images/icons/categories/cosmetics.svg';
import Dental from '../../assets/images/icons/categories/dental.svg';
import Electronics from '../../assets/images/icons/categories/electronics.svg';
import Entertainment from '../../assets/images/icons/categories/entertainment.svg';
import FitnessAndGym from '../../assets/images/icons/categories/fitness_&_gym.svg';
import FunAndLeisure from '../../assets/images/icons/categories/fun_&_leisure.svg';
import GiftsAndSurprises from '../../assets/images/icons/categories/gifts_&_surprises.svg';
import GroceriesAndMarkets from '../../assets/images/icons/categories/groceries_&_markets.svg';
import HomeService from '../../assets/images/icons/categories/home_service.svg';
import KidsAndToys from '../../assets/images/icons/categories/kids_&_Toys.svg';
import Medical from '../../assets/images/icons/categories/medical.svg';
import NightLife from '../../assets/images/icons/categories/night_life.svg';
import PersonalServices from '../../assets/images/icons/categories/personal_services.svg';
import Pets from '../../assets/images/icons/categories/pets.svg';
import Restaurants from '../../assets/images/icons/categories/restaurants.svg';
import Retail from '../../assets/images/icons/categories/retail.svg';
import Others from '../../assets/images/icons/categories/others.svg';
import BeautyAndWellness from '../../assets/images/icons/categories/beauty_&_wellness.svg';
import HotelsAndTravels from '../../assets/images/icons/categories/hotels_&_travels.svg';

import { useOptionsQuery } from '../../services/categories';
import { useAppSelector } from '../../store/hooks';

type cat_icons = {
    [key: string]: string;
};

const category_icons: cat_icons = {
    Accessories,
    ArtsAndDesign,
    Automotive,
    CafesAndTreats,
    Cosmetics,
    Dental,
    Electronics,
    Entertainment,
    FitnessAndGym,
    FunAndLeisure,
    GiftsAndSurprises,
    GroceriesAndMarkets,
    HomeService,
    KidsAndToys,
    Medical,
    NightLife,
    PersonalServices,
    Pets,
    Restaurants,
    Retail,
    Others,
    BeautyAndWellness,
    HotelsAndTravels
}

const Desktop = () => {
    useOptionsQuery();
    const { categories } = useAppSelector(store => store.categories.options);

    return (
        <div className='text-center'>
            <div className='h-56 bg-light-blue-green' style={{ backgroundImage: `url(${BusinessTransparentPattern})` }}>
                <div className='mx-52 lg:py-20 md:py-12 sm:py-12 max-sm:py-12'>
                    <div>
                        <span className='float-left text-h1 font-montserrat'>All Categories</span>
                    </div>
                </div>
            </div>
            <div>
                {/* <div className='lg:mx-52 md:mx-32 sm:mx-20 max-sm:mx-10 py-20 grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-3 max-sm:grid-cols-3 gap-y-3 gap-x-3 hover:cursor-pointer'> */}
                <div className='flex flex-wrap gap-3 items-center justify-center py-20'>
                    {
                        categories.map((item) => {
                            return(
                                <div key={item.id} className='w-44 h-40 rounded-xl border cursor-pointer' style={{ backgroundColor: `${item.color}` }}>
                                    <img src={category_icons[item.name.replaceAll(' ', '').replaceAll('&', 'And')]} alt="" className='mt-8 mx-16 h-12 w-12'/>
                                    <div className='text-center pt-6'>
                                        <span className='font-bold text-sm font-sans'>{item.name}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default Desktop;