import React, { ChangeEvent, EventHandler, useEffect, useRef, useState } from 'react';
import Modal from '../../../components/Modal';
import BaseModal from '../../../components/Modal/BaseModal';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';

import AddIcon from '../../../assets/images/add_icon.svg';
import { classNames } from '../../../helpers/styles';
import { isValidImageAspectRatio } from '../../../helpers/image';
import Select from '../../../components/Select';
import { useOptionsV2Query } from '../../../services/options';
import { useUserListOptionsQuery } from '../../../services/user';
import Autocomplete from '../../../components/Autocomplete';
import { BusinessBasic } from '../../../models/Business';
import { useBusinessCreateMutation, useBusinessUpdateMutation, useUploadBusinessLogoMutation } from '../../../services/business';
import Swal from 'sweetalert2';
import { useDebounce } from 'use-debounce';

const AddBusiness = (props: any) => {
    const data = props?.data || {};
    const { data: options } = useOptionsV2Query();
    const [selected_user, setSelectedUser] = useState<any>(undefined);
    const [user_query, setUserQuery] = useState('');
    const [search] = useDebounce(user_query, 1000);
    const { data: user_data } = useUserListOptionsQuery({ search });
    const [category, setCategory] = useState<any>(undefined);
    const [state, setState] = useState<any>(undefined);
    const categories = options?.categories || [];
    const states = options?.states || [];
    const hiddenFileInput = useRef<any>(undefined);
    const [image_error, setImageError] = useState(false);
    const [createBusiness] = useBusinessCreateMutation();
    const [updateBusiness] = useBusinessUpdateMutation();
    const [uploadBusinessLogo] = useUploadBusinessLogoMutation();
    const [field_errors, setFieldErrors] = useState({
        business_name: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        business_phone_number: '',
        category: '',
    });
    const [form, setForm] = useState({
        name: '',
        address_line_1: '',
        city: '',
        state: undefined,
        zip: '',
        category_id: undefined,
        phone_number: ''
    });
    const [image, setImage] = useState<any>({
        file: undefined,
        url: ''
    });

    useEffect(() => {
        setForm({
            name: '',
            address_line_1: '',
            city: '',
            state: undefined,
            zip: '',
            category_id: undefined,
            phone_number: ''
        });
        setCategory(undefined);
        setState(undefined);
        setImage({
            file: undefined,
            url: ''
        });
    }, []);

    useEffect(() => {
        console.log(data);
        if (!!data && data?.id) {
            setForm({
                name: data.name,
                address_line_1: data.address_line_1,
                city: data.city,
                state: data.state,
                zip: data.zip,
                phone_number: data.phone_number,
                category_id: undefined,
            });

            const state_item = states.filter(s => s.value === data.state)[0];
            const category_item = categories.filter(c => c.value === data.category_id)[0];

            setSelectedUser({ label: data?.owner?.name, value: data?.owner?.id });
            setCategory(() => category_item);
            setState(() => state_item);
            setImage({
                file: undefined,
                url: data?.logo || ''
            });
        }
    }, [data]);

    const handleOnUploadClick = () => {
        hiddenFileInput.current?.click();
    }

    const handleOnImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const img_file = e.target.files[0];
            const img_url = URL.createObjectURL(img_file);
            const is_valid = await isValidImageAspectRatio(img_url, { min_height: 512, min_width: 512, ratio: 1 / 1 });

            if (!is_valid) {
                setImageError(true);
                return;
            }

            setImage({
                file: img_file,
                url: img_url,
            });
        }
    }
    console.log('image ', image);
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const params = {
            ...form,
            state: state.value,
            category_id: category.value,
            owner_id: selected_user?.value || '',
        }

        if (!!data.id) {
            handleUpdateBusiness();
            return;
        }

        try {
            const response: any = await createBusiness(params);
            const data = response?.data;
            const image_file = image.file;
            console.log('!!image_file  ', image_file, data)
            if (!!image_file && data && data?.data?.id) { // upload business logo
                const image = new FormData();
                image.append('image', image_file || '');
                const businessLogoParams = {
                    id: data?.data?.id,
                    image,
                };
                await uploadBusinessLogo(businessLogoParams);
            }

            Swal.fire({
                title: 'Success!',
                text: 'Added Successfully!',
                icon: 'success',
                confirmButtonText: 'Ok',
                didClose() {
                    props?.onRequestClose();
                },
            });
        } catch (e) {

        } finally {

        }
    }
    const handleUpdateBusiness = async () => {
        const params = {
            id: data?.id,
            ...form,
            state: state.value,
            category_id: category.value,
            owner_id: selected_user?.value || '',
        }

        try {
            const response: any = await updateBusiness(params);
            const data = response?.data;
            const image_file = image.file;

            console.log(' xhr ', response);

            if (!!response?.error) {
                throw response;
            }

            if (!!image_file && data && data?.data?.id) { // upload business logo
                const image = new FormData();
                image.append('image', image_file || '');
                const businessLogoParams = {
                    id: data?.data?.id,
                    image,
                };
                const xhr = await uploadBusinessLogo(businessLogoParams);
            }

            Swal.fire({
                title: 'Success!',
                text: 'Updated Successfully!',
                icon: 'success',
                confirmButtonText: 'Ok',
                didClose() {
                    props?.onRequestClose();
                },
            });
        } catch (e: any) {
            Swal.fire({
                title: 'Error!',
                text: e?.error?.data?.message || 'An error occurred!',
                icon: 'error',
                confirmButtonText: 'Ok',
                // didClose() {
                //     props?.onRequestClose();
                // },
            });
        } finally {

        }
    }

    return (
        <BaseModal
            is_open={props?.is_open}
            title={`${data.id ? 'Update' : 'Add New'} Business`}
            className="modal-container"
            onRequestClose={props?.onRequestClose}
        >
            <form onSubmit={handleSubmit}>
                <div className="w-full pt-6">
                    <Autocomplete
                        placeholder="Search User"
                        options={user_data || []}
                        value={selected_user}
                        loading={false}
                        onChange={setSelectedUser}
                        onChangeQuery={setUserQuery}
                        required
                    />
                    <TextInput
                        type="text"
                        width="w-full"
                        placeholder="Business Name"
                        value={form.name}
                        onChange={e => setForm({ ...form, name: e.target.value })}
                        required
                    />
                    <TextInput
                        type="text"
                        width="w-full"
                        placeholder="Address 1"
                        value={form.address_line_1}
                        onChange={e => setForm({ ...form, address_line_1: e.target.value })}
                        required
                    />
                    <div className='grid grid-flow-row grid-cols-3 gap-2'>
                        <TextInput
                            type="text"
                            width="w-full"
                            placeholder="City"
                            value={form.city}
                            onChange={e => setForm({ ...form, city: e.target.value })}
                            required
                        />
                        <Select
                            placeholder='State'
                            onChange={setState}
                            options={states}
                            value={state}
                            width='w-full'
                            required
                        // error={!!field_errors.state}
                        // message={field_errors.state}
                        />
                        <TextInput
                            type="text"
                            width="w-full"
                            placeholder="Zip Code"
                            value={form.zip}
                            onChange={e => setForm({ ...form, zip: e.target.value })}
                            required
                        />
                    </div>
                    <Select
                        placeholder='Select Category'
                        onChange={setCategory}
                        options={categories}
                        value={category || undefined}
                        width='w-full'
                        required
                    // error={!!field_errors.category}
                    // message={field_errors.category}
                    />
                    <TextInput
                        type="text"
                        width="w-full"
                        placeholder="Business Phone Number"
                        value={form.phone_number}
                        onChange={e => setForm({ ...form, phone_number: e.target.value })}
                        required
                    />
                </div>
                <div className="flex space-x-6 pt-5">
                    <div
                        className="flex sm:h-20 sm:w-20 md:h-20 md:w-20 max-sm:h-20 max-sm:w-20 lg:h-28 lg:w-28 border-2 border-dashed border-gray-400 rounded-full bg-grey1 cursor-pointer"
                        onClick={handleOnUploadClick}
                    >
                        <img src={image?.url !== '' ? image.url : AddIcon} className={classNames(image.url !== '' ? 'object-cover w-full h-full rounded-full' : 'h-4 w-4 m-auto align-middle')} />
                    </div>
                    <div>
                        <div className="pt-2">
                            <span className="text-base font-montserrat font-bold">Add your Business Logo (Optional)</span><br />
                            <span className="font-sans text-sm italic">*Accepted files jpg, png and must be 512x512</span>
                            <div className="mt-2">
                                <Button type="button" height="h-8" text="Upload" bg_color="bg-sky-blue" text_size='text-xs' width="w-100" onClick={handleOnUploadClick} />
                                <input type='file' accept="image/*" ref={hiddenFileInput} style={{ visibility: 'hidden' }} onChange={handleOnImageChange} />
                                {
                                    image_error && <span className='flex text-sm text-red font-sans mt-2'>Image must be atleast 512x512 and with 1:1 ratio</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-8">
                    <div className="lg:flex space-x-6">
                        <Button type="submit" text={`${data.id ? "UPDATE" : "ADD"} BUSINESS`} width="lg:w-1/2 sm:w-full md:w-full max-sm:w-full" onSubmit={handleSubmit} />
                        <button className="h-12 leading-5 font-sans text-red text-sm lg:block sm:hidden md:hidden max-sm:hidden w-1/3">Cancel</button>
                    </div>
                </div>
            </form>
        </BaseModal>
    );
}

export default AddBusiness;