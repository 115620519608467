import { Popover, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import Button from '../Button';

import product_img from '../../assets/images/samples/product_img.png';
import check_small from '../../assets/images/icons/check_small.svg';
import massage from '../../assets/images/samples/massage.png';
import { CheckIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { Product } from '../../store/business/types';
import { TYPE_PRODUCT_SERVICE } from '../../constants/business';
import { useCheckoutMutation } from '../../services/cart';

const CartPopover = (props: any) => {
  const navigate = useNavigate();
  const [checkout] = useCheckoutMutation();
  const cart = useAppSelector(store => store.cart);

  const handleViewCartOnClick = () => {
    navigate('/app/cart');
  }

  const handleCheckoutOnClick = () => {
    handleCheckout();
  }

  useEffect(() => {
    if (!!cart.notify && props?.open) {
      setTimeout(() => {
        // props.close();
      }, 3000);
    }
  }, [cart.notify, cart.message, cart.type]);

  const handleCheckout = async () => {
    if (!cart.list.length) return;
    try {
      const products = cart.list.map(item => ({ id: item.item.id, quantity: item.quantity }));
      const xhr: any = await checkout({ products });

      if (xhr?.error?.status >= 400) {
        throw xhr;
       }

      window.location.href = xhr?.data?.url || '#';    
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <Popover.Panel className="absolute left-[-250px] mt-1 z-10 px-5 py-4 w-450" static>
        <div className="overflow-x-hidden overflow-y-auto rounded-lg shadow-lg ring-1 ring-black bg-white ring-opacity-5 px-4 pt-4 relative">
          <div className="relative flex flex-col">
            <div className='flex items-center pb-5'>
              {!!cart.message && <img src={check_small} width={16} height={16}/>}
              {/* <span className='pl-3 text-lg'>Added to cart</span> */}
              {!!cart.message && <span className='pl-3 text-lg'>{cart.message}</span>}
            </div>
            <div className='flex flex-col gap-1 overflow-hidden overflow-y-auto max-h-72'>
              {
                cart.list.map(item => <CartItem key={item.item.id} data={item.item} quantity={item.quantity} />)
              }
              {
                !cart.list.length && (
                  <div><h5>Cart is empty</h5></div>
                )
              }
            </div>
          </div>
          <div className="pt-5 flex gap-1">
            <Button text="VIEW CART" outlined onClick={handleViewCartOnClick} />
            <Button text="CHECKOUT" disabled={!cart.list.length} onClick={handleCheckoutOnClick} />
          </div>
          <button onClick={props.close}>
            <XMarkIcon width={24} height={24} className='absolute top-2 right-2 text-[#A6A6A6]' />
          </button>
        </div>
      </Popover.Panel>
    </Transition>
  );
}

const CartItem = ({ data, quantity }: { data: Product, quantity: number }) => {
  return (
    <div className='flex pr-5 relative'>
      {
        data.type === TYPE_PRODUCT_SERVICE ?
          <img src={data.image || product_img} className='w-20 h-20' />
            :
          <PromoCodeView
            description={data?.discount_description || ''}
            percentage={data?.discount_percentage || 0}
            bg_color={data?.color || ''}
          />
      }
      <div className=' pl-4'>
        <span className="text-sm product-title text-span text-start font-bold pt-1 overflow-hidden text-ellipsis">{data.name}</span>
        <div className='text-sm text-left'>
          <p>{data.short_description}</p>
        </div>
        <div className='flex flex-row items-center'>
          <div className='text-sm font-bold'>${data.price}</div>
          <div className='border-gray border-l h-4 mx-3'/>
          {!!data.discount_percentage && <div className='flex-1 text-sm text-grey2'>{data.discount_percentage}% OFF</div>}
        </div>
      </div>
      <div className='absolute top-0 right-0 text-sm'>
        x{quantity}
      </div>
    </div>
  );
}

const PromoCodeView = (props: { description: string, percentage: number, bg_color: string }) => {
  return (
    <div className='w-20 h-20 relative bg-red text-white flex flex-col items-center justify-center' style={{
      backgroundColor: props.bg_color,
    }}>
      <div><h6 className=''>{Math.round(props.percentage)}%</h6></div>
      <div><h6 className=''>{props.description}</h6></div>
    </div>
  );
}

export default CartPopover;