import React from 'react';
import ActiveDailyCustomersIcon from '../../assets/images/icons/active_daily_customers.svg';
import CitiesNationWideIcon from '../../assets/images/icons/cities_nationwide.svg';
import Business from '../../assets/images/icons/business.svg';
import BusinessOnTheGoIcon from '../../assets/images/icons/business_on_the_go.svg';
import BusinessTransparentPattern from '../../assets/images/business_transparent_pattern.png';
import BusinessImage1 from '../../assets/images/for_business_1.png';
import BusinessImage2 from '../../assets/images/for_business_2.png';
import BusinessImage3 from '../../assets/images/for_business_3.png';
import AttractCustomersIcon from '../../assets/images/attract_new_customers.png';
import Button from '../../components/Button';
import NewAudiencesIcon from '../../assets/images/icons/new_audiences.svg';
import BoostBrandLoyaltyIcon from '../../assets/images/icons/boost_brand_loyalty.svg';
import DestinationChoiceIcon from '../../assets/images/icons/destination_choice.svg';
import CheckCircle from '../../assets/images/icons/check_circle.svg';
import Accordion from '../../components/Accordion';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BusinessPic1 from '../../assets/images/business_pic_1.png';
import BusinessPic2 from '../../assets/images/business_pic_2.png';
import BusinessPic3 from '../../assets/images/business_pic_3.png';
import BusinessPic4 from '../../assets/images/business_pic_4.png';
import BusinessPic5 from '../../assets/images/business_pic_5.png';
import BusinessPic6 from '../../assets/images/business_pic_6.png';
import BusinessPic7 from '../../assets/images/business_pic_7.png';
import BusinessPic8 from '../../assets/images/business_pic_8.png';
import BusinessPic9 from '../../assets/images/business_pic_9.png';
import BusinessPic10 from '../../assets/images/business_pic_10.png';
import ConnetCustomersDirectly from '../../assets/images/icons/connect_customers_directly.svg';
import BrandLoyaltySmall from '../../assets/images/icons/boost_brand_loyalty_small.svg';
import { classNames } from '../../helpers/styles';
import AllanJamesWorth from '../../assets/images/allan_jamesworth_pic.png'
import LiquorCompanyLogo from '../../assets/images/liquor_company_logo.png';

type business_images = {
    [key: string]: string;
};

const valu_menu_display = [
    {
        icon: ActiveDailyCustomersIcon,
        title: 'Thounsands of active daily users',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem ipsum',
        bgColor: 'bg-light-orange'
    },
    {
        icon: CitiesNationWideIcon,
        title: 'Over 40+ Cities Nationwide',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem ipsum',
        bgColor: 'bg-dark-blue-green'
    },
    {
        icon: BusinessOnTheGoIcon,
        title: 'Promote Business From Your Phone',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem ipsum',
        bgColor: 'bg-dark-pink'
    },
    {
        icon: Business,
        title: 'Trusted by 500+ Businesses',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem ipsum',
        bgColor: 'bg-dark-green'
    },
    {
        icon: BrandLoyaltySmall,
        title: 'Boost Brand \n Loyalty',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem ipsum',
        bgColor: 'bg-light-maroon'
    },
    {
        icon: ConnetCustomersDirectly,
        title: 'Connect with Your Customers Directly',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem ipsum',
        bgColor: 'bg-pale-light-blue'
    }
]

const business_img: business_images = {
    BusinessPic1,
    BusinessPic2,
    BusinessPic3,
    BusinessPic4,
    BusinessPic5,
    BusinessPic6,
    BusinessPic7,
    BusinessPic8,
    BusinessPic9,
    BusinessPic10,
}

const img_cls = [
    {
        height: '154.43px',
        width: '192.85px',
        marginTop: '0rem'
    },
    {
        height: '121.39px',
        width: '121.39px',
        marginTop: '1rem'
    },
    {
        height: '121.39px',
        width: '134.14px',
        marginTop: '1rem'
    },
    {
        height: '121.39px',
        width: '126.06px',
        marginTop: '1rem'
    },
    {
        height: '115.25px',
        width: '225.89px',
        marginTop: '1.5rem'
    },
    {
        height: '172.87px',
        width: '172.87px',
        marginTop: '0rem'
    },
    {
        height: '172.87px',
        width: '172.87px',
        marginTop: '0rem'
    },
    {
        height: '172.87px',
        width: '172.87px',
        marginTop: '0rem'
    },
    {
        height: '121.29px',
        width: '121.39px',
        marginTop: '1.5rem'
    },
    {
        height: '106.03px',
        width: '172.3px',
        marginTop: '2rem'
    }
]

const faq = [
    {
        title: 'Frequently Asked Question',
        content: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.'
    },
    {
        title: 'Frequently Asked Question',
        content: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.'
    },
    {
        title: 'Frequently Asked Question',
        content: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.'
    },
    {
        title: 'Frequently Asked Question',
        content: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.'
    },
    {
        title: 'Frequently Asked Question',
        content: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.'
    }
];

const Desktop = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const navigate = useNavigate();

    const routePage = () => {
        navigate('/app/for-business?type=pricing');
    }

    return (
        <div id="DesktopForBusiness" className='w-screen'>
            {type === 'display' ?
                <div className='bg-light-orange relative w-screen' style={{ height: '40.8rem' }}>
                    <img src={BusinessTransparentPattern} alt="" className='h-full object-cover'/>
                    <div className='lg:flex top-28 absolute justify-center sm:mx-20 md:mx-32 max-sm:mx-20 left-0 right-0'>
                        <div>
                            <div className='w-600 pt-12'>
                                <span className='text-h1 font-montserrat text-black font-bold'>New & Better Way To Promote Your Business</span>
                            </div>
                            <div className='w-500 mt-2'>
                                <span className='font-sans text-black text-xl leading-7'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</span>
                            </div>
                            <div className='flex space-x-8'>
                                <div className='mt-10'>
                                    <div className='flex'>
                                        <img src={ActiveDailyCustomersIcon} alt="" className='h-20 w-20'/>
                                        <span className='text-black text-h6 font-bold font-montserrat w-40 pt-5 pl-4'>1400+ Active Daily Customers</span>
                                    </div>
                                    <div className='flex mt-12'>
                                        <img src={Business} alt="" className='h-20 w-20'/>
                                        <span className='text-black text-h6 font-bold font-montserrat w-40 pt-5 pl-4'>Trusted by 500+ Businesses</span>
                                    </div>
                                </div>
                                <div className='mt-10'>
                                    <div className='flex'>
                                        <img src={CitiesNationWideIcon} alt="" className='h-20 w-20'/>
                                        <span className='text-black text-h6 font-bold font-montserrat w-40 pt-5 pl-4'>Over 40+ Cities Nationwide</span>
                                    </div>
                                    <div className='flex mt-12'>
                                        <img src={BusinessOnTheGoIcon} alt="" className='h-20 w-20'/>
                                        <span className='text-black text-h6 font-bold font-montserrat w-40 pt-5 pl-4'>Your Business on the Go.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex space-x-2'>
                                <div>
                                    <img src={BusinessImage1} alt="" className='pt-6 object-cover' style={{ height: '39.13rem', width:'21.87rem' }}/>
                                </div>
                                <div>
                                    <div className='h-full'>
                                        <img src={BusinessImage2} alt="" className='w-80 mb-2' style={{ height: '24.9rem', width:'21.87rem' }}/>
                                        <img src={BusinessImage3} alt="" className='w-80 mt-2' style={{ height: '13.75rem', width:'21.87rem' }}/>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                :
                <div className='text-center'>
                    <div className='h-96 bg-pale-green' style={{ backgroundImage: `url(${BusinessTransparentPattern})` }}>
                        <div className='flex justify-center mx-52 space-x-10'>
                            <div className='mt-28 max-w-xl text-left'>
                                <span className='text-h1 font-montserrat'>Level up your Business with ValuMenu.</span><br/><br/>
                                <span className='text-xl font-sans'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</span>
                            </div>
                            <div>
                                <div className='min-h-450 min-w-450 bg-white border rounded-md mt-16'>
                                    <div className='mx-7'>
                                        <div className='text-center mt-7'>
                                            <div><span className='text-lg font-montserrat font-bold text-dark-blue'>BUSINESS</span></div>
                                            <div className='mt-2'><span className='text-5xl font-bold font-montserrat'>$49.00</span></div>
                                            <div className='mt-3'><span className='font-sans text-base text-dark-grey'>per month and 1% per transaction</span></div>
                                        </div>
                                        <div className='text-left mt-16'>
                                            <div className='text-left flex'>
                                                <img src={CheckCircle} className='-ml-12 -mr-7 -my-9'/>
                                                <span className='text-base font-sans pt-2'>Benefit number 1 should be here</span>
                                            </div>
                                            <div className='text-left flex'>
                                                <img src={CheckCircle} className='-ml-12 -mr-7 -my-9'/>
                                                <span className='text-base font-sans pt-2'>Benefit number 1 should be here</span>
                                            </div>
                                            <div className='text-left flex'>
                                                <img src={CheckCircle} className='-ml-12 -mr-7 -my-9'/>
                                                <span className='text-base font-sans pt-2'>Benefit number 1 should be here</span>
                                            </div>
                                            <div className='mt-6'>
                                                <Button text='START YOUR 14-DAY FREE TRIAL' width='w-full'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            
            {type === 'display' ?
                <div className='mt-52 mb-36 flex justify-center left-0 right-0 space-x-40'>
                    <div>
                        <img src={AttractCustomersIcon} alt="" className='pt-12' style={{ width: '46.5rem', height: '30.1875rem'}}/>
                    </div>
                    <div className='w-450 pt-8'>
                        <span className='text-black font-montserrat text-h1'>Attract New Customers and Build Brand Loyalty</span><br/><br/>
                        <span className='text-black font-sans text-sm'>You have something unique to offer, and you need to reach the people who want to experience it. With ValuMenu, you can. We have one of the largest platforms in the world, and a tech-forward approach to getting your offer to the right people. </span><br/><br/>
                        <span className='text-black font-sans text-sm'>Everyday excellence. Extraordinary experiences. No matter the need, you make it happen. We help you find visitors, and you give them something to remember.</span><br/><br/>
                        <Button text='JOIN' onClick={routePage}/>
                    </div>
                </div>
                :
                <div className='mt-52 mb-20 flex justify-center'>
                    <div>
                        <div className='text-center'>
                            <span className='font-montserrat font-bold text-h4'>Loved by 1000+ of Businesses Like Yours</span>
                        </div>
                        <div className='grid grid-cols-5 mt-8 gap-0'>
                            {
                                img_cls.map((item, index) => (
                                    <div key={index} className='align-middle block'>
                                        <img src={business_img[`BusinessPic${index+1}`]} style={{ height: `${item.height}`, width: `${item.width}`, marginTop: `${item.marginTop}`}} />
                                    </div>
                                ))
                            }
                        </div>                                                                                                                                                                                                                                                                
                    </div>
                </div>
            }

            {type === 'pricing' ?
                <div className='my-20 flex justify-center'>
                    <div>
                        <div className='text-center'>
                            <span className='font-montserrat font-bold text-h4'>Why Use Valu Menu</span>
                        </div>
                        <div className='text-center pt-4'>
                            <span className='text-xl font-sans'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</span>
                        </div>
                        <div className='grid grid-cols-3 gap-4 mt-12'>
                            {
                                valu_menu_display.map((item, index) => (
                                    <div key={index} className={classNames('rounded-xl border', item.bgColor)} style={{ width: '22.1875rem', height: '24.56rem'}}>
                                        <div className='flex justify-center mt-9'>
                                            <img src={item.icon} alt="" className='w-20 h-20'/>
                                        </div>
                                        <div className='text-center mx-5 mt-6'>
                                            <span className='text-2xl font-bold font-montserrat whitespace-pre-line'>{item.title}</span>
                                        </div>
                                        <div className='text-center mx-5 mt-10'>
                                            <span className='text-lg font-sans'>
                                                {item.description}
                                            </span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div> : ''
            }

            <div className='flex justify-center mb-32'>
                <div>
                    <div className='flex justify-center'>
                        <img src={AllanJamesWorth} alt="" className='h-20 w-20'/>
                    </div>
                    <div className='text-center mt-4 px-72'>
                        <span className='font-montserrat font-bold text-h2'>“Since we promoted our products and services at Valu Menu, our sales have been doubled!”</span>
                    </div>
                    <div className='text-center mt-6'>
                        <span className='font-bold font-sans text-sm'>Allan Jamesworth, CEO and Founder of </span>
                        <span className='font-sans text-sm'>Big Country Liquor</span>
                    </div>
                    <div className='flex justify-center mt-6'>
                        <img src={LiquorCompanyLogo} alt="" className='h-14 w-14'/>
                    </div>
                </div>
            </div>

            {type === 'display' ?
                <div className='relative w-screen bg-pale-green' style={{ height: '40.8rem' }}>
                    <img src={BusinessTransparentPattern} alt="" className='h-full object-cover'/>
                    <div className='absolute flex justify-center left-0 right-0 top-16 space-x-40'>
                        <div style={{ width: '26.68rem' }}>
                            <span className='text-black font-montserrat text-h1 w-450'>ValuMenu is the Marketplace for Local experiences</span><br/><br/>
                            <span className='text-black font-sans text-sm'>Groupon is more than a place to shop for deals. It’s where customers around the world explore where to go, what to do, or what to buy today. Here’s how Groupon Merchant helps promote your business. </span><br/><br/>
                            <Button text='JOIN' onClick={routePage}/>
                        </div>
                        <div>
                            <div className='bg-white rounded-md flex space-x-10 mb-3' style={{ width: '39rem', height: '9.6875rem' }}>
                                <div>
                                    <img src={NewAudiencesIcon} alt="" className='h-16 w-16 mt-9 ml-9'/>
                                </div>
                                <div className='w-450 mt-6'>
                                    <div className='mb-2'>
                                        <span className='text-h4 font-montserrat'>Find New Audiences</span><br/>
                                    </div>
                                    <span className='text-h6 font-sans mt-6'>We invest millions in marketing campaigns across web and mobile to bring customers to the ValuMenu platform.</span>
                                </div>
                            </div>
                            <div className='bg-white rounded-md flex space-x-10 my-3' style={{ width: '39rem', height: '9.6875rem' }}>
                                <div>
                                    <img src={BoostBrandLoyaltyIcon} alt="" className='h-16 w-16 mt-9 ml-9'/>
                                </div>
                                <div className='w-450 mt-6'>
                                    <div className='mb-2'>
                                        <span className='text-h4 font-montserrat'>Boost Brand Loyalty</span><br/>
                                    </div>
                                    <span className='text-h6 font-sans mt-6'>We invest millions in marketing campaigns across web and mobile to bring customers to the ValuMenu platform.</span>
                                </div>
                            </div>
                            <div className='bg-white rounded-md flex space-x-10 mt-3' style={{ width: '39rem', height: '9.6875rem' }}>
                                <div>
                                    <img src={DestinationChoiceIcon} alt="" className='h-16 w-16 mt-9 ml-9'/>
                                </div>
                                <div className='w-450 mt-6'>
                                    <div className='mb-2'>
                                        <span className='text-h4 font-montserrat'>Be the Destination of Choice</span><br/>
                                    </div>
                                    <span className='text-h6 font-sans mt-6'>People are always looking for their new favorite thing to do. We’ll use our tools to help them find you.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ''
            }
            
            <div className='h-auto my-24 flex justify-center left-0 right-0 space-x-40'>
                <div>
                    <div style={{ width: '26.68rem' }}>
                        <span className='text-black font-montserrat text-h1 w-450'>Frequently Asked Questions</span><br/><br/>
                    </div>
                </div>
                <div>
                    <div>
                        {
                            faq.map((item, index) => (
                                <Accordion key={index} content={item.content} title={item.title} className='bg-white rounded border-2 border-gray-200 mb-4' titleClass='text-h5' width='w-625' height='h-75'/>
                            ))
                        }
                    </div>
                </div>
            </div>

            {type==='display' ?
                <div className='flex bg-light-grey h-48 justify-center left-0 right-0 space-x-72'>
                    <div className='pt-9' style={{ width: '44.5625rem' }}>
                        <div className='mb-2'>
                            <span className='text-black font-montserrat text-h1 w-450'>Read to Level up your Business?</span>
                        </div>
                        <span className='text-black font-sans text-sm'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. </span><br/><br/>
                    </div>
                    <div  className='pt-16'>
                        <Button text="JOIN" onClick={routePage}/>
                    </div>
                </div> : ''
            }
            
        </div>
    );
}

export default Desktop;