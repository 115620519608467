import React, { useState } from 'react';
import SignUpImage from '../../assets/images/sign_up_image.png';
import ValuMenuIcon from '../../assets/images/valu_menu_sign_in_logo.svg';
import ArrowLeft from '../../assets/images/icons/chevron_left.svg';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import MobileLogo from '../../assets/images/valu_menu.svg';
import Checkbox from '../../components/Checkbox';
import AllanJameworthPic from '../../assets/images/allan_jamesworth_pic.png';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { SignUpKeys, onSignUpFormChange } from '../../store/sign_up/slice';
import { useSignUpMutation } from '../../services/sign_up';

const SignUp = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const data = useAppSelector(store => store.signUp);
  let signUpType = 'normal';
  const handleOnChange = (key: SignUpKeys, value: any) => {
    dispatch(onSignUpFormChange({ key, value }));
  }
  const [signUp, { isSuccess, isError, data: signUpData, }] = useSignUpMutation();
  const [errors, setErrors] = useState<any>({
    name: '',
    username: '',
    email: '',
    phone: '',
    password: '',
    c_password: '',
  });

  const createAccount = async () => {
    try {
      const xhr = await signUp({
        name: data.full_name,
        username: data.user_name,
        email: data.email_add,
        phone: data.phone_number,
        password: data.password,
        c_password: data.confirm_pass
      });

      if ((xhr as any)?.error?.status >= 400) {
        throw (xhr as any);
      }

    } catch (e: any) {
      console.log('error ', e);
      const errors_data = e?.error?.data?.errors;
      const new_errors: any = {
        name: '',
        username: '',
        email: '',
        phone: '',
        password: '',
        c_password: '',
      };
      if (!!errors_data) {
        Object.keys(errors_data).map(k => {
          new_errors[k] = errors_data[k][0];
        });
        console.log({ new_errors });
      }

      setErrors(new_errors);
    }
  }

  if (isSuccess) {
    navigate('/app/profile');
  }

  const routePage = () => {
    navigate('/sign-up-business-details');
  }

  const clearError = (key: string) => {
    setErrors({
      ...errors,
      [key]: '',
    });
  }

  const handleSignUpWithGoogleOnClick = async () => {

  }

  return (
    <div id="SignIn" className='w-screen h-full'>
      <div className='lg:flex h-full'>
        <div className='relative lg:block md:hidden sm:hidden max-sm:hidden'>
          {signUpType === "normal" ?
            <img src={SignUpImage} alt="" className='h-full object-cover' style={{ width: '40rem' }} />
            :
            <div style={{ width: '26rem' }} className='h-full bg-dark-blue' />
          }
          <div className='absolute top-8 left-10'>
            <img src={ValuMenuIcon} alt="" className='' />
          </div>
          {signUpType === 'business' ?
            <span className='text-h5 font-montserrat text-white absolute top-36 left-10 w-80'>
              "Since we promoted our products and services at Valu Menu, our sales have been doubled!"
            </span> : ''
          }
          {signUpType === 'business' ?
            <div className='absolute top-72 left-10 w-80'>
              <div className='flex'>
                <img src={AllanJameworthPic} alt="" className='h-12 w-12 rounded-full' />
                <div className='w-52 ml-4 leading-5 pt-1'>
                  <span className='text-sm text-neutral-200 font-sans'>Allan Jamesworth, CEO and Founder of Big Country Liquor</span>
                </div>
              </div>
            </div> : ''
          }
          {signUpType === 'business' ?
            <div className='absolute bottom-6 w-80 left-10'>
              <span className='text-neutral-300 text-sm font-sans'>Copyright @ 2021 Valu Menu. All Rights Reserved</span>
            </div> : ''
          }
        </div>
        <div className='w-full'>
          <div className='flex justify-between lg:mx-10 my-9 md:mx-20 sm:mx-10 max-sm:mx-5'>
            <div className='flex space-x-3 sm:w-1/3 md:w-1/3 max-sm:w-1/3'>
              <img src={ArrowLeft} alt="" className='w-2' />
              <span className='font-sans text-base leading-6'>Back</span>
            </div>
            <div className='lg:hidden w-1/3 flex justify-center'>
              <img src={MobileLogo} alt="" className='h-7 w-16' />
            </div>
            <div className='lg:block lg:text-right sm:w-1/3 md:w-1/3 max-sm:w-1/3'>
              <Link to='/sign-in' className='font-sans text-base leading-6 text-blue lg:block md:hidden sm:hidden max-sm:hidden'>Sign in</Link>
            </div>
          </div>
          <div className='md-lg:mx-72 max-lg:mx-20 md:mx-20 sm:mx-10 max-sm:mx-5 sm:mt-16 md:mt-16 max-sm:mt-16'>
            <div>
              <span className='text-blue lg:text-h4 font-bold font-montserrat md:text-h5 sm:text-h5 max-sm:text-h5'>
                {signUpType === 'normal' ?
                  'Sign up' : 'Start your 14-Day Free Trial'
                }
              </span>
            </div>
            <div className='mt-5 lg:flex lg:space-x-1 '>
              <TextInput
                placeholder='Full name'
                width='w-full'
                className='border border-gray-300 rounded-md'
                value={data.full_name || ''}
                onChange={(e) => {
                  handleOnChange('full_name', e.target.value);
                  clearError('name');
                }}
                error={!!errors?.name}
                message={errors?.name}
              />
              <TextInput
                placeholder='Username'
                width='w-full'
                className='border border-gray-300 rounded-md'
                value={data.user_name || ''}
                onChange={(e) => {
                  handleOnChange('user_name', e.target.value);
                  clearError('username');
                }}
                error={!!errors?.username}
                message={errors?.username}

              />
            </div>
            <div className='mt-1 lg:flex lg:space-x-1 '>
              <TextInput
                placeholder='Email Address'
                width='w-full'
                className='border border-gray-300 rounded-md'
                value={data.email_add || ''}
                onChange={(e) => {
                  handleOnChange('email_add', e.target.value);
                  clearError('email');
                }}
                error={!!errors?.email}
                message={errors?.email}

              />
              <TextInput
                placeholder='Phone number'
                width='w-full'
                className='border border-gray-300 rounded-md'
                value={data.phone_number || ''}
                onChange={(e) => {
                  handleOnChange('phone_number', e.target.value);
                  clearError('phone');
                }}
                error={!!errors?.phone}
                message={errors?.phone}

              />
            </div>
            <div className='mt-1'>
              <TextInput
                placeholder='Password*'
                type="password"
                width='w-full'
                className='border border-gray-300 rounded-md'
                value={data.password || ''}
                onChange={(e) => {
                  handleOnChange('password', e.target.value);
                  clearError('password');
                }}
                error={!!errors?.password}
                message={errors?.password}

              />
            </div>
            <div className='mt-1'>
              <TextInput
                placeholder='Confirm password'
                type="password"
                width='w-full'
                className='border border-gray-300 rounded-md'
                value={data.confirm_pass || ''}
                onChange={(e) => {
                  handleOnChange('confirm_pass', e.target.value);
                  clearError('c_password');
                }}
                error={!!errors?.c_password}
                message={errors?.c_password}

              />
            </div>
            <div className='mt-2'>
              {signUpType === 'normal' ?
                <Button text='SIGN UP' width='w-full' onClick={createAccount} />
                :
                <Button text='CONTINUE' width='w-full' onClick={routePage} />
              }

            </div>
            <div className='flex mt-4 justify-between space-x-2'>
              <div className='w-full pt-3'>
                <div className='border border-gray-200'></div>
              </div>
              <div>
                <span className='font-sans text-sm'>Or</span>
              </div>
              <div className='w-full pt-3'>
                <div className='border border-gray-200'></div>
              </div>
            </div>
            <div className='mt-4'>
              <Button
                text='SIGN IN WITH GOOGLE'
                text_color='text-black'
                outlined={true}
                outline_color='gray-700'
                width='w-full'
                onClick={handleSignUpWithGoogleOnClick}
              />
            </div>
            {signUpType === 'normal' ?
              <div className='lg:block sm:hidden max-sm:hidden md:hidden'>
                <div className='lg:mt-10 md:text-center sm:text-center max-sm:text-center lg:text-left sm:mt-40 md:mt-40 max-sm:mt-40 space-x-1 lg:flex '>
                  <div className=''>
                    <Checkbox />
                  </div>
                  <div className=''>
                    <span className='text-sm text-black font-sans'>Creating an account means you're okay with  our</span>
                    <a href='#' className='text-sm text-blue font-sans'> Terms of Service</a>
                    <span className='text-sm text-black font-sans'> and </span>
                    <a href='#' className='text-sm text-blue font-sans'>Provicy Policy</a>
                  </div>
                </div>
              </div> : ''
            }
            <div className='space-x-1 lg:mt-10 md:text-center sm:text-center max-sm:text-center lg:text-left sm:my-5 md:my-5 max-sm:my-5'>
              <span className='text-base text-black font-sans'>Already a member?</span>
              <a href='/sign-in' className='text-sm text-blue font-sans'>Sign in here</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// type SignUpProps = {

// };

export default SignUp;