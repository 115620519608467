import { StarIcon } from '@heroicons/react/20/solid';
import business_bg from '../../assets/images/samples/business_bg.png';
import business_img1 from '../../assets/images/samples/business_img.png';
import heart_gray from '../../assets/images/icons/heart_gray.svg';
import heart_active from '../../assets/images/icons/heart_active.svg';
import { useState } from 'react';
import { Business } from '../../store/business/types';
import { Link } from 'react-router-dom';

const SearchItem = ({ data }: { data: Business }) => {
  const [fav, setFav] = useState(false);

  const handleFavOnClick = () => {
    const new_fav = !fav;
    setFav(new_fav);
  }
  return (
    <div className='w-full rounded-md overflow-hidden border border-hairline relative'>
      <img src={fav ? heart_active : heart_gray} className='object-contain h-4 w-5 absolute top-3 right-4 cursor-pointer' onClick={handleFavOnClick} />
      <img src={data.logo} className='object-cover h-12 w-12 rounded-full absolute top-3 left-4' />
      <img src={data.previews[0]?.url || business_bg} className='w-full h-150 object-cover' />
      <div className='pt-3 pb-5 px-5'>
        <Link to={`/app/business-details/${data.id}`}><h5>{data.name}</h5></Link>
        <span className='text-sm'>{data.address_line_1}</span>
        <div className='flex justify-start items-center'>
          <StarIcon width={14} height={14} color='#FFCA2C' />
          <StarIcon width={14} height={14} color='#FFCA2C' />
          <StarIcon width={14} height={14} color='#FFCA2C' />
          <StarIcon width={14} height={14} color='#FFCA2C' />
          <StarIcon width={14} height={14} color='#FFCA2C' />
          <span className='text-sm self-end pl-3'>4.5 of 35 Reviews</span>
        </div>
      </div>
    </div>
  );
}

export default SearchItem;