import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Option } from "./types";
import { SignUpResponse } from "../../services/sign_up/types";

export interface SignUpState {
    email_add: string,
    password: string,
    confirm_pass: string,
    full_name: string
    user_name: string,
    phone_number: string,
    submitted: boolean,
    business_name: string,
    address: string,
    city: string,
    state: string,
    zip_code: string,
    category: string,
    business_phone_number: string,
    image_url: string,
    url_err: boolean,
    option?: Option,
}

const initialState: SignUpState = {
    email_add: '',
    password: '',
    confirm_pass: '',
    full_name: '',
    user_name: '',
    phone_number: '',
    submitted: false,
    business_name: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    category: '',
    business_phone_number: '',
    image_url: '',
    url_err: false,
}

export interface OnSignUpFormChangeType {
    key: SignUpKeys,
    value: any
}

export type SignUpKeys = 'email_add' | 'password' | 'confirm_pass' | 'full_name' | 'user_name' | 'phone_number' | 'submitted' | 'business_name' | 'address'
| 'city' | 'state' | 'zip_code' | 'category' | 'business_phone_number' | 'image_url' | 'url_err';

const signUpSlice = createSlice({
    name: 'sign-up',
    initialState,
    reducers: {
        onSignUpFormChange: (state:any, action: PayloadAction<OnSignUpFormChangeType>) => {
            state[action.payload.key] = action.payload.value;
        },
        saveOption: (state, action: PayloadAction<Option>) => {
            state.option = action.payload
        },
    }
});

export const { onSignUpFormChange, saveOption } = signUpSlice.actions;

export default signUpSlice;