import { classNames } from "../../helpers/styles";
import Option from "../../models/Options";

const TabRadio = ({ options, value, onChange }: { options: Array<Option>, value: any, onChange(value: any): void }) => {
  return (
    <div className="rounded-md border border-border-default overflow-hidden w-auto">
      {
        options.map(o => {
          return (
            <button key={o.value} onClick={() => onChange(o.value)} className={classNames("border-l border-border-default h-16 px-16 first-of-type:border-0", o.value != value ? "bg-white" : "bg-dark-blue" )}>
              <span className={classNames(" bg-transparent", o.value == value ? "font-bold text-white" : "")}>{o.label}</span>
            </button>
          );
        })
      }
    </div>
  );
}

export default TabRadio;