
import { useNavigate, useParams } from "react-router-dom";
import { StarIcon } from "@heroicons/react/20/solid";
import ReportItem from "../../../components/ReportItem";
import Select from "../../../components/Select";
import { Link } from "react-router-dom";
import { useActivityListQuery } from "../../../services/user";
import moment from "moment";

const AdminOverview = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useActivityListQuery();
  const list = data?.data || [];

  const handleOnClaim = () => {
    navigate('/app/claim/123');
  }

  return (
    <div className="container m-auto rounded-sm py-10 px-11">
      <div className="flex justify-between items-center">
        <div className="flex">
          <h4>Quick Overview</h4>
        </div>
        <div className="flex">
          <div className="pr-2">
            <Select
              placeholder='This month'
              onChange={() => { }}
              options={[
                {
                  label: 'option 1',
                  value: 1
                },
                {
                  label: 'option 2',
                  value: 2
                },
                {
                  label: 'option 3',
                  value: 3
                },
                {
                  label: 'option 4',
                  value: 4
                },
              ]}
              value={undefined}
            />
          </div>
        </div>
      </div>
      <div className="w-full pt-3 grid grid-flow-row grid-cols-5 gap-2">
        <ReportItem label="New Orders" value="29" />
        <ReportItem label="Total Sales" value="$292.77" />
        <ReportItem label="Customers" value="147" />
        <ReportItem label="Messages/Inquiries" value="56" />
        <ReportItem label="Profile Views" value="147" />
      </div>
      <div className="flex justify-between items-center pt-6">
        <h4>Activities</h4>
      </div>
      <div className="flex flex-col gap-3 py-5">
        {
          list.map(item => <Item data={item} />)
        }
        {/* <Item data={{ type: 1 }} />
        <Item data={{ type: 2 }} />
        <Item data={{ type: 3 }} />
        <Item data={{ type: 2 }} />
        <Item data={{ type: 3 }} />
        <Item data={{ type: 1 }} />
        <Item data={{ type: 2 }} />
        <Item data={{ type: 3 }} />
        <Item data={{ type: 2 }} />
        <Item data={{ type: 3 }} /> */}
      </div>
    </div>
  );
}

const Item = (props: { data: any }) => {
  const message = props.data?.message || <></>;

  return (
    <div className="grid grid-cols-7 w-full gap-1 items-center border rounded-md border-border-default p-5">
      <div className="col-span-6 text-sm" dangerouslySetInnerHTML={{ __html: message }}>
      </div>
      <div className="col-span-1 text-sm">
        {moment(props.data?.created_at).format("MMM DD, hh:mm A")}
      </div>

    </div >
  );
}

export default AdminOverview;