import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
import { ROLE_WEB_ADMIN } from '../constants/roles';

const Protected = (props: ProtectedProps): JSX.Element => {
  const { token, user } = useAppSelector(store => store.auth);
  if (!token) {
    return <Navigate to="/sign-in" replace />;
  }

  if (user?.roles.includes(ROLE_WEB_ADMIN)) {
    return <Navigate to="/admin/dashboard" replace />;
  }

  return props.children;

  return props.children;
}

interface ProtectedProps {
  children: JSX.Element
}

export default Protected;