import React from 'react';

import Desktop from './Desktop';
import Mobile from './Mobile';


const ForBusiness = () => {
    return (
        <div id="ForBusiness">
            <div className='lg:block sm:hidden md:hidden max-sm:hidden'>
                <Desktop/>
            </div>
            <div className='lg:hidden'>
                <Mobile/>
            </div>
        </div>
    );
}

export default ForBusiness;