import React from 'react';
import massage from '../../assets/images/samples/massage.png';
import visa from '../../assets/images/icons/visa.png';
import american_express from '../../assets/images/icons/american_express.png';
import mastercard from '../../assets/images/icons/mastercard.png';
import paypal from '../../assets/images/icons/paypal.png';
import logo from '../../assets/images/valu_menu.svg';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import Checkbox from '../../components/Checkbox';
import Radio from '../../components/Radio';
import Select from '../../components/Select';
import { router_paths } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { Product } from '../../store/business/types';
import { useAppSelector } from '../../store/hooks';
import { TYPE_PRODUCT_SERVICE } from '../../constants/business';

const Checkout = () => {
  const navigate = useNavigate();
  const cart = useAppSelector(store => store.cart);

  return (
      <div className='grid grid-flow-row grid-cols-2 h-full w-full'>
        <div className='col-span-1 px-10 flex justify-end pb-20'>
          <div className='max-w-[600px] mt-[10%] w-full'>
            <img
              className="h-8 w-auto sm:h-10 lg:m-0 m-auto"
              src={logo}
              alt="Valu Menu"
            />
            <div className='pt-10'>
              <h4>Checkout</h4>
              <span className='text-md'>Select the payment method you would like to use.</span>
              <div className='grid grid-flow-row grid-cols-2 gap-2 w-full pt-8'>
                <div className="">
                  <span className='text-sm'>Full Name</span>
                  <div className="w-full pt-2 flex gap-2">
                    <TextInput width="w-full" />
                  </div>
                </div>
                <div className="">
                  <span className='text-sm'>Email Address</span>
                  <div className="w-full pt-2 flex gap-2">
                    <TextInput width="w-full" />
                  </div>
                </div>
              </div>
              <div>
                <Checkbox label="Create an account" />
              </div>
              <div className='mt-8 border border-hairline p-4'>
                <div className='flex flex-row'>
                  <div>
                    <Radio name='payment_method' labelClassName='style-2' />
                  </div>
                  <div className='flex-1 pl-2'>
                    <h6>Pay with Credit Card</h6>
                    <span className='text-sm'>Pay safely and securely with your credit card.</span>
                  </div>
                  <div className='flex flex-row gap-2 justify-center items-center'>
                    <img src={visa} width={40} alt="Visa" />
                    <img src={mastercard} width={40} alt="Aastercard" />
                    <img src={american_express} width={40} alt="American Express" />
                  </div>
                </div>
              </div>
              <div className='border border-t-0 border-hairline p-4 bg-[#FBFBFB]'>
                <div className='grid grid-flow-row grid-cols-2 gap-2 w-full'>
                  <div className="">
                    <span className='text-sm'>Name on the Card</span>
                    <div className="w-full pt-2 flex gap-2">
                      <TextInput width="w-full" />
                    </div>
                  </div>
                  <div className="">
                    <span className='text-sm'>Card Number</span>
                    <div className="w-full pt-2 flex gap-2">
                      <TextInput width="w-full" />
                    </div>
                  </div>
                </div>
                <div className='grid grid-flow-row grid-cols-3 gap-2 w-full'>
                  <div className="">
                    <span className='text-sm'>Expiry Date</span>
                    <div className="w-full pt-2 flex gap-2">
                      <Select
                        width="w-full"
                        options={[
                          {
                            value: 1,
                            label: '01',
                          },
                          {
                            value: 2,
                            label: '02',
                          },
                          {
                            value: 3,
                            label: '03',
                          },
                          {
                            value: 4,
                            label: '04',
                          },
                          {
                            value: 5,
                            label: '05',
                          },
                          {
                            value: 6,
                            label: '06',
                          },
                          {
                            value: 7,
                            label: '07',
                          },
                          {
                            value: 8,
                            label: '08',
                          },
                          {
                            value: 9,
                            label: '09',
                          },
                          {
                            value: 10,
                            label: '10',
                          },
                          {
                            value: 11,
                            label: '11',
                          },
                          {
                            value: 12,
                            label: '12',
                          },
                        ]}
                        value={undefined}
                        onChange={() => {}}
                        placeholder='MM'
                      />
                    </div>
                  </div>
                  <div className="">
                    <span className='text-sm'>Expiry Date</span>
                    <div className="w-full pt-2 flex gap-2">
                      <Select
                          width="w-full"
                          options={[
                            {
                              label: '2023',
                              value: 2023
                            },
                            {
                              label: '2024',
                              value: 2024
                            },
                            {
                              label: '2025',
                              value: 2025
                            },
                            {
                              label: '2026',
                              value: 2026
                            },
                            {
                              label: '2027',
                              value: 2027
                            },
                            {
                              label: '2028',
                              value: 2028
                            },
                          ]}
                          value={undefined}
                          onChange={() => {}}
                          placeholder='YYYY'
                        />
                    </div>
                  </div>
                  <div className="">
                    <span className='text-sm'>CVV / Security code</span>
                    <div className="w-full pt-2 flex gap-2">
                      <TextInput width="w-full" />
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-2 border border-hairline p-4'>
                <div className='flex flex-row'>
                  <div>
                    <Radio name='payment_method' labelClassName='style-2' />
                  </div>
                  <div className='flex-1 pl-2'>
                    <h6>Pay with Paypal</h6>
                    <span className='text-sm'>Pay without sharing your payment information.</span>
                  </div>
                  <div className='flex flex-row gap-2 justify-center items-center'>
                    <img src={paypal} width={50} alt="Paypal" />
                  </div>
                </div>
              </div>
              <div className='flex justify-end pt-8'>
                <Button text='PLACE MY ORDER' secondary onClick={() => navigate(router_paths.ORDER_CONFIRMED)} />
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-1 border-l flex flex-col px-10 border-grey bg-pale-blue w-full'>
          <div className='max-w-[600px] mt-[10%]'>
            <img
              className="h-8 w-auto sm:h-10 lg:m-0 m-auto invisible"
              src={logo}
              alt=""
            />
            <div className='pt-10'>
              <h4>Order Summary</h4>
              <div className='flex flex-col gap-3 py-8 border-b border-hairline1'>
                {
                  cart.list.map(item => <CartItem key={item.item.id} data={item.item} quantity={item.quantity} />)
                }
              </div>
              <div>
                <div className="py-7 border-b border-hairline1">
                  <span className='text-sm'>Promo Code</span>
                  <div className="w-full pt-2 flex gap-2">
                    <TextInput width="w-full" placeholder="Enter Promo Code" />
                    <Button width='w-md' text='APPLY' />
                  </div>
                </div>
                <div className="py-7 border-b border-hairline1">
                  <div className='flex w-full justify-between'>
                    <span className='text-sm'>Subtotal</span>
                    <span className='text-sm'>$32.00</span>
                  </div>
                  <div className='flex w-full justify-between pt-3'>
                    <span className='text-sm'>Discount</span>
                    <span className='text-sm text-red'>-$6.4 (20% off )</span>
                  </div>
                </div>
                <div className="py-7">
                  <div className='flex w-full justify-between'>
                    <span className='text-sm font-bold'>Total</span>
                    <h5>$25.6</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}



const CartItem = ({ data, quantity }: { data: Product, quantity: number }) => {
  return (
    <div className='flex pr-10 relative max-w-450'>
      {
        data.type === TYPE_PRODUCT_SERVICE ?
          <img src={data.image} className='w-20 h-20' />
            :
          <PromoCodeView
            description={data?.discount_description || ''}
            percentage={data?.discount_percentage || 0}
            bg_color={data?.color || ''}
          />
      }
      <div className='pl-4'>
        <span className="text-sm product-title text-span text-start font-bold pt-1 overflow-hidden text-ellipsis">{data.name}</span>
        <div className='text-sm text-left'>
          <p>{data.short_description}</p>
        </div>
        <div className='flex flex-row items-center'>
          <div className='text-sm'>x{quantity}</div>
          <div className='border-gray border-l h-4 mx-3'/>
          {!!data.discount_percentage && <div className='flex-1 text-sm text-grey2'>{data.discount_percentage}% OFF</div>}
        </div>
      </div>
      <div className='absolute top-0 right-0 text-sm font-bold'>
        ${10}
      </div>
    </div>
  );
}

const PromoCodeView = (props: { description: string, percentage: number, bg_color: string }) => {
  return (
    <div className='w-20 h-20 relative bg-red text-white flex flex-col items-center justify-center' style={{
      backgroundColor: props.bg_color,
    }}>
      <div><h6 className=''>{Math.round(props.percentage)}%</h6></div>
      <div><h6 className=''>{props.description}</h6></div>
    </div>
  );
}

export default Checkout;