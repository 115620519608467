import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Option from '../../models/Options';

interface BusinessSignUpState {
  email_add: string,
  business_name: string,
  address: string,
  city: string,
  state: Option | undefined,
  zip_code: string,
  category: Option | undefined,
  business_phone_number: string,
  image_url: string,
  url_err: boolean,
  image_file: File | undefined,
}

export type Keys = 'email_add' | 'business_name' | 'address' | 'city' | 'state' | 'zip_code' | 'category' | 'business_phone_number' | 'image_url' | 'url_err';

const initialState: BusinessSignUpState = {
  email_add: '',
  business_name: '',
  address: '',
  city: '',
  state: undefined,
  zip_code: '',
  category: undefined,
  business_phone_number: '',
  image_url: '',
  url_err: false,
  image_file: undefined,
};

const businessSignUpSlice = createSlice({
  name: 'businessSignUp',
  initialState,
  reducers: {
    onBusinessSignUpFormChange: (state: any, action: PayloadAction<{ key: Keys, value: any}>) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    uploadLogo: (state, action: PayloadAction<{ url: string, err: boolean, image_file: File | undefined }>) => {
      const { url, err, image_file } = action.payload;
      state.image_file = image_file;
      state.image_url = url;
      state.url_err = err;
    },
    reset: () => initialState
  }
});

export const { onBusinessSignUpFormChange, uploadLogo, reset } = businessSignUpSlice.actions;
export default businessSignUpSlice;
