import React from 'react';
import { useRouteError } from 'react-router-dom';
import './index.scss';

const ErrorPage = () => {
  const error: any = useRouteError();
  return (
    <div className='w-full h-full text-center flex flex-col justify-center items-center bg-dark-blue'>
      <span className='bubble-text' data-text="404">
        404
      </span>
      <div>
        <i className='text-white'>{error.statusText || error.message}</i>
      </div>
    </div>);
}

export default ErrorPage;