import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../../helpers/styles";
import './index.scss';

const MessageNotification = () => {
  return (
    <div className='inline-block'>
      <div id="MessageNotification" className="flex items-center rounded-full bg-red bg-opacity-20 px-2 py-1">
        <div className="inline-block relative notify pl-1">
          <ChatBubbleLeftIcon className="w-6" />
        </div>
        <div className="text-sm px-2"><span className="font-bold">5</span><span> New Messages</span></div>
      </div>
    </div>
  );
}

export default MessageNotification;