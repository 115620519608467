import React, { useEffect } from 'react';
import { Outlet, useNavigate } from "react-router-dom";

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import { useAppSelector } from '../../store/hooks';
import { useMeQuery } from '../../services/auth';
import { useOptionsQuery } from '../../services/categories';
import SplashScreen from '../../components/SplashScreen';
import { router_paths } from '../../routes';
import HeaderAdmin from '../../components/HeaderAdmin/Header';
import AdminTabs from './AdminTabs';

const AdminDashboard = () => {
  useOptionsQuery();
  const { categories } = useAppSelector(store => store.categories.options);
  const token = useAppSelector(store => store.auth.token);
  const { user } = useAppSelector(store => store.auth);
  const navigate = useNavigate();
  const { data, isLoading } = useMeQuery();
  console.log('token ', token);
  useEffect(() => {
    if (!token) {
      navigate(router_paths.ADMIN_LOGIN, { replace: true });
    }
  }, [token, navigate]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <>
      <HeaderAdmin list_categories={categories} user_data={user} />

      <main className=' pt-24'>
        <AdminTabs>
          <Outlet />
        </AdminTabs>
      </main>
      <Footer />
    </>
  );
}

export default AdminDashboard;