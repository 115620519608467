import Button from "../../../../components/Button";
import Select from "../../../../components/Select";
import { useNavigate, useParams } from "react-router-dom";
import { ORDER_STATUS } from "../../../../constants/purchase_history";
import { OrderStatusChip } from "../../../../components/Chip";
import ReportItem from "../../../../components/ReportItem";
import { useProductListQuery } from "../../../../services/business";
import ProductCard from "../../../../components/ProductCard";
import { StarIcon } from "@heroicons/react/20/solid";
import ReviewItem from "../ReviewItem";

const Overview = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.business_id;
  const { data } = useProductListQuery({ id: Number(id) })
  const product_list = data?.data || [];

  const handleOnClaim = () => {
    navigate('/app/claim/123');
  }

  return (
    <div className="container m-auto rounded-sm py-10 px-11">
      <div className="flex justify-between items-center">
        <div className="flex">
          <h4>Reports</h4>
        </div>
        <div className="flex">
          <div className="pr-2">
            <Select
              placeholder='This month'
              onChange={() => { }}
              options={[
                {
                  label: 'option 1',
                  value: 1
                },
                {
                  label: 'option 2',
                  value: 2
                },
                {
                  label: 'option 3',
                  value: 3
                },
                {
                  label: 'option 4',
                  value: 4
                },
              ]}
              value={undefined}
            />
          </div>
        </div>
      </div>
      <div className="w-full pt-3 grid grid-flow-row grid-cols-5 gap-2">
        <ReportItem label="New Orders" value="29" />
        <ReportItem label="Total Sales" value="$292.77" />
        <ReportItem label="Customers" value="147" />
        <ReportItem label="Messages/Inquiries" value="56" />
        <ReportItem label="Profile Views" value="147" />
      </div>
      <div className="flex justify-between items-center pt-6">
        <h4>Orders (10)</h4>
        <div className="pr-2">
          <Select
            placeholder='Sort By'
            onChange={() => { }}
            options={[
              {
                label: 'option 1',
                value: 1
              },
              {
                label: 'option 2',
                value: 2
              },
              {
                label: 'option 3',
                value: 3
              },
              {
                label: 'option 4',
                value: 4
              },
            ]}
            value={undefined}
          />
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex" />
        <div className="flex">

        </div>
      </div>
      <div className="flex pt-4">
        <div className="grid grid-cols-7 w-full gap-1 items-center border-t border-border-default pt-3 pb-5 font-bold text-xs text-dark-grey px-5">
          <div className="col-span-2">
            ORDER #
          </div>
          <div className="col-span-1">
            DATE
          </div>
          <div className="col-span-1">
            STATUS
          </div>
          <div className="col-span-1">
            CUSTOMER
          </div>
          <div className="col-span-1">
            ITEMS
          </div>
          <div className="col-span-1">
            TOTAL
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3 pb-5">
        <Item status={ORDER_STATUS.FULFILLED} handleOnClaim={handleOnClaim} />
        <Item status={ORDER_STATUS.PARTIALLY_FULFILLED} handleOnClaim={handleOnClaim} />
        <Item status={ORDER_STATUS.UNFULFILLED} handleOnClaim={handleOnClaim} />
        <Item status={ORDER_STATUS.FULFILLED} handleOnClaim={handleOnClaim} />
        <Item status={ORDER_STATUS.PARTIALLY_FULFILLED} handleOnClaim={handleOnClaim} />
        <Item status={ORDER_STATUS.UNFULFILLED} handleOnClaim={handleOnClaim} />
        <Item status={ORDER_STATUS.FULFILLED} handleOnClaim={handleOnClaim} />
        <Item status={ORDER_STATUS.PARTIALLY_FULFILLED} handleOnClaim={handleOnClaim} />
        <Item status={ORDER_STATUS.UNFULFILLED} handleOnClaim={handleOnClaim} />
        <Button text="VIEW ALL" className="border rounded-md border-border-default" width="w-full" outlined outline_color="border-border-default text-dark-blue" />
      </div>
      <div className="flex justify-between items-center pt-6">
        <h4>Products (10)</h4>
        <Button text="+ ADD ITEM" />
      </div>
      <div className="w-full pt-6 pb-3 grid grid-flow-row grid-cols-5 gap-2">
        {
          product_list.filter((p, i) => i < 5).map(p => (
            <ProductCard
              key={p.id}
              id={p.id}
              business_id={p.business.id}
              show_add_to_cart={false}
              show_percent_discount
              title={p.name}
              image={p.image}
              business_name={p.business.name}
              business_url={p.business.website_url}
              short_description={p.short_description}
              price={p.price}
            />
          ))
        }
      </div>
      <Button text="VIEW ALL" className="border rounded-md border-border-default" width="w-full" outlined outline_color="border-border-default text-dark-blue" />
      <div className="flex flex-col pt-6">
        <h4>Rating & Reviews (9)</h4>
        <div className="flex gap-5 pt-6">
          <div className="flex flex-col pr-10">
            <span className=" text-[45px]">4.5</span>
            <span>of 35 Reviews</span>
            <div className='flex justify-start items-center'>
              <StarIcon width={14} height={14} color="#FFCA2C" />
              <StarIcon width={14} height={14} color="#FFCA2C" />
              <StarIcon width={14} height={14} color="#FFCA2C" />
              <StarIcon width={14} height={14} color="#FFCA2C" />
              <StarIcon width={14} height={14} color="#FFCA2C" />
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-2">
            <ReviewItem />
            <ReviewItem />
            <ReviewItem />
            <ReviewItem />
          </div>
        </div>
      </div>

    </div>
  );
}

const Item = (props: { status: string, handleOnClaim: () => void }) => {
  return (
    <div className="grid grid-cols-7 w-full gap-1 items-center border rounded-md border-border-default p-5">
      <div className="col-span-2 text-sm">
        VM0000005
      </div>
      <div className="col-span-1 text-sm">
        May 18, 12:00 PM
      </div>
      <div className="col-span-1 text-sm">
        <OrderStatusChip status={props.status} />
      </div>
      <div className="col-span-1 text-sm">
        Peter Klaus
      </div>
      <div className="col-span-1 text-sm">
        2 items
      </div>
      <div className="col-span-1 text-sm">
        $25.00
      </div>
    </div>
  );
}

export default Overview;