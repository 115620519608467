import React from 'react';

import valumenu_white from '../../assets/images/valumenu_white.svg';
import fb from '../../assets/images/social_icons/fb.svg';
import google from '../../assets/images/social_icons/google.svg';
import twitter from '../../assets/images/social_icons/twitter.svg';
import youtube from '../../assets/images/social_icons/youtube.svg';
import FooterLinks from './components/FooterLinks';
import TextInput from '../TextInput';

const QUICK_LINKS = [
  {
    to: '#',
    label: 'Home'
  },
  {
    to: '#',
    label: 'Explore'
  },
  {
    to: '#',
    label: 'For Business'
  },
  {
    to: '#',
    label: 'Pricing'
  },
  {
    to: '#',
    label: 'Contact Us'
  },
];

const INFO_LINKS = [
  {
    to: '#',
    label: 'About Us'
  },
  {
    to: '#',
    label: 'FAQ\'s'
  },
  {
    to: '#',
    label: 'Support Center'
  },
  {
    to: '#',
    label: 'Help Center'
  },
  {
    to: '#',
    label: 'Join Us'
  },
];

const Desktop = () => {
  return (
    <div className='py-10 px-10 flex-col'>
      <div className='flex flex-row pb-10'>
        <div className='flex flex-1'>
          <img className='fit-content h-fit' src={valumenu_white} alt="logo" />
        </div>
        <div className='flex flex-1 justify-evenly'>
          <FooterLinks
            links={QUICK_LINKS}
            title="Quick Links"
          />
          <FooterLinks
            links={INFO_LINKS}
            title="Information Links"
          />
        </div>
        <div className='flex flex-1'>
          <div className='ml-5 text-white'>
            <h6 className='text-sm font-semibold'>Quick Links</h6>
            <p className='my-3 text-sm'>Sign up and be the first to know about the latest news, deals and events.</p>
            <TextInput
              placeholder='Enter Your Email'
              onChange={() => {}}
              width="w-full"
              right_component={
                <button className='bg-orange-400 whitespace-nowrap text-xs px-3 py-2 font-bold'>SIGN UP</button>
              }
            />
            <div className='flex justify-start align-middle pt-3'>
              <input type="checkbox" />
              <span className='pl-3 text-xs'>I want to receive marketing emails from Value Menu</span>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row justify-between border-t border-t-slate-400	pt-5'>
        <div>
          <span className='text-sm text-white'>Copyright © {new Date().getFullYear()} Valu Menu. All Rights Reserved</span>
        </div>
        <div className='flex flex-row'>
          <div className="bg-white h-8 w-8 flex justify-center items-center rounded-full mx-1"><img src={fb} className="flex  h-4" alt="facebook" /></div>
          <div className="bg-white h-8 w-8 flex justify-center items-center rounded-full mx-1"><img src={twitter} className="flex  h-7" alt="twitter" /></div>
          <div className="bg-white h-8 w-8 flex justify-center items-center rounded-full mx-1"><img src={youtube} className="flex  h-7" alt="youtube" /></div>
          <div className="bg-white h-8 w-8 flex justify-center items-center rounded-full mx-1"><img src={google} className="flex  h-4" alt="google" /></div>
        </div>
      </div>
    </div>
  );
}

export default Desktop;