import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const Pagination2 = () => {
  return (
    <div className="flex items-center">

      <div className='flex items-center justify-center bg-white border border-[#BEBEBE] h-6 w-6 rounded-full ml-1'>
        <ChevronLeftIcon width={15} height={15} color='#BEBEBE' className="mr-[2px]" />
      </div>
      <div className="flex gap-1 items-center justify-center px-3">
        <div className="w-2 h-2 rounded-full bg-dark-blue" />
        <div className="w-2 h-2 rounded-full bg-[#D8D8D8]" />
        <div className="w-2 h-2 rounded-full bg-[#D8D8D8]" />
        <div className="w-2 h-2 rounded-full bg-[#D8D8D8]" />
      </div>
      <div className='flex items-center justify-center bg-dark-blue h-6 w-6 rounded-full ml-1'>
        <ChevronRightIcon width={15} height={15} color='white' className="ml-[2px]" />
      </div>
    </div>
  );
}

export default Pagination2;