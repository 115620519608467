import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import TextInput from '../../components/TextInput';
import Select from '../../components/Select';
import Button from '../../components/Button';
import SearchItem from './SearchItem';
import Pagination from '../../components/Pagination';
import { useLocation, useParams } from 'react-router';
import { useListQuery } from '../../services/business';
import { useSearchParams, useNavigate } from "react-router-dom";


const SearchResult = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const search = searchParams.get("search") || '';
  const navigate = useNavigate();



  const { data, isLoading } = useListQuery({ search: search || '' });

  return (
    <div className='w-full'>
      <div className='bg-purple font-montserrat py-10 text-3xl text-white'>
        <div className='container m-auto'>
          There are {data?.data.length} results for the term <h3 className='inline-block'>“{search}”</h3>
        </div>
      </div>
      <div className='container m-auto py-3'>
        <div className='grid grid-flow-row grid-cols-9 gap-1'>
          <div className='col-span-2'>
            <TextInput
              placeholder='Coffee'
              width='w-full'
              value={search}
              onChange={e => {
                searchParams.set('search', e.target.value);
                navigate(`${location.pathname}?${searchParams.toString()}`);
              }}
            />
          </div>
          <div className='col-span-2'>
            <Select
              placeholder='All'
              options={[
                {
                  label: 'All',
                  value: 0,
                }
              ]}
              onChange={(value) => { }}
              value={0}
              width='w-full'
            />
          </div>
          <div className='col-span-2'>
            <Select
              placeholder='All Locations'
              options={[
                {
                  label: 'All',
                  value: 0,
                }
              ]}
              onChange={(value) => { }}
              value={0}
              width='w-full'
            />
          </div>
          <div className='col-span-2'>
            <Select
              placeholder='Sort by'
              options={[
                {
                  label: 'All',
                  value: 0,
                }
              ]}
              onChange={(value) => { }}
              value={0}
              width='w-full'
            />
          </div>
          <div className='col-span-1'><Button text='APPLY' secondary width='w-full' /></div>
        </div>
        <div className='pt-5'>
          <div>{data?.data.length} Businesses matching “{search}”</div>
          <div className='grid grid-flow-row grid-cols-4 gap-3 pt-5'>
            {
              data?.data.map(d => {
                return <SearchItem key={d.id} data={d} />;
              })
            }
          </div>
        </div>
        <div className='py-10'>
          {/* <Pagination /> */}
        </div>
      </div>
    </div>
  );
}

export default SearchResult;